import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';

// Lazy load components
const PermissionMain = () => import('@/views/modules/permission/pages/permission-main/PermissionMain.vue');
const PermissionActivity = () => import('@/views/modules/permission/pages/permission-main/PermissionActivity.vue');
const ResetPassword = () => import('@/views/modules/permission/pages/authentication/components/ResetPassword.vue');
const CreatePassword = () => import('@/views/modules/permission/pages/authentication/components/CreatePassword.vue');
const CheckOldPassword = () => import('@/views/modules/permission/pages/authentication/components/CheckOldPassword.vue');
const ExpireResetPassword = () => import('@/views/modules/authentication/components/ExpireResetPassword.vue');
const InviteProfile = () => import('@/views/modules/permission/pages/authentication/components/InviteProfile.vue');

const permissionRoutes: Array<CustomRouteRecord> = [
  {
    path: '/permission/profile/:idetitysref?',
    name: 'permission-main',
    component: PermissionMain,
    breadcrumb: [
      {
        text: 'menu_bar.permission',
        to: '/permission/profile',
      },
      {
        text: 'menu_bar.profile',
        active: true,
      },
    ],
  },
  {
    path: '/permission/manage/:submenu?',
    name: 'permission-manage',
    component: PermissionMain,
    breadcrumb: [
      {
        text: 'menu_bar.permission',
        to: '/permission/profile/:submenu?',
      },
      {
        text: 'menu_bar.manage_permission',
        active: true,
      },
    ],
  },
  {
    path: '/permission/history/:idetitysref?',
    name: 'permission-history',
    component: PermissionActivity,
    breadcrumb: [
      {
        text: 'menu_bar.permission',
        to: '/permission/profile',
      },
      {
        text: 'menu_bar.profile',
        to: '/permission/profile',
      },
      {
        text: 'menu_bar.history',
        active: true,
      },
    ],
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/created-password',
    name: 'created-password',
    component: CreatePassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/check-old-password',
    name: 'check-old-password',
    component: CheckOldPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/expire-reset-password',
    name: 'expire-reset-password',
    component: ExpireResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/invite-profile',
    name: 'invite-profile',
    component: InviteProfile,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
];

export default permissionRoutes;
