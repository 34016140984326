export default {
  unlock_crucial_information: 'ปลดล็อกข้อมูลสำคัญและเปลี่ยนความรู้เชิงลึกให้กลายเป็นกลยุทธ์',
  dashboard: 'แดชบอร์ด',
  add_chart: 'เพิ่มแผนภูมิ',
  data_type: 'ประเภทข้อมูล',
  source: 'แหล่งข้อมูล',
  date: 'วันที่',
  chart_type: 'ประเภทแผนภูมิ',
  cancel: 'ยกเลิก',
  close: 'ปิด',
  add: 'เพิ่ม',
  data_info: 'รายละเอียด',
  setting: 'ตั้งค่า',
  delete: 'ลบ',
  information: 'ข้อมูล',
  chart_name: 'ชื่อแผนภูมิ',
  description: 'คำอธิบาย',
  filter: 'ตัวกรอง',
  filters: 'ตัวกรอง',
  add_tab: 'เพิ่มแท็บ',
  tab_name: 'ชื่อแท็บ',
  fill: 'โปรดระบุ',
  All: 'ทั้งหมด',
  PieChart: 'แผนภูมิวงกลม',
  LineChart: 'แผนภูมิเส้น',
  BarChart: 'แผนภูมิแท่ง',
  Heatmap: 'แผนภูมิความร้อน',
  Infographic: 'อินโฟกราฟิก',
  Radar: 'แผนภูมิเรดาร์ ',
  Box: 'แผนภูมิกล่องข้อมูล',
  GeoMap: 'แผนภูมิภูมิศาสตร์',
  Table: 'แผนภูมิตาราง',
  Overview: 'แผนภูมิภาพรวม',
  Gauge: 'แผนภูมิเกจวัดค่า',
  Profile: 'โปรไฟล์',
  Customer: 'ลูกค้า',
  Product: 'สินค้า',
  Transaction: 'ธุรกรรม',
  POS: 'POS',
  CRM: 'CRM',
  Csv: 'CSV',
  CRMPOS: 'CRM/POS',
  BP: 'BCRM Platform',
  FH5: 'Fasthelp5',
  edit_tab: 'แก้ไขชื่อแท็บ',
  save: 'บันทึก',
  no_filter: 'ไม่มีตัวกรอง',
  selected: 'เลือกแล้ว',
  select: 'เลือก',
  setting_chart: 'ตั้งค่าแผนภูมิ',
  chart_info:'รายละเอียดแผนภูมิ',
  color: 'สี',
  no_color: 'ไม่มีสี',
  apply: 'นำไปใช้',
  confirm_deletion: 'ยืนยันการลบ',
  or_not: 'หรือไม่',
  confirm: 'ยืนยัน',
  no_item: 'ไม่มีรายการ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  select_source: 'เลือกแหล่งข้อมูล',
  startDate: 'วันที่เริ่มต้น',
  endDate: 'วันที่สิ้นสุด',
  fillDataSourceAndDate: 'โปรดระบุแหล่งข้อมูล และวันที่ให้ถูกต้อง',
  successful: 'ทำรายการสำเร็จ',
  success: 'สำเร็จ',
  want_to_delete_tab: 'ต้องการลบแท็บ',
  yes_no: 'หรือไม่ ?',
  create_tab: 'สร้างแท็บใหม่',
  change_tab_name: 'เปลี่ยนชื่อแท็บ',
  tab_name_to: 'เป็น',
  tab_list_empty: 'Tab list empty',
  not_found_tab_item: 'Not found tab item',
  overview: 'ภาพรวม',
  geomap_extraleft_title: '5 อันดับจังหวัดเเรก',
  total: 'ทั้งหมด',
  no_data: 'ไม่มีข้อมูล',
  export_pdf: 'ส่งออก PDF​',
  date_range: 'ระบุวันที่​',
  background: 'พื้นหลัง',
  label: 'ป้าย/ เครื่องหมาย/ ข้อความ',
  female: 'หญิง',
  male: 'ชาย',
  not_specified: 'ไม่ระบุ',
  child: 'วัยเด็ก',
  teen: 'วัยรุ่น',
  late_teen: 'วัยรุ่นตอนปลาย',
  working: 'วัยทำงาน',
  old: 'วัยชรา',
  age: 'ช่วงอายุ',
  year_over: 'ปีขึ้นไป',
  dynamicFilter: 'ตัวกรองไดนามิก',
  seemore: 'ดูเพิ่มเติม',
  chart: 'แผนภูมิ',
  brandAndBranch: 'แบรนด์ และสาขา',
  pleaseSelectBrand: 'กรุณาเลือกแบรนด์ และสาขา ที่ต้องการดูข้อมูลแผนภูมิ',
};
