import { Ability } from '@casl/ability';
import brandService from '@/services/brand';
import { initialAbility } from './config';

// const foundBrandData2 = (): boolean => {
//   const localBrandInfo = brandService.getLocalBrandData();
//   if (!localBrandInfo) return false;
//   const parsedBrandData = JSON.parse(brandService.getLocalBrandData() || '');
//   if (Array.isArray(parsedBrandData)) {
//     const brandFound = parsedBrandData.filter(
//       (o: Record<string, any>) =>
//         o.brandRef == new URLSearchParams(window.location.search).get('brandRef') || o.brandRef == sessionStorage.getItem('brandSessionData'),
//     );
//
//     return brandFound.length > 0;
//   }
//   // JSON.parse(brandService.getLocalBrandData())?.filter((o: Record<string, any>) => {
//   //   return (
//   //     o.brandRef == new URLSearchParams(window.location.search).get('brandRef') ||
//   //     o.brandRef == sessionStorage.getItem('brandSessionData')
//   //   );
//   // })?.length > 0;
//
//   return false;
// };

const foundBrandData = (): boolean => {
  const brandLocal = brandService.getLocalBrandData();
  if (brandLocal) {
    const currentBrandLocal = JSON.parse(brandLocal).currentBrand;
    if (!currentBrandLocal) {
      return false;
    }
    if (currentBrandLocal.brandRef) {
      const brandRefQuery = brandService.getBrandRefParams();

      if (brandRefQuery && currentBrandLocal.brandRef === brandRefQuery) {
        return true;
      }
    }
  }

  return false;
};

const existingAbility = foundBrandData() ? [{ action: 'access', subject: 'cdp' }] : null;

export default new Ability(existingAbility || initialAbility);
