import { initialAbility } from '@/libs/acl/config';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { getCurrentInstance } from 'vue';

export const usePermissionStore = defineStore('permission', {
  state: (): Permission.State => ({
    permissions: {} as Record<KeyName, boolean>,
    isLoading: false,
  }),
  getters: {
    getPermissionStatus: (state) => (key: KeyName) => state.permissions[key],
    hasPermission: (state) => (key: KeyName) => !!state.permissions[key],
    allPermission: (state) => state.permissions,
    isLoading: (state) => state.isLoading,
  },
  actions: {
    setPermission(permissionList: Record<KeyName, boolean>) {
      // Update the Info state in a single operation
      this.permissions = { ...permissionList };
    },
    resetPermissions() {
      this.$reset();
      const vm = getCurrentInstance()?.proxy;
      vm?.$ability.update(initialAbility);
    },
    setLoading(loading: boolean) {
      this.isLoading = loading;
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissionStore, import.meta.hot));
}
