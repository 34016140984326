<template>
  <div class="notification-bell">
    <div class="dropdown-notification-bell-container" ref="refNotiBell" @click="onClickBell">
      <div class="icon">
        <bell-icon />
      </div>
      <div v-if="dataNoti.total_unread" class="count-unread">
        <div class="text">{{ dataNoti.total_unread }}</div>
      </div>
    </div>
    <ul v-show="isNoti" class="dropdown-noti dropdown-noti-list m-2" ref="refDropdownNoti">
      <li class="title">
        <div class="text">{{ t(`notification.notification_bell.title`) }}</div>
      </li>
      <li>
        <hr class="dropdown-divider" style="margin: 0" />
      </li>
      <li v-show="loading" class="noti-list">
        <div class="loading-item">
          <div class="load-icon"></div>
          <div class="load-detail"></div>
        </div>
      </li>
      <li
        v-show="!loading"
        class="noti-list"
        ref="scrollContainer"
        @scroll="handleScroll"
        :style="{ 'max-height': `${(limitToPage - 1) * 80}px !important` }"
      >
        <div v-for="item in dataNoti.data_list" :key="item.label">
          <button
            v-if="!item.hide"
            class="dropdown-item dropdown-noti-item"
            :style="{ backgroundColor: item.is_read ? '' : ' #007FFF0D' }"
            type="button"
            @click="onClickNoti(item)"
            :disabled="item.disable"
          >
            <div class="content">
              <component
                :is="mapIcon(item.icon_name).icon"
                class="icon"
                :class="mapIcon(item.icon_name).color !== '' ? mapIcon(item.icon_name).color : ''"
                style="width: auto; height: 30px; padding: 0 1rem 0 1rem"
              />
              <div class="text">
                <span class="title-text">{{ item.title }}</span>
                <span class="details">{{ item.detail }}</span>
                <span class="date-time">{{ setDateTime(item.created_dt) }}</span>
              </div>
            </div>

            <div :class="item.is_read ? 'noti-unread' : 'noti-read'" style="margin: 0 1rem 0 1rem"></div>
          </button>
        </div>
        <div v-if="loadingMore" class="loading-item">
          <div class="load-icon"></div>
          <div class="load-detail"></div>
        </div>
      </li>
      <!-- <li>
        <hr class="dropdown-divider" />
      </li> -->
      <!-- <li>
        <button class="dropdown-item dropdown-menu-item log-out-button" type="button" @click="">{{ t('global.view_more') }}</button>
      </li> -->
    </ul>
  </div>
</template>

<script setup lang="ts">
import useNotiBell from '../hooks/useNotiBell';

import BellIcon from '@/assets/icons/base/notification/bell/bell.vue';

const {
  isNoti,
  loading,
  dataNoti,
  t,
  mapIcon,
  onClickNoti,
  setDateTime,
  onClickBell,
  refNotiBell,
  refDropdownNoti,
  handleScroll,
  scrollContainer,
  loadingMore,
  limitToPage,
} = useNotiBell();
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/horizontal-header-bar.scss';
</style>
