<script setup lang="ts">
import { useBrandStore } from '@/store/brandStore';
import { defineProps, Ref, ref, onBeforeMount, nextTick } from 'vue';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import usePermissionModal from '@/views/modules/permission/components/permission-modal/hook/usePermissionModal';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/libs/i18n';
import authService from '@/services/authentication';

// Icon
import RoleIcon from '@/assets/icons/modules/permission/RoleIcon.vue';
import ArrowIcon from '@/assets/icons/modules/permission/ArrowIcon.vue';

// Modal
import RoleModal from '@/views/modules/permission/components/permission-modal/RoleModal.vue';

const { modalOpen, openRoleModal } = usePermissionModal();
const { t } = useI18n();

const isOpen = ref<boolean>(false);

const user: Record<string, any> = ref({});

const role: Ref<any[]> = ref([]);
const roleKey: Ref<string | null> = ref('');

const onLoadData = () => {
  const str = sessionStorage.getItem('verify:account');

  const parsedObject = str ? JSON.parse(str) : {};
  role.value = i18n.global.locale === 'th' ? parsedObject.role_name_th : parsedObject.role_name_en;
  roleKey.value = parsedObject.role_key;

  // if (authService.getLocalBrandData()! && JSON.parse(authService.getLocalBrandData()!).length > 0) {
  if (useBrandStore().currentBrand) {
    user.value = JSON.parse(localStorage.getItem('app:authInfo')!);
  }

  if (sessionStorage.getItem('verify:account')) {
    const verifyAcc = JSON.parse(sessionStorage.getItem('verify:account')!);

    if (user.value?.email == verifyAcc?.email) {
      isOpen.value = true;
    }
  }
};

const onClickToHome = () => {
  sessionStorage.removeItem('verify:account');
  isOpen.value = false;
};

onBeforeMount(() => {
  onLoadData();
});
</script>

<template>
  <base-modal :size="'xl'" :modal-id="'confirm-welcome-modal'" class="confirm-welcome" :is-modal-open="isOpen" hide-header hide-footer>
    <template #modal-body>
      <div class="container">
        <div class="header">
          <div class="title">{{ t('permission.modal.confirm_welcome.title') }}</div>
          <div class="sub-title">
            {{ t('permission.modal.confirm_welcome.sub_title_1') }}
            <span style="color: #007fff">{{ (user.first_name && user.last_name) !== null ? `${user.first_name} ${user.last_name}` : '' }}</span>
            {{ t('permission.modal.confirm_welcome.sub_title_2') }}
          </div>
        </div>
        <div class="content">
          <div>
            <div>{{ t('permission.modal.confirm_welcome.content_1') }}</div>
            <div>{{ t('permission.modal.confirm_welcome.content_2') }}</div>
            <div>{{ t('permission.modal.confirm_welcome.content_3') }}</div>
          </div>
        </div>
        <div class="btn-action">
          <button class="btnRole" @click="openRoleModal(role, roleKey)"><RoleIcon /> {{ t('permission.modal.confirm_welcome.role') }}</button>
          <button class="btnStart" @click="onClickToHome">
            <span>{{ t('permission.modal.confirm_welcome.start') }}</span> <ArrowIcon class="arrow-icon" />
          </button>
        </div>
      </div>
      <div class="footer">
        <img src="@/assets/images/modules/permission/ConfirmUser.png" alt="" style="width: 100%" />
      </div>
    </template>
  </base-modal>
  <RoleModal
    v-if="modalOpen.isOpenModal"
    :is-open="modalOpen.isOpenModal"
    @close-modal="modalOpen.isOpenModal = false"
    :role="modalOpen.role ?? []"
    :role-key="modalOpen.roleKey ?? ''"
  />
</template>

<style lang="scss" scoped>
@import '@assets/styles/modules/permission/permission-modal/permission-confirm-welcome-modal.scss';
</style>
