import router from '@/router';
import { $ResetPinia } from '@/store';
import { usePermissionStore } from '@/store/permissionStore';
import { isTokenValid } from '@/utils/token';
// import { isTokenValid } from '@/utils/token';
import authModel from '@/models/authentication/login';
import mfaModel from '@/models/authentication/mfa';
import { signalRService } from '@/services/signalR';
import apiService from '@/services/api';
// import jwt from 'jsonwebtoken';

// Store
import { useAuthStore } from '@/store/authStore';
import { useBrandStore } from '@/store/brandStore';
import { useModuleStore } from '@/store/moduleStore';
import { useBuilderConfigStore, useSourceDetailStore } from '@/store/segmentV2Store';

class AuthService {
  // Dynamically fetch the auth store to ensure Pinia is initialized
  private get authStore() {
    return useAuthStore();
  }

  private get brandStore() {
    return useBrandStore();
  }

  private get moduleStore() {
    return useModuleStore();
  }

  private get permissionStore() {
    return usePermissionStore();
  }

  // Segment v2
  private get segmentBuilderConfigStore() {
    return useBuilderConfigStore();
  }

  private get segmentSourceDetailStore() {
    return useSourceDetailStore();
  }

  private isRefreshing = false; // Prevent multiple refresh attempts

  private async fetchRefreshToken(accessToken: string, refreshToken: string): Promise<Authentication.RespRefreshToken> {
    const { fetchRefreshTokenModel } = authModel();
    fetchRefreshTokenModel.payload.access_token = accessToken;
    fetchRefreshTokenModel.payload.refresh_token = refreshToken;
    const res = await apiService.apiRequest(fetchRefreshTokenModel).catch((error) => {
      throw error;
    });

    return res.data as Authentication.RespRefreshToken;
  }

  async refreshToken(): Promise<void> {
    try {
      if (this.authStore.access_token && this.authStore.refresh_token) {
        const res = await this.fetchRefreshToken(this.authStore.access_token, this.authStore.refresh_token);
        this.authStore.setTokens(res.access_token, res.refresh_token);
        this.brandStore.setToken(res.access_token, res.refresh_token);
      }
    } catch (e) {
      throw e;
    }
  }

  isUserLoggedIn(): boolean {
    this.authStore.loadAuthInfoFromLocalStorage();
    const userInfo = this.authStore.userInfo;
    const currentBrand = this.brandStore.currentBrand;
    const { access_token, refresh_token } = this.authStore;

    // Validate access token first
    if (!access_token || !refresh_token) return false;
    if (!isTokenValid(access_token)) {
      authService.clearUserLocalStorage();
      return false;
    }
    return !!(userInfo && currentBrand && currentBrand.brandRef && access_token && refresh_token);
  }

  isClearLocalStorage() {
    return localStorage.clear();
  }

  // User Auth
  async fetchLogin(credentials: { username: string; password: string; brandRef?: string }): Promise<Authentication.RespLogin> {
    const { fetchLoginModel } = authModel();
    fetchLoginModel.payload.username = credentials.username;
    fetchLoginModel.payload.password = credentials.password;
    fetchLoginModel.payload.brand_ref = credentials.brandRef || '';

    try {
      const loginRes = await apiService.apiRequest(fetchLoginModel).catch((err) => {
        return Promise.reject(err);
      });
      return loginRes.data as Authentication.RespLogin;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /*
  * SendEmailToResetPass
   Payload
   {Email:"admin@mail.com"}
   Response
   {
    "request_id": "",
    "status": "success",
    "data": null,
    "error": null
  }
  * */

  async fetchResetPassword(password: string): Promise<Authentication.RespResetPassword> {
    try {
      const { fetchResetPasswordModel } = authModel();
      fetchResetPasswordModel.payload.password = password;
      const resetResp = await apiService.apiRequest(fetchResetPasswordModel);
      return resetResp.data as Authentication.RespResetPassword;
    } catch (e) {
      console.error('Fetch reset password error: ', e);
      throw e;
    }
  }

  /*
  fetchForgotPassword response
  {
  "request_id": "",
  "status": "success",
  "data": null,
  "error": null
  }
   */
  async fetchForgotPassword(token: string, txReference: string, password: string): Promise<boolean> {
    try {
      const { fetchResetForgetPassword } = authModel();
      fetchResetForgetPassword.payload.Token = token;
      fetchResetForgetPassword.payload.tx_Reference = txReference;
      fetchResetForgetPassword.payload.New_Password = password;
      const resetResp = await apiService.apiRequest(fetchResetForgetPassword);
      return resetResp.status === 'success';
    } catch (e) {
      console.error('Fetch forgot password error: ', e);
      throw e;
    }
  }

  async fetchLoginWithMFA(credentials: { username: string; password: string; brandRef?: string }): Promise<{
    MFAStatus: boolean;
    loginRes: Authentication.RespLogin;
  }> {
    try {
      const loginRes = await this.fetchLogin(credentials);
      if (loginRes.mfa_required) {
      }

      return { loginRes, MFAStatus: false };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchLogout(): Promise<boolean> {
    try {
      if (this.authStore.access_token) {
        const { fetchLogoutModel } = authModel();
        fetchLogoutModel.payload.access_token = this.authStore.access_token;
        await apiService
          .apiRequest(fetchLogoutModel)
          .then(() => {
            Promise.resolve();
          })
          .catch((error) => {
            Promise.reject(error);
            return false;
          });
      }
      return true;
    } catch (e) {
      console.error('fetchLogout() Error: ', e);
      return false;
    }
  }

  async logout(): Promise<void> {
    // including clear data
    try {
      await this.fetchLogout();
    } catch (e) {
      console.error('logout() Error: ', e);
    } finally {
      this.clearUserLocalStorage();
      // Disconnect 3rd party service
      this.clearServiceConnections();
      // Clear user data

      // const redirectQueryParams = router.currentRoute.value.query.redirect;
      // if (redirectQueryParams) {
      //   await router.push({
      //     name: 'auth-login',
      //     query: { redirect: redirectQueryParams },
      //   });
      // }
      await router.push({
        name: 'auth-login',
      });
    }
  }

  clearUserLocalStorage() {
    this.permissionStore.resetPermissions();

    // Reset Pinia
    $ResetPinia();

    this.authStore.$reset();
    this.brandStore.$reset();
    this.moduleStore.$reset();
    this.permissionStore.$reset();

    // Segment
    this.segmentSourceDetailStore.$reset();
    this.segmentBuilderConfigStore.$reset();
    // Manual reset method
    this.segmentBuilderConfigStore.reset();
    this.segmentSourceDetailStore.reset();
    // Clear all local session
    sessionStorage.clear();
    localStorage.clear();
  }

  private clearServiceConnections() {
    // Disconnect SignalR
    signalRService.disconnect();
  }
}

const authService = new AuthService();

export default authService;

// TODO: split service
// MFA Microsoft 2Factor
class MFAService {
  async getMfaRegister(mfaToken: string) {
    const { fetchMfaRegister } = mfaModel();
    fetchMfaRegister.payload.Token = mfaToken;
    await apiService
      .apiRequest(fetchMfaRegister)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async fetchTotpVerify(mfaToken: string, credential: string): Promise<any> {
    const { fetchMfaCheck } = mfaModel();

    fetchMfaCheck.payload.Token = mfaToken;
    fetchMfaCheck.payload.MFA_Credential = credential;
    await apiService
      .apiRequest(fetchMfaCheck)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async fetchTotpConfirm(mfaToken: string, credential: string): Promise<any> {
    const { fetchMfaTotpConfirm } = mfaModel();
    fetchMfaTotpConfirm.payload.Token = mfaToken;
    fetchMfaTotpConfirm.payload.MFA_Credential = credential;
    await apiService
      .apiRequest(fetchMfaTotpConfirm)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}

const mfaService = new MFAService();
export { mfaService };
