import { CustomRouteRecord } from '@/types/router/router';

const MainNotificationCredit = () => import('@/views/modules/notification-credit/mainNotificationCredit.vue');

const MainNotificationCreditRoutes: Array<CustomRouteRecord> = [
  {
    path: '/notification-credit',
    name: 'MainNotificationCredit',
    component: MainNotificationCredit,
    meta: {
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'notification.notification',
      },
    ],
  },
];

export default MainNotificationCreditRoutes;
