<template>
  <div></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import router from '@/router';
import api from '@/services/api';

import loginModel from '@/models/authentication/login';
const { fetchVerifyTokenToResetPass } = loginModel();

const checkEmail = () => {
  fetchVerifyTokenToResetPass.payload.tx_Reference = String(router.currentRoute.value.query.tx_Reference);
  fetchVerifyTokenToResetPass.payload.Token = String(router.currentRoute.value.query.token);

  api
    .apiRequest(fetchVerifyTokenToResetPass)
    .then((response) => {
      if (response.status == 'success') {
        router.replace({
          name: 'first-reset-password',
          query: {
            token: response.data.token,
            tx_Reference: response.data.tx_reference,
          },
        });
      }
    })
    .catch((err) => {
      if (err.data.error.message == 'Token not valid or expired') {
        router.push({
          path: '/expire-reset-password',
        });
      }
    });
};

onMounted(() => {
  checkEmail();
});
</script>

<style lang="scss"></style>
