import { acceptHMRUpdate, defineStore } from 'pinia';

const moduleVersionConstant = {
  Global: '1.0.0',
  Segment: '1.0.0',
  BusinessIntelligence: '1.0.0',
  CustomerInsight: '1.0.0',
  Communication: '1.0.0',
  MKA: '1.0.0',
  Analytics: '1.0.0',
  DataManagement: '1.0.0',
  DataSource: '1.0.0',
  DataRelation: '1.0.0',
  DataConnector: '1.0.0',
  DataIngestion: '1.0.0',
  DataMapping: '1.0.0',
  DataMerge: '1.0.0',
};

interface ModuleState {
  moduleVersion: CDPModule.ModuleVersion | null;
}

export const useModuleStore = defineStore('module', {
  state: (): ModuleState => ({ moduleVersion: null }),
  getters: {
    getGlobalVersion: (state) => state.moduleVersion?.Global,
    getSegmentVersion: (state) => state.moduleVersion?.Segment,
    getBusinessIntelligenceVersion: (state) => state.moduleVersion?.BusinessIntelligence,
    getCustomerInsightVersion: (state) => state.moduleVersion?.CustomerInsight,
    getCommunicationVersion: (state) => state.moduleVersion?.Communication,
    getMKAVersion: (state) => state.moduleVersion?.MKA,
    getAnalyticsVersion: (state) => state.moduleVersion?.Analytics,
    getDataManagementVersion: (state) => state.moduleVersion?.DataManagement,
    getDataSourceVersion: (state) => state.moduleVersion?.DataSource,
    getDataRelationVersion: (state) => state.moduleVersion?.DataRelation,
    getDataConnectorVersion: (state) => state.moduleVersion?.DataConnector,
    getDataIngestionVersion: (state) => state.moduleVersion?.DataIngestion,
    getDataMappingVersion: (state) => state.moduleVersion?.DataMapping,
    getDataMergeVersion: (state) => state.moduleVersion?.DataMerge,
  },
  actions: {
    loadModuleVersionsFromLocalStorage() {
      const versionsString = localStorage.getItem('app:moduleVersions');
      if (versionsString) {
        this.$patch(JSON.parse(versionsString));
      }
    },
    setModuleVersions(versions: CDPModule.ModuleVersion) {
      this.moduleVersion = versions;
    },
    resetModuleVersion() {
      this.moduleVersion = moduleVersionConstant;
    },
    clearModuleVersion() {
      this.$patch({});
      // localStorage.removeItem('moduleVersion');
    },
  },
  persist: {
    key: 'app:moduleVersion',
    storage: localStorage,
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModuleStore, import.meta.hot));
}
