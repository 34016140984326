import { CustomRouteRecord } from '@/types/router/router';

const settingRoute: Array<CustomRouteRecord> = [
  {
    path: '/setting/main',
    name: 'setting',
    component: () => import('@/views/modules/unauthorized/Unauthorized.vue'),
    breadcrumb: [
      {
        text: 'Setting',
      },
    ],
  },
];

export default settingRoute;
