<template>
  <div class="hamburger-menu">
    <!-- implement for xmas theme only -->
    <SnowFlake v-if="toggleSnow" />
    <!------------end-xmas--------------->
    <div class="dropdown-hamburger-container" data-bs-toggle="dropdown" aria-expanded="false" aria-controls="navbarMenu">
      <hamburger-svg />
    </div>
    <ul class="dropdown-menu dropdown-menu-list m-2" aria-labelledby="dropdownMenu2">
      <div class="profile-detail">
        <img
          v-if="userInfo?.picture_url"
          :src="userInfo?.picture_url == 'DefaultProfile' ? DefaultProfile : userInfo?.picture_url"
          class="profile-pic"
          alt="profile"
        />
        <div v-else class="avatar">
          {{ 'A' }}
        </div>
        <div class="info-section">
          <!-- <div>{{ `${user.first_name} ${user.last_name}` }}</div> -->
          <div class="text-role">{{ role }}</div>

          <div>{{ authStore.getFullName }}</div>
          <!-- <div class="text-email">{{ `${user.email}` }}</div> -->
          <div class="text-email">{{ userInfo?.email }}</div>
          <!-- <a class="text-edit-profile disabled">{{ `${t('menu_bar.edit_profile')}` }}</a> -->
        </div>
      </div>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li v-for="item in menuList" :key="item.label">
        <button
          v-if="!item.hide"
          class="dropdown-item dropdown-menu-item menu-item-container"
          type="button"
          @click="item.action"
          :disabled="item.disable"
        >
          <div class="left-container">
            <component :is="resolveIcon(item.icon)" class="icon" :class="{ disabled: item.disable }" />
            <!-- <span :style="{ marginLeft: item.label === 'switchlanguage' ? '1rem' : '0rem' }"> {{ t(`menu_bar.${item.label}`) }}</span> -->
            <span> {{ t(`menu_bar.${item.label}`) }}</span>
          </div>
          <div class="right-container">
            <div class="toggle-flag" v-if="item.label == 'switchlanguage'">
              <div class="contain-lang" v-if="currentLocale == 'th'">
                <span class="language-text">ไทย</span> <img :src="thaiTlag" class="national-flag-icon" />
              </div>
              <div class="contain-lang" v-if="currentLocale == 'en-US'">
                <img :src="engFlag" class="national-flag-icon" /><span class="language-text">EN</span>
              </div>
            </div>
          </div>
        </button>
      </li>
      <li class="px-3 py-2" hidden>
        <div class="form-check form-switch">
          <input v-model="toggleSnow" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          <label class="form-check-label" for="flexSwitchCheckDefault"><span style="color: dodgerblue">Snow</span></label>
        </div>
      </li>
      <li style="text-align: end; font-size: 0.6rem">{{ version }}</li>
      <li>
        <hr class="dropdown-divider" />
      </li>
      <li>
        <button class="dropdown-item dropdown-menu-item log-out-button" type="button" @click="onClickLogout">{{ t('menu_bar.sign_out') }}</button>
      </li>
    </ul>
    <teleport to="#app">
      <change-brand-modal :is-modal-open="modalType.changeBrand" @close-modal="closeChangeBrandModal" />
    </teleport>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import authService from '@/services/authentication';
// Hooks
import useMenuBar from '../hooks/useMenuBar';
// Components
import ChangeBrandModal from '@/views/components/modal/ChangeBrandModal.vue';

// Icon
import HamburgerSvg from '@/assets/icons/base/navbarIcon/Hamburger.vue';
import DefaultProfile from '@/assets/icons/base/default-profile.png';

import BrandAccountIcon from '@/assets/icons/base/menubar/BrandAccountIcon.vue';
import ChangeBrandAccountIcon from '@/assets/icons/base/menubar/ChangeBrandAccountIcon.vue';
import PermissionIcon from '@/assets/icons/base/menubar/PermissionIcon.vue';
import ChangeRoleIcon from '@/assets/icons/base/menubar/ChangeRoleIcon.vue';
import ManageStorageIcon from '@/assets/icons/base/menubar/ManageStorageIcon.vue';
import ConfigurationIcon from '@/assets/icons/base/menubar/ConfigurationIcon.vue';
import LanguageIcon from '@/assets/icons/base/menubar/LanguageIcon.vue';
import engFlag from '@/assets/images/base/engFlag.png';
import thaiTlag from '@/assets/images/base/thaiFlag.png';

// Get platform Version
// const packageJson = require('/package.json');

import { useAuthStore } from '@/store/authStore';

import SnowFlake from '@/themes/SnowFlake.vue';

const { t, locale } = useI18n();

// implement for X-mas theme only
const toggleSnow: Ref<boolean> = ref(false);

const { menuList, modalType, currentLocale, closeChangeBrandModal } = useMenuBar();
const authStore = useAuthStore();
const { userInfo } = authStore;

const version = __APP_VERSION__;
const role = locale.value == 'th' ? userInfo?.role_th : userInfo?.role_en;

const resolveIcon = (name: string) => {
  switch (name) {
    case 'BrandAccountIcon':
      return BrandAccountIcon;
    case 'ChangeBrandAccountIcon':
      return ChangeBrandAccountIcon;
    case 'ManageStorageIcon':
      return ManageStorageIcon;
    case 'ConfigurationIcon':
      return ConfigurationIcon;
    case 'PermissionIcon':
      return PermissionIcon;
    case 'ChangeRoleIcon':
      return ChangeRoleIcon;
    case 'LanguageIcon':
      return LanguageIcon;
    default:
  }
};

const onClickLogout = async () => {
  await authService.logout();
};
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/horizontal-header-bar.scss';
</style>
