//th
export default {
  copy: 'สำเนา',
  //coupon set
  source: 'แหล่งข้อมูล',
  format: 'รูปแบบข้อมูล',
  couponType: 'ประเภทคูปอง',
  status: 'สถานะ',
  countDelivered: 'จำนวนคูปองที่ถูกส่งออก',
  countAvailable: 'จำนวนคูปองคงเหลือ',
  countRedeemed: 'จำนวนคูปองที่ถูกแลก',
  upload: 'อัปโหลดข้อมูล',
  sync: 'เชื่อมต่อข้อมูล​',
  action: 'เพิ่มเติม',
  detail: 'รายละเอียด',
  exportFile: 'ส่งออกไฟล์',
  show: 'แสดงผล',
  list: 'รายการ',
  back: 'ย้อนกลับ',
  next: 'ถัดไป',
  done: 'เสร็จแล้ว',
  save: 'บันทึก',

  //coupon code
  uploadingData: 'การอัปโหลดข้อมูล',
  noReference: 'ไม่มีการอ้างอิง',
  noCouponName: 'ไม่มีชื่อคูปอง',
  noDescription: 'ไม่มีคำอธิบาย',
  valueReference: 'มูลค่าสิทธิ์​',
  total_numberOfRights: 'จำนวนสิทธิ์ทั้งหมด',
  trackingCoupon: 'ติดตามการใช้คูปอง', //  ติดตามสถานะคูปอง
  couponCode: 'รหัสคูปอง',
  batch: 'ชุด',
  channel: 'ช่องทาง',
  all: 'ทั้งหมด',
  select: 'เลือก',
  exportCSV: 'ส่งออกไฟล์ CSV.',

  delivered: 'Delivered',
  available: 'Available',
  redeemed: 'Redeemed',

  no: 'ลำดับ',
  linkCouponCode: 'ลิงก์รหัสคูปอง',
  batchId: 'ชุด',
  exp_date: 'วันหมดอายุ​',
  exp_time: 'เวลาหมดอายุ​',
  campaignId: 'รหัสแคมเปญ​',
  cid: 'CID',
  redeemedDT: 'Redeemed DT',
  redeemedBranchName: 'Redeemed Branch',

  uploadFileCoupon_details: 'กรุณาอัปโหลดข้อมูลคูปองในรูปแบบไฟล์ CSV.',

  image: 'ภาพ',
  replace: 'แทนที่',
  delete: 'ลบ',
  reference: 'ข้อมูลอ้างอิง',
  description: 'คำอธิบาย',
  summaryImported: 'สรุปจำนวนข้อมูลที่นำเข้า',
  quantity: 'ปริมาณ',
  success: 'สำเร็จ',
  error: 'ผิดพลาด',
  sumRow_isInvalid: 'ผลรวมของข้อมูลแถวที่ไม่ถูกต้อง',
  errorRow: 'แถวผิดพลาด',
  downloadErrorReport: 'ดาวน์โหลดรายงานข้อผิดพลาด',

  dataSetName: 'ชื่อชุดข้อมูล',
  waitUploadingFiles: 'ระบบกำลังอัปโหลดไฟล์ กรุณารอสักครู่....',

  column: 'คอลัมน์',
  rename: 'เปลี่ยนชื่อ',
  notSelected: 'ไม่ได้เลือก',

  selectTemplateType: 'เลือกประเภทเทมเพลต',
  templateType: 'ประเภทเทมเพลต',
  selectDataType: 'กรุณาเลือกประเภทข้อมูล',
  templateName: 'ชื่อเทมเพลต',
  setTemplateName: 'ตั้งชื่อเทมเพลต',
  selectAll: 'เลือกทั้งหมด',
  editTemplate: 'แก้ไขเทมเพลต',
  createTemplate: 'สร้างเทมเพลต',

  mappingTemplate: 'การแม็ปเทมเพลต',
  selectData: 'เลือกข้อมูล',

  mapping: 'จับคู้คอลัมน์ระบบ',
  //add data source
  moreDetails: 'รายละเอียดเพิ่มเติม',
  readMore: 'อ่านเพิ่มเติม',
  //add data source Fh5
  goToSetting: 'ไปที่การตั้งค่า',
  contactUs: 'ติดต่อเรา',
  connectFH5: 'หากต้องการเชื่อมต่อกับ FastHelps5 กรุณาติดต่อเรา กดที่ปุ่ม ',
  contact: 'ติดต่อ',

  //Fh5
  fh5_button_open: 'Open FastHelp5',
  fh5_integration: 'FastHelp5 Integration',
  fh5_connection_info: 'ข้อมูลการเชื่อมต่อ FH5',
  fh5_webhook_setting: 'Webhook Setting',
  fh5_connect_chocoCDP_to_FH5: 'ข้อมูลสำหรับการเชื่อมต่อจาก ChocoCDP ไป FH5',

  //Upload history
  fileUploadHistory: 'ประวัติการอัพโหลด',
  detail_fileUploadHistory: 'ประวัติการอัปโหลดไฟล์ข้อมูล',

  //excelDataSource
  createFileTemplate: 'อัปโหลดไฟล์เทมเพลต',
  downloadTemplate: 'ดาวน์โหลดแบบฟอร์ม​',
  downloadTemplate_details: 'กรุณาดาวน์โหลดแบบฟอร์มเพื่อกรอกข้อมูลคูปองให้ถูกต้องก่อนอัปโหลดไฟล์ทุกครั้ง',
  uploadFile: 'อัปโหลดไฟล์',
  uploadFile_details: 'กรุณาอัปโหลดไฟล์ CSV. เพื่อสร้างแบบฟอร์มไว้สำหรับสำนำเข้าข้อมูลที่ถูกต้อง',

  inputData: 'นำเข้าข้อมูล',

  dataFormAlreadySetUp: 'แบบฟอร์มข้อมูลที่ทำการตั้งค่าแล้ว',
  permission_importData: 'ไม่มีสิทธิ์นำเข้าข้อมูล',

  confirmData: 'ยืนยันข้อมูล',
  checkInformation: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',

  progress: 'ความคืบหน้า',
  inProgress: 'อยู่ระหว่างดำเนินการ',
  complete: 'เสร็จสมบูรณ์',
  fileUploaded: 'อัพโหลดไฟล์แล้ว',
  fileSize: 'ไฟล์ CSV. ไม่เกิน 250 MB',

  duplicate: 'สำเนาข้อมูล',
  noDuplicate: 'ไม่สำเนาข้อมูล',

  browseFile: 'เรียกดูไฟล์',
  selectColumnCategorizing: 'กรุณาเลือกคอลัมน์ที่ต้องการใช้ สำหรับการจัดหมวดหมู่ในแบบฟอร์ม',

  code: 'รหัส',
  link: 'ลิงก์',
  expireDT: 'วันหมดอายุ',
  defaultTemplate: 'แบบฟอร์มเริ่มต้น',
  detail_defaultTemplate: 'กรุณาดาวน์โหลดแบบฟอร์มเพื่อกรอกข้อมูลให้ถูกต้อง ก่อนอัปโหลด',
  customMappingTemplate: 'แบบฟอร์มการแม็ปที่กำหนดเอง',
  detail_customMappingTemplate: 'กรุณาดาวน์โหลดแบบฟอร์มเพื่อกรอกข้อมูลให้ถูกต้อง ก่อนอัปโหลด',

  //
  deletingFileData: 'กำลังลบข้อมูลไฟล์',
  //modal
  modal_notComeBackEdit: 'หากยกเลิกแล้วจะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก',
  modal_wantToConfirm: 'ต้องการยืนยันใช่หรือไม่ ?',
  modal_wantToCancelDataImport: 'ต้องการยกเลิกการนำเข้าข้อมูล',
  modal_wantToCancel: 'ต้องการยกเลิกใช่หรือไม่ ?',
  modal_deleteCustomTemplate: 'ลบเทมเพลตแบบกำหนดเอง',
  modal_WantToDelete_orNot: 'ต้องการลบ {name} หรือไม่ ?',
  modal_AnErrorOccurred: 'เกิดข้อผิดพลาด!',
  modal_transactionSuccess: 'ทำรายการสำเร็จ!',
  modal_transactionFailed: 'ทำรายการไม่สำเร็จ',
  //modal FastHelps5
  modal_contact_information: 'ข้อมูลติดต่อ',
  modal_button_line_AddFriends: 'เพิ่มเพื่อน',
  modal_button_back: 'ย้อนกลับ',
};
