export default {
  config: {
    role_all: 'ทั้งหมด',
    role_super_admin: 'ซุปเปอร์เเอดมิน',
    role_admin: 'เเอดมิน',
    role_editor: 'ผู้เเก้ไข',
    role_generaluser: 'ผู้ใช้งานทั่วไป',
    role_onlysee: 'ดูได้เท่านั้น',
    role_choco_employee: 'พนักงานช็อคโก้',
    role_master: 'มาสเตอร์',
    role_choco_developer: 'นักพัฒนาช็อคโก้',
    status_all: 'สถานะทั้งหมด',
    status_active: 'ใช้งาน',
    status_inactive: 'ไม่ใช้งาน',
    status_pending: 'รอตอบรับ',
    status_archived: 'การเชิญหมดอายุ',

    manage_role: 'จัดการสิทธิ์การใช้งาน',
    dashboard: 'เเดชบอร์ด',
    create_audience: 'กลุ่มเป้าหมาย',

    edit: 'เเก้ไข',
    delete: 'ลบ',
    build: 'สร้าง',
  },

  button_menu: {
    profile: 'โปรไฟล์',
    manage_role: 'จัดการสิทธิ์การใช้งาน',
  },

  profile_detail: {
    your_profile: 'โปรไฟล์',
    manage_profile: 'จัดการโปรไฟล์ของคุณ',
    use: 'ใช้งาน',
    not_use: 'ไม่ใช้งาน',
    format_image: 'สิทธิ์ รูปแบบไฟล์ PNG หรือ JPEG ขนาดไม่เกิน 2 MB​',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    phone: 'เบอร์โทรศัพท์​',
    email: 'อีเมล',
    role: 'บทบาท',
    title_role: 'รายละเอียดบทบาท',
    save_done: 'บันทึกสำเร็จ',
    save_fail: 'บันทึกไม่สำเร็จ',
    upload_photo: 'กำลังอัพโหลดรูปภาพ',
    record: 'บันทึก',
  },

  manage_all: {
    manage_role: 'จัดการสิทธิ์การใช้งาน',
    manage_member: 'การจัดการสิทธิ์การใช้งานของผู้ใช้งาน',
  },

  user_list: {
    number: 'ลำดับ',
    image: 'รูปภาพ',
    firstname: 'ชื่อจริง',
    lastname: 'นามสกุล',
    phone: 'เบอร์โทรศัพท์',
    email: 'อีเมล',
    role: 'สิทธิ์การใช้งาน',
    update: 'อัปเดตล่าสุด',
    status: 'สถานะ',
    user_list: 'รายชื่อผู้ใช้งาน',
    manage_role: 'จัดการสิทธิ์การใช้งาน',
    activity: 'ประวัติการใช้งาน',
    role_all: 'สิทธิ์ทั้งหมด',
    status_all: 'สถานะทั้งหมด',
    invite: 'เชิญ',
    manage: 'จัดการ',
    edit: 'เเก้ไข',
    delete: 'ลบ',
    history: 'ประวัติการใช้งาน',
    send_again: 'ส่งอีกครั้ง',
    cancel: 'ยกเลิก',
  },

  manage_role: {
    number: 'ลำดับ',
    role: 'สิทธิ์การใช้งาน',
    update_by: 'อัปเดตโดย',
    update: 'อัปเดตล่าสุด',
    status: 'สถานะ',
    user_list: 'รายชื่อผู้ใช้งาน',
    manage_role: 'จัดการสิทธิ์การใช้งาน',
    role_all: 'สิทธิ์ทั้งหมด',
    status_all: 'สถานะทั้งหมด',
    title_role: 'ดูสิทธิ์การใช้งาน',
  },

  activity: {
    activity_all: {
      list: 'รายการ',
      filter_data: 'กรองข้อมูล',
      clear_value: 'ล้างค่าทั้งหมด',
      role: 'สิทธฺ์การใช้งาน',
      function: 'ฟังก์ชัน',
      action: 'การกระทำ',
      update: 'อัพเดตล่าสุด',
      apply: 'นำไปใช้',
      reset: 'รีเซ็ต',
      last_number: '5 อันดับใช้งานล่าสุด',
      symbol: 'สัญลักษณ์',
    },

    activity_per_person: {
      list: 'รายการ',
      filter_data: 'กรองข้อมูล',
      clear_value: 'ล้างค่าทั้งหมด',
      function: 'ฟังก์ชัน',
      action: 'การกระทำ',
      update: 'อัพเดตล่าสุด',
      apply: 'นำไปใช้',
      reset: 'รีเซ็ต',
      last_number: '5 อันดับใช้งานล่าสุด',
      symbol: 'สัญลักษณ์',
      back: 'กลับ',
      history: 'ประวัติการใช้งาน',
      profile: 'โปรไฟล์',
      use: 'ใช้งาน',
      not_use: 'ไม่ใช้งาน',
      to: 'ถึง',
    },
  },

  modal: {
    role_detail: {
      admin: 'แอดมิน',
      editor: 'ผู้เเก้ไข',
      general_user: 'ผู้ใช้งานทั่วไป',
      only_see: 'ดูได้เท่านั้น',
      role: 'สิทธิ์การใช้งาน',
      dashboard: 'แดชบอร์ด',
      create_audience: 'กลุ่มเป้าหมาย',
      data_conector: 'เชื่อมต่อข้อมูล',
      communication: 'การสื่อสาร',
      mka: 'การตลาดอัตโนมัติ',
      manage_role: 'การจัดการสิทธิ์',
      change_password: 'เปลี่ยนรหัสผ่าน',
      detail: 'รายละเอียดสิทธิ์​',
    },

    user_invite: {
      invite: 'เชิญผู้ใช้งาน',
      add_user_detail_1: 'กรุณากรอกอีเมลของผู้ใช้งาน โดยระบบจะส่งคำเชิญ',
      add_user_detail_2: 'ไปยังอีเมลเพื่อเข้าสู่ระบบ ChocoCDP',
      role_detail: 'รายละเอียดสิทธิ์',
      add_email: 'เพิ่มอีเมล',
      send_invite: 'ส่งคำเชิญ',
      manage_role: 'จัดการสิทธิ์การใช้งาน',
      email: 'อีเมล',
      role_all: 'จัดการสิทธิ์',
      placeholder: 'เลือกสิทธิ์การใช้งาน',
    },

    confirm_welcome: {
      title: 'ยินดีต้อนรับ',
      sub_title_1: 'คุณ',
      sub_title_2: 'เข้าสู่ระบบ ChocoCDP',
      content_1: 'ChocoCDP เป็นเครื่องมือสำหรับการทำ Digital Marketing เพื่อให้ธุรกิจทำการตลาดได้ผลลัพธ์อย่างมีประสิทธิภาพ',
      content_2: 'ช่วยให้การจัดการข้อมูลลูกค้าที่มาจากหลากหลายแหล่งที่มารวบรวมเข้ามาไว้ที่เดียว ตอบโจทย์เรื่องการวิเคราะห์ข้อมูลลูกค้า',
      content_3: 'เพื่อส่งมอบประสบการณ์ดีๆ ที่เหมาะสมแก่ลูกค้า และยังช่วยเพื่มโอกาสทางการขายได้ดียิ่งขึ้น',
      role: 'สิทธิ์การใช้งาน',
      start: 'เริ่มต้นการใช้งาน',
    },

    validation: {
      cancel: {
        invite_cancel_success: 'ยกเลิกการเชิญเรียบร้อย',
        invite_cancel: 'ยกเลิกการเชิญ',
        want_invite_cancel: 'ต้องการยกเลิกการเชิญอีเมล',
        confirm_cancel: 'ยืนยันการยกเลิกใช่หรือไม่',
        back: 'ย้อนกลับ',
        ok: 'ยืนยัน',
      },
      delete: {
        delete_success: 'ลบผู้ใช้งานเรียบร้อย',
        delete_user: 'ลบผู้ใช้งาน',
        want_delete_user: 'ต้องการลบผู้ใช้งานอีเมล',
        confirm_delete_user: 'ยืนยันการลบใช่หรือไม่',
        back: 'ย้อนกลับ',
        ok: 'ยืนยัน',
      },
      resend: {
        invite_email_success: 'ส่งอีเมลเชิญเรียบร้อย',
        resend_email: 'ส่งอีเมลเชิญอีกครั้ง',
        want_resend_email: 'หากต้องการส่งเชิญไปยังอีเมล',
        again: 'อีกครั้ง',
        please_click: 'กรุณาคลิก',
        send_again: '“ส่งอีกครั้ง”',
        confirm_resend_email: 'เพื่อให้ผู้ถูกเชิญตอบรับคำเชิญ',
        close: 'ปิด',
        ok: 'ส่งอีกครั้ง',
      },
      warning: {
        title_size: 'ขนาดไฟล์ใหญ่เกินไป',
        desc_size: 'กรุณาอัปโหลดไฟล์ขนาดไม่เกิน “2 MB”',
        title_type: 'ประเภทไฟล์ไม่ถูกต้อง',
        desc_type: 'กรุณาอัปโหลดไฟล์ประเภท “PNG เเละ JEG” เท่านั้น',
      },
    },
  },

  invite_profile: {
    title: 'โปรไฟล์',
    sub_title: 'โปรดกรอกรายละเอียดเกี่ยวกับโปรไฟล์ของคุณ',
    firstname: 'ชื่อจริง',
    lastname: 'นามสกุล',
    phone: 'เบอร์โทรศัพท์',
    email: 'อีเมล',
    role: 'จัดการสิทธิ์',
  },

  create_password: {
    password: 'รหัสผ่าน',
    confirm_password: 'ยืนยันรหัสผ่าน',
    title: 'สร้างรหัสผ่านใหม่',
    sub_title_step1: 'รหัสผ่านใหม่ของคุณสำหรับ',
    sub_title_step2: ' ต้องแตกต่างจากรหัสผ่านที่คุณเคยใช้ก่อนหน้านี้',
    placeholder_password: 'รหัสผ่านใหม่',
    placeholder_confirm_password: 'ยืนยันรหัสผ่านใหม่',
    rule1: 'รหัสผ่านต้องตรงกัน',
    rule2: 'รหัสผ่านต้องมีมากกว่า 8 ตัวอักษร',
    rule3: 'รหัสผ่านต้องมีตัวอักษรตัวเล็กอย่างน้อย 1 ตัวอักษร',
    rule4: 'รหัสผ่านต้องมีตัวอักษรตัวใหญ่อย่างน้อย 1 ตัวอักษร',
    rule5: 'รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัวอักษร (0-9)',
    rule6: "รหัสผ่านต้องมีอักขระพิเศษอย่างน้อย 1 ตัวอักษร (!{'@'}#{'$'}%^&*())",
    btn: 'สร้างรหัสผ่าน',
    btn_submit: 'ยืนยัน',
    btn_back: 'ย้อนกลับ',
  },

  reset_password: {
    password: 'รหัสผ่าน',
    confirm_password: 'ยืนยันรหัสผ่าน',
    title: 'เปลี่ยนรหัสผ่าน',
    description_first: 'กรุณากรอกรหัสเดิมเพื่อตั้งรหัสผ่านใหม่',
    header: 'รหัสผ่านเดิม',
    placeholder: 'รหัสผ่านเดิมของคุณ',
    button: 'ถัดไป',
    error_text: 'รหัสผ่านไม่ถูกต้อง',
    notemail_text: 'ไม่พบรหัสผ่านนี้ในระบบ กรุณาตรวจสอบอีกครั้ง',
    back: 'กลับ',

    check_email: {
      title: 'เรากำลังช่วยเหลือคุณ',
      description_first: 'เรากำลังค้นหารายการในระบบเพื่อยืนยันบัญชี CHOCO CDP ',
      description_second: 'สำหรับ ',
      go_back: 'กลับไปที่ ChocoFor ',
      link_login: 'portal.chococdp.com/login',
    },

    expire_email: {
      title: 'ลิงก์ของคุณหมดอายุแล้ว',
      description_first: 'หากคุณต้องการดำเนินการรีเซ็ตรหัสผ่านต่อ ',
      re_click: 'กรุณาคลิกลิงก์นี้อีกครั้ง ',
      link_forgot_password: 'portal.chococdp.com/forgot-password',
    },

    confirm_change_password: {
      password_change_success: 'เปลี่ยนเเปลงรหัสผ่านเรียบร้อย',
      password_change_confirm: 'ยืนยันการเปลี่ยนรหัสผ่าน',
      sign_out: 'ออกจากระบบในทุกอุปกรณ์',
      stay: 'อยู่ในระบบต่อไป',
      back: 'ย้อนกลับ',
      ok: 'ยืนยัน',
    },

    success_modal: {
      header: 'เปลี่ยนรหัสสำเร็จ',
      title_1: 'บัญชีของผู้ใช้งานที่เข้าสู่ระบบ',
      title_2: 'ไว้ในอุปกรณ์ทั้งหมดถูกเคลียร์เรียบร้อย',
      submit: 'เข้าสู่ระบบใหม่',
    },
  },

  change_password: {
    title: 'เปลี่ยนรหัสผ่าน',
    sub_title_step1: 'รหัสผ่านใหม่ของคุณสำหรับ',
  },
  total: 'ทั้งหมด',
};
