export default {
  notification: 'Notification',
  contact_us_modal: {
    contact_us: 'Contact Us',
    line_add: 'Add Line',
    scan_qr: 'Scan QR CODE',
    request_add_credit: 'to submit a credit top-up request.',
  },

  noti_credit_modal: {
    title: 'Notifications',
    button: 'Seemore',
    detail: {
      storage: 'Storage',
      top_up: 'Top Up',
    },
    credit_empty: 'Out Of Credit',
    credit_non_empty: 'Credit Running Out',
    storage_empty: 'Out Of Storage',
    storage_non_empty: 'Low storage remaining',
  },

  noti_credit_success: {
    title: 'Topup Credit Success',
    content_one: 'Campaigns currently in used',
    num: '',
    list: 'campaign',
    campaign: 'campaign',
    content_two: 'to manage not enough credit campaigns, go to',
    manage: 'Manage Campaign',
    add_credit: 'Credit Topup',
    total_credit: 'Credit Balance',
    skip_button: 'Skip',
  },

  card_credit_notification: {
    title: {
      normal: 'Healthy Credit',
      empty: 'Out Of Credit',
      non_empty: 'Credit Running Out',
    },
    remaining_credit: 'Credit Balance',
    text_underline_credit: 'Campaign and credits in use',
    active_campaign: 'Campaign in use',
    pre_credit: 'Credit to be used',
    see_more: 'See more',
  },

  main_notification: {
    tooltip_text: 'Please read all notifications',
    ask_more: 'Contact us',

    menu: {
      credit: 'Credit Notifications',
      storage: 'Storage Notifications',
      top_up: 'Topup Notifications',
    },

    credit: {
      title: 'Credit',
      tab: {
        all: 'All',
        empty: 'Out Of Credit',
        non_empty: 'Credit Running Out',
        normal: 'Healthy Credit',
      },
      button: 'Topup Credit',
    },

    detail_credit: {
      list: 'List',
      show: 'Show',
      back: 'Back',
      title_header: 'More Detail',
      des_header_one: 'More',
      des_header_two: 'detail',
      popup: {
        red: {
          title: 'Out Of Credit',
          description:
            'Please add enough credit. The campaigns shown are all campaigns that require credit, including campaigns that have already run and do not have enough credit. And/or campaigns that are about to be run in the next round.',
        },
        yellow: {
          title: 'Credit Running Out',
          description:
            'Please add enough credit. The campaigns shown are all campaigns that require credit, including campaigns that have already run and do not have enough credit. And/or campaigns that are about to be run in the next round.',
        },
      },
      tab: {
        all: 'All Campaigns',
        commu: 'Communication',
        mka: 'Marketing Automation​',
      },
      button: 'Topup Credit',
    },

    storage: {},
    empty: 'No item',
  },

  campaign_pause_notification: 'Not enough credit Campaign',
  box_credit_notification: {
    content: 'Credit is not enough for all recipients.',
    add_credit: 'Add Credit',
  },

  notification_bell: {
    title: 'Notification',
    seconds: 'seconds ago',
    minutes: 'minutes ago',
    hours: 'hours ago',
    month_thai: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  },
  no: 'No.',
  CampaignName: 'Campaign Name',
  Module: 'Module',
  channel: 'Channel',
  schedule_mode: 'Schedule',
  count_dispatchable: 'Total Recipients',
  count_success: 'Success',
  count_pendingcredit: 'Pending',
  est_credit_use: 'Credit to be used',
  updated: 'Latest update',
  status: 'Status',
  send_now: 'Send Now',
  schedule_later: 'Schedule a later',
  repeat: 'Repeat',
  pause: 'Pause',
  dispath: 'Sending',
  scheduled: 'Success',
  preparing: 'Preparing',
  not_found:'Not Found',
  tooltip_column_accum_recp:
    'Count cumulatively, single-round Communications campaigns are counted first round only. Repeat Communications campaigns are counted cumulatively from first round  to the next round. Marketing Automation campaigns are counted cumulatively according to the current number of recipients that are currently in the flow. (Count only recipients who is sent but received insufficient credit) ',
  card_provider_one: 'Provider not found',
  card_provider_two: 'Please contact for setup.',
};
