<template>
  <div class="base-loading">
    <transition name="base-loading-animation" appear>
      <div v-show="isLoadingConfig.isOpen" class="base-loading-container">
        <div class="base-loading-backdrop-container"></div>
        <div class="content-container">
          <transition name="base-loading-animation-inner" appear appear-class="custom-appear">
            <div class="content-container">
              <div class="base-loading-inner" :class="{ 'bg-active': isLoadingConfig.title }">
                <div class="loading-icon text-center">
                  <img src="/gif/circle_loading.gif" alt="loading" />
                </div>
                <div class="loading-content mt-3 ps-2 pe-2 text-center" :hidden="!isLoadingConfig.title || isLoadingConfig.title == ''">
                  <span>{{ isLoadingConfig.title }}</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useLoadingStore } from '@/store/loadingStore';
import { storeToRefs } from 'pinia';

const LoadingStore = useLoadingStore();
const { isLoadingConfig } = storeToRefs(LoadingStore);
</script>

<style scoped lang="scss">
@use '@/assets/styles/base/base-loading/base-loading.scss';
</style>
