<template>
  <router-link v-bind="linkProps" class="text-decoration-none" v-slot="{ href }">
    <div class="sub-menu-lvl1" :href="href" @click="onClickSubMenu()">
      <div class="title" :class="item.path == $route.path ? 'active' : null">
        <div class="navigation-sub-content-wrp">
          {{ t(item.title) }}
        </div>
      </div>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useVerticalNavMenuLink from './useVerticalNavMenuLink';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
    mainIndexMenu: {
      type: Number,
      required: false,
    },
  },

  setup(props) {
    const { isActive, linkProps, updateIsActive, onClickSubMenu } = useVerticalNavMenuLink(props.item, props.mainIndexMenu);
    const { t } = useI18n();
    return {
      isActive,
      linkProps,
      updateIsActive,
      t,
      onClickSubMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/vertical-nav-bar.scss';
</style>
