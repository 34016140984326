import { acceptHMRUpdate, defineStore } from 'pinia';

export const useRouterBypassStore = defineStore({
  id: 'router_bypass_confirmation',

  state: (): { bypassConfirmation: boolean } => ({
    bypassConfirmation: false,
  }),
  getters: {
    isBypassConfirmation(state): boolean {
      return state.bypassConfirmation;
    },
  },
  actions: {
    update(isBypass: boolean) {
      this.bypassConfirmation = isBypass;
    },
    reset() {
      this.bypassConfirmation = false;
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRouterBypassStore, import.meta.hot));
}
