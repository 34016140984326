<template>
  <div :id="String(idAuth)" :class="['base-authenticator-modal', { active: is_MFA_StatusActive }]">
    <base-modal
      class="modal-authenticator"
      hide-footer
      hide-header
      :is-modal-open="userInfo?.mfa_modal ?? false"
      @open-modal="onOpenModal"
      @hide="onHideModal"
      size="md"
    >
      <template #modal-body>
        <div class="authenticator-modal-container">
          <!-- Header -->
          <div class="header-modal">
            <div class="text-container">
              <div class="text-header">{{ t('authentication.text_header') }}</div>
              <div v-if="stepProgress != STEP_PROGRESS.COMPLETE" class="description-container">
                <div class="description-header">{{ t('authentication.description_header1') }}</div>
                <div class="description-header">{{ t('authentication.description_header2') }}</div>
              </div>
            </div>
            <div :class="['progress-container', { active: is_MFA_StatusActive }]">
              <!-- stepProgress -->
              <div>
                <div class="step-navbar-authenticator d-flex flex-row justify-content-center align-items-center mx-1">
                  <div class="steparea-wrp-authenticator steparea-wrp-shadow w-100">
                    <div>
                      <div class="d-flex justify-content-center align-items-center">
                        <ul v-if="is_MFA_StatusActive" id="stepper-progress" class="w-100 steps"></ul>
                        <ul v-else id="stepper-progress" class="w-100 steps">
                          <li
                            v-for="numStep in 4"
                            :key="numStep"
                            :style="['--icon:']"
                            class="step-container"
                            :class="[stepProgress == numStep ? 'step-active' : '', stepProgress > numStep ? 'completed-step' : '']"
                          >
                            <div class="icon-area">
                              <div v-if="stepProgress == numStep" class="completed-step-icon">
                                <ProggressingIcon />
                              </div>
                              <div v-else-if="stepProgress > numStep" class="not-completed-step-icon">
                                <TickIcon />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div :class="['container-auth', { active: is_MFA_StatusActive }]">
            <!-- Step 1: Select App -->
            <div v-if="stepProgress == STEP_PROGRESS.START" class="step-one">
              <h2>{{ t('authentication.step') }} 1</h2>
              <h3>{{ t('authentication.start_by_getting_the_app') }}</h3>
              <p>{{ t('authentication.please_install1') }}</p>
              <div :id="String(idAuth)" class="auth-apps">
                <div
                  class="app"
                  :class="selectedApp == APPICATION_SYSTEM.MICROSOFT ? 'app-active' : ''"
                  @click="onSelectedApp(APPICATION_SYSTEM.MICROSOFT)"
                >
                  <img src="@assets\images\modules\authentication\microsoft_authenticator_logo.png" alt="Microsoft Authenticator" />
                  <div>
                    <div>Microsoft Authenticator</div>
                    <!-- <div>
                      <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank">iOS</a> /
                      <a
                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator&referrer=%20adjust_reftag%3Dc6f1p4ErudH2C%26utm_source%3DLanding%2BPage%2BOrganic%2B-%2Bapp%2Bstore%2Bbadges%26utm_campaign%3Dappstore_android"
                        target="_blank"
                        >Android</a
                      >
                    </div> -->
                    <div>iOS / Android</div>
                  </div>
                </div>
                <div class="divider">{{ t('authentication.divider') }}</div>
                <div
                  class="app"
                  :class="selectedApp == APPICATION_SYSTEM.GOOGLE ? 'app-active' : ''"
                  @click="onSelectedApp(APPICATION_SYSTEM.GOOGLE)"
                >
                  <img src="@assets\images\modules\authentication\google_authenticator_logo.png" alt="Google Authenticator" />
                  <div>
                    <div>Google Authenticator</div>
                    <!-- <div>
                      <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">iOS</a> /
                      <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1" target="_blank"
                        >Android</a
                      >
                    </div> -->
                    <div>iOS / Android</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 2: Download App -->
            <div v-else-if="stepProgress == STEP_PROGRESS.INSTALL" class="step-two">
              <h2>{{ t('authentication.step') }} 2</h2>
              <h3>{{ t('authentication.install_an_authentication_app') }}</h3>
              <p>
                {{ t('authentication.please_install2') }}
                {{ selectedApp == APPICATION_SYSTEM.MICROSOFT ? 'Microsoft Authenticator' : 'Google Authenticator' }}
                {{ t('authentication.on_your_mobile') }}
              </p>
              <div class="platform-links-container">
                <div class="platform-links">
                  <div class="button-platform" :class="{ active: selectedPlatform == 'iOS' }" @click="selectPlatform('iOS')">iOS</div>
                  <div class="button-platform" :class="{ active: selectedPlatform == 'Android' }" @click="selectPlatform('Android')">Android</div>
                </div>
              </div>

              <div class="box-qr-code">
                <div class="qr-code-container">
                  <img :src="resolveQR(selectedApp, selectedPlatform)" alt="QR Code" />
                </div>
              </div>
            </div>

            <!-- Step 3: QR Code -->
            <div v-else-if="stepProgress == STEP_PROGRESS.SCAN_QR" class="step-three">
              <h2>{{ t('authentication.step') }} 3</h2>
              <h3>{{ t('authentication.scan_qr_code') }}</h3>
              <p>
                {{ t('authentication.use_the') }}
                {{ selectedApp == APPICATION_SYSTEM.MICROSOFT ? 'Microsoft Authenticator' : 'Google Authenticator' }}
                {{ t('authentication.application_to_scan') }}
                {{ selectedApp == APPICATION_SYSTEM.MICROSOFT ? 'Microsoft Authenticator' : 'Google Authenticator' }}
                {{ t('authentication.application_with_your_account') }}
              </p>
              <p class="instruction">{{ t('authentication.scan_to_next') }}</p>
              <div class="container-step-three">
                <div class="left-container">
                  <img src="@assets\images\modules\authentication\iphone_app_auth.png" alt="Phone Image" />
                </div>
                <div class="right-container">
                  <div class="qr-scan-container">
                    <canvas ref="canvas"></canvas>
                  </div>
                  <!-- <div class="ref-qr-code-scan">
                    <span>{{ credSecret }}</span>
                  </div> -->
                </div>
              </div>
            </div>

            <!-- Step 4: OTP -->
            <div v-else-if="stepProgress == STEP_PROGRESS.AUTHENTICATION" class="step-four">
              <template v-if="is_MFA_StatusActive">
                <div class="text-header">{{ t('authentication.verify_authentication_code') }}</div>
                <div class="description-container">
                  <div class="description-header">{{ t('authentication.enter_the_six_digit') }}</div>
                </div>
              </template>
              <template v-else>
                <h2>{{ t('authentication.step') }} 4</h2>
                <h3>{{ t('authentication.verify_authentication_code') }}</h3>
                <p>{{ t('authentication.open_your_two_factor') }}</p>
              </template>
              <transition name="slide-fade">
                <div v-if="isValidateError" :class="['error-otp-container', { active: is_MFA_StatusActive }]">
                  <div class="left-error">
                    <div class="icon-error">
                      <ErrorIcon />
                    </div>
                  </div>
                  <div class="right-error">
                    <span>{{ t('authentication.error') }}</span>
                  </div>
                </div>
              </transition>

              <div class="otp-container">
                <OtpInput
                  ref="otpInput"
                  :max-digit="6"
                  :valid-error="isValidateError"
                  :input-value="otp"
                  @clear-error-class="clearErrorClass"
                  @callback-max-degit="CompleteVerification"
                />
                <button @click="CompleteVerification" class="btn btn-primary button-complete">
                  {{ t('authentication.button_complete') }}
                  <div class="spinner-border" role="status" v-if="isLoadingVerify">
                    <span class="visually-hidden">{{ t('authentication.loading') }}...</span>
                  </div>
                </button>
              </div>
            </div>
            <!-- Step 5: Complete -->
            <div v-else-if="stepProgress == STEP_PROGRESS.COMPLETE" class="step-five">
              <div>
                <h3>{{ t('authentication.authenticator_setup_complete') }}</h3>
              </div>
              <div>
                <p>{{ t('authentication.description_header_complete') }}</p>
              </div>

              <div class="pic-complete-container">
                <img src="@assets\images\modules\authentication\iphone-setup-complete.png" alt="Complete Image" />
              </div>
              <div style="width: 100%">
                <button @click="DoneComplete" class="btn btn-primary button-done">
                  {{ t('authentication.done') }}
                </button>
              </div>
            </div>
          </div>

          <div v-if="stepProgress != STEP_PROGRESS.COMPLETE" class="footer-auth">
            <!-- Button -->
            <div v-if="stepProgress != STEP_PROGRESS.AUTHENTICATION" class="button-container">
              <button v-if="stepProgress != STEP_PROGRESS.START" @click="goBack" class="btn btn-outline-primary back-button">
                {{ t('authentication.back') }}
              </button>
              <button @click="goNext" class="btn btn-primary next-button">{{ t('authentication.next') }}</button>
            </div>
            <div v-else-if="stepProgress == STEP_PROGRESS.AUTHENTICATION" class="trouble-container">
              <div class="text-container">{{ t('authentication.trouble_text') }}</div>
            </div>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, reactive, Ref, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { APPICATION_SYSTEM, MFA_STATUS, STEP_PROGRESS } from '@/constants/modules/authentication/multi-factor-authen';
import QRCode from 'qrcode';

//pinia
import { storeToRefs } from 'pinia';
import { useAuthStore, userInfoConstant } from '@/store/authStore';

import api from '@/services/api';
import router from '@/router';

// api
import mfaModel from '@/models/authentication/mfa';

import OtpInput from '@/views/components/modal/components/OtpInput.vue';

import BaseModal from '@/views/components/modal/BaseModal.vue';
import ProggressingIcon from '@/assets/icons/modules/authentication/ProggressingIcon.vue';
import TickIcon from '@/assets/icons/modules/authentication/TickIcon.vue';
import ErrorIcon from '@/assets/icons/modules/authentication/ErrorIcon.vue';

import microsoftIos from '@/assets/images/modules/authentication/qr-code/microsoft-ios.png';
import microsoftAndroid from '@/assets/images/modules/authentication/qr-code/microsoft-android.png';
import googleIos from '@/assets/images/modules/authentication/qr-code/google-ios.png';
import googleAndroid from '@/assets/images/modules/authentication/qr-code/google-android.png';
import { deepClone } from '@/views/modules/customer-insight/utils';

const authStore = useAuthStore();
const { userInfo } = storeToRefs(authStore);

const { t } = useI18n();

const { fetchMfaRegister, fetchMfaTotpConfirm, fetchMfaCheck } = mfaModel();

const is_MFA_StatusActive = ref<boolean>(false);
const otp: Ref<{ input: string }> = ref({ input: '' });
const otpInput: Ref<HTMLDivElement> = ref(null!);

const userInfoRef = ref(deepClone(userInfoConstant));

const isValidateError = ref<boolean>(false);
const isLoadingVerify = ref<boolean>(false);
const isCredentialValid = ref<boolean>(false);

const stepProgress = ref<number>(STEP_PROGRESS.START);
const idAuth = ref<number>(1);
const selectedApp = ref(APPICATION_SYSTEM.MICROSOFT);
const selectedPlatform = ref('iOS');
const canvas = ref<HTMLCanvasElement | null>(null);
const qrString = ref<string>('NOT FOUND');
const credSecret: Ref<string | null> = ref('NOT FOUND');

function onHideModal() {
  userInfoRef.value.mfa_modal = false;
  authStore.setUserInfo(userInfoRef.value);
}
function clearErrorClass() {
  isValidateError.value = false;
}
function onSelectedApp(app: number) {
  selectedApp.value = app;
  idAuth.value++;
}

function GenQRToCanvas() {
  if (stepProgress.value == STEP_PROGRESS.SCAN_QR) {
    if (canvas.value) {
      QRCode.toCanvas(canvas.value, qrString.value)
        .then(() => {})
        .catch((error: any) => {
          console.error('Failed to generate QR code:', error);
        });
    }
  }
}

function goBack() {
  if (stepProgress.value > STEP_PROGRESS.START) {
    stepProgress.value--;
    nextTick(() => {
      GenQRToCanvas();
    });
  }
  idAuth.value++;
}

function resolveQR(selectedApp: number, selectedPlatform: string) {
  switch (selectedApp) {
    case 1:
      if (selectedPlatform == 'iOS') {
        return microsoftIos;
      } else {
        return microsoftAndroid;
      }

    case 2:
      if (selectedPlatform == 'iOS') {
        return googleIos;
      } else {
        return googleAndroid;
      }
    default:
      return microsoftIos;
  }
}

function selectPlatform(platform: string) {
  selectedPlatform.value = platform;
}

function goNext() {
  if (stepProgress.value < STEP_PROGRESS.AUTHENTICATION) {
    stepProgress.value++;
    nextTick(() => {
      GenQRToCanvas();
    });
  }
  idAuth.value++;
}

function DoneComplete() {
  userInfoRef.value.credential_valid = isCredentialValid.value;
  userInfoRef.value.mfa_modal = false;
  authStore.setUserInfo(userInfoRef.value);
}

function CompleteVerification() {
  // API TOTP
  if (userInfo.value?.mfa_status == MFA_STATUS.PENDING) {
    fetchTotpConfirm();
  } else {
    fetchTotpVerify();
  }
}

function getSecret(url: string): string | null {
  const match = url.match(/secret=([^&]*)/);
  return match ? match[1] : null;
}

function getMfaRegister() {
  fetchMfaRegister.payload.Token = userInfo.value?.mfa_token;
  api
    .apiRequest(fetchMfaRegister)
    .then((response) => {
      if (response.status == 'success') {
        qrString.value = response.data.credential;
        credSecret.value = getSecret(response.data.credential as string);
        GenQRToCanvas();
      }
    })
    .catch((error) => {
      // TODO: Handle Error
    });
}

function fetchTotpVerify() {
  isLoadingVerify.value = true;
  fetchMfaCheck.payload.Token = userInfo.value?.mfa_token;
  fetchMfaCheck.payload.MFA_Credential = otp.value.input;
  api
    .apiRequest(fetchMfaCheck)
    .then((response) => {
      if (response.status == 'success') {
        if (response.data.credential_valid) {
          isCredentialValid.value = response.data.credential_valid;
          if (userInfo.value?.mfa_status == MFA_STATUS.PENDING) {
            stepProgress.value = STEP_PROGRESS.COMPLETE;
          } else {
            userInfoRef.value.credential_valid = isCredentialValid.value;
            userInfoRef.value.mfa_modal = false;
            authStore.setUserInfo(userInfoRef.value);
          }
        } else {
          isValidateError.value = true;
          (otpInput as any).value.clearInput();
        }
      } else {
        isValidateError.value = true;
        (otpInput as any).value.clearInput();
      }
    })
    .catch((error) => {
      isValidateError.value = true;
      (otpInput as any).value.clearInput();
    })
    .finally(() => {
      isLoadingVerify.value = false;
    });
}

function fetchTotpConfirm() {
  isLoadingVerify.value = true;
  fetchMfaTotpConfirm.payload.Token = userInfo.value?.mfa_token;
  fetchMfaTotpConfirm.payload.MFA_Credential = otp.value.input;
  api
    .apiRequest(fetchMfaTotpConfirm)
    .then((response) => {
      if (response.status == 'success') {
        if (response.data.credential_valid) {
          isCredentialValid.value = response.data.credential_valid;
          if (userInfo.value?.mfa_status == MFA_STATUS.PENDING) {
            stepProgress.value = STEP_PROGRESS.COMPLETE;
          } else {
            userInfoRef.value.credential_valid = isCredentialValid.value;
            userInfoRef.value.mfa_modal = false;
            authStore.setUserInfo(userInfoRef.value);
          }
        } else {
          isValidateError.value = true;
          (otpInput as any).value.clearInput();
        }
      } else {
        isValidateError.value = true;
        (otpInput as any).value.clearInput();
      }
    })
    .catch((error) => {
      isValidateError.value = true;
      (otpInput as any).value.clearInput();
    })
    .finally(() => {
      isLoadingVerify.value = false;
    });
}

function onOpenModal() {
  if (userInfo.value?.mfa_modal && userInfo.value?.mfa_status == MFA_STATUS.PENDING) {
    // pending to register
    stepProgress.value = STEP_PROGRESS.START;
    is_MFA_StatusActive.value = false;
    getMfaRegister();
  } else if (userInfo.value?.mfa_modal && userInfo.value?.mfa_status == MFA_STATUS.ACTIVE) {
    // register already
    stepProgress.value = STEP_PROGRESS.AUTHENTICATION;
    is_MFA_StatusActive.value = true;
  }
  nextTick(() => {
    GenQRToCanvas();
  });
}

watch(
  userInfo,
  async (newValue) => {
    if (newValue) {
      userInfoRef.value = newValue;
    }
    if (newValue?.mfa_modal) {
      await nextTick(() => {
        if (userInfo.value?.mfa_modal) {
          if (userInfo.value?.mfa_status == MFA_STATUS.PENDING) {
            // pending to register
            stepProgress.value = STEP_PROGRESS.START;
            is_MFA_StatusActive.value = false;
            getMfaRegister();
          } else if (userInfo.value?.mfa_status == MFA_STATUS.ACTIVE) {
            // already register
            stepProgress.value = STEP_PROGRESS.AUTHENTICATION;
            is_MFA_StatusActive.value = true;
          }
        }
      });
    }
  },
  { deep: true },
);
</script>

<style scoped lang="scss">
//step navbar
.step-navbar-authenticator .steps {
  flex: 1;
  counter-reset: step;
  grid-auto-columns: 1fr;
  display: inline-grid;
  overflow: hidden;
  overflow-x: auto;
  grid-auto-flow: column;
  margin-top: 1rem;
  padding: 0;
  align-items: center;
}

.step-navbar-authenticator .icon-area {
  display: flex;
  width: 2.2rem;
  height: 2.2rem;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #cecece;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  place-self: center;
}

.step-navbar-authenticator .step-active .icon-area {
  background-color: white;
  color: #027ffe;
  border: 2px solid #027ffe;
}

.step-navbar-authenticator .completed-step .icon-area {
  background-color: #027ffe;
  color: white;
  border: 2px solid #027ffe;
}

.step-navbar-authenticator .icon-area svg {
  fill: #c6c6c6;
}

.step-navbar-authenticator .step-active .icon-area svg {
  fill: #027ffe;
}

.step-navbar-authenticator .completed-step .icon-area svg {
  fill: #c6c6c6;
}

.step-navbar-authenticator .step-container {
  position: relative;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: 2.4rem 1fr;
  min-width: 5rem;
  height: 100%;
}

.step-navbar-authenticator .step-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step-navbar-authenticator .icon-area .step-icon svg {
  width: 1rem;
  height: 1rem;
}

.step-navbar-authenticator .icon-area .step-icon svg,
.step-navbar-authenticator .icon-area .step-icon svg path {
  fill: #aaaaaa;
}

.step-navbar-authenticator .icon-area .step-icon svg rect {
  fill: #aaaaaa;
}

.step-navbar-authenticator .step-active .icon-area .step-icon svg,
.step-navbar-authenticator .completed-step .icon-area .step-icon svg,
.step-navbar-authenticator .step-active .icon-area .step-icon svg path,
.step-navbar-authenticator .completed-step .icon-area .step-icon svg path,
.step-navbar-authenticator .step-active .icon-area .step-icon svg rect,
.step-navbar-authenticator .completed-step .icon-area .step-icon svg path {
  fill: #c6c6c6;
}

.step-navbar-authenticator .step-active .step-title,
.step-navbar-authenticator .completed-step .step-title {
  opacity: 1;
}

.step-navbar-authenticator .step-title {
  color: #c6c6c6;
  opacity: 0.5;
  max-width: 100px;
  text-align: center;
  align-self: start;
  justify-self: center;
  z-index: 3;
}

.step-navbar-authenticator .steps .step-container-active {
  background: #027ffe !important;
}

.step-navbar-authenticator .steps .step-container.completed-step:before {
  background: #027ffe !important;
}

.step-navbar-authenticator .steps .step-container:first-child:before {
  content: '';
  position: absolute;
  top: 1.1rem;
  right: -25px;
  width: 60%;
  height: 0.1rem;
  background: #c6c6c6;
}

.step-navbar-authenticator .steps .step-container:nth-child(2):before {
  content: '';
  position: absolute;
  top: 1.1rem;
  right: -25px;
  width: 60%;
  height: 0.1rem;
  background: #c6c6c6;
}

.step-navbar-authenticator .steps .step-container:nth-child(3):before {
  content: '';
  position: absolute;
  top: 1.1rem;
  right: -25px;
  width: 60%;
  height: 0.1rem;
  background: #c6c6c6;
}

// .step-navbar-authenticator .steps .step-container:nth-child(n + 2):before {
//   content: '';
//   position: absolute;
//   top: 1.1rem;
//   width: 0%;
//   height: 0.1rem;
//   background: #c6c6c6;
// }

.step-navbar-authenticator .steparea-wrp-authenticator {
  flex: 1;
  position: relative;
  border: none;
  overflow: hidden;
}

.btn-primary {
  border: none;
  border-radius: 10px;
  background-color: #027ffe !important;
  color: white !important;
}

.base-authenticator-modal {
  :deep(.modal-dialog) {
    max-width: 40rem !important;
    border-radius: 20px !important;
    overflow: auto !important;
    width: 100% !important;

    .modal-content {
      min-height: 57rem !important;
    }
  }
}

.base-authenticator-modal.active {
  :deep(.modal-dialog) {
    max-width: 40rem !important;

    border-radius: 20px !important;
    overflow: auto !important;
    width: 100% !important;

    .modal-content {
      min-height: 45rem !important;
    }
  }
}

.base-authenticator-modal {
  // text-align: center;
  max-width: 400px;
  margin: auto;
  font-family: 'Prompt';
}

.modal-authenticator {
  // Add your custom modal styling here
}

.header-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .text-header {
    //styleName: H1;
    font-family: 'Prompt';
    font-size: 28px;
    font-weight: 400;
    line-height: 42.34px;
    text-align: center;
  }

  .description-container {
    margin-top: 0.5rem;

    .description-header {
      //styleName: H5;
      font-family: 'Prompt';
      font-size: 18px;
      font-weight: 400;
      line-height: 27.22px;
      text-align: center;
    }
  }

  .progress-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    &.active {
      display: none;
    }
  }
}

.container-auth {
  min-height: 33rem;
  margin-top: 1rem;

  &.active {
    min-height: 25rem;
    margin-top: 2.5rem;
  }

  .step-one,
  .step-two,
  .step-three,
  .step-four {
    h2 {
      font-size: 16px;
      margin-bottom: 16px;
    }

    h3 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      color: #555;
    }

    .text-header {
      font-family: 'Prompt';
      font-size: 24px;
      font-weight: 400;
      line-height: 42.34px;
      text-align: center;
    }

    .description-container {
      margin-top: 0.5rem;

      .description-header {
        //styleName: H5;
        font-family: 'Prompt';
        font-size: 16px;
        font-weight: 400;
        line-height: 27.22px;
        text-align: center;
      }
    }
  }

  .step-one {
    .auth-apps {
      display: flex;
      padding: 0px 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      margin-top: 55px;

      .app {
        cursor: pointer;
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        border-radius: 20px;
        border: 2px solid var(--Grey-border, #ededed);

        img {
          border-radius: 10px;
          box-shadow: 0px 4px 12.8px 0px rgba(0, 0, 0, 0.05);
          display: flex;
          width: 56px;
          height: 56px;
          justify-content: center;
          align-items: center;
        }
      }

      .app-active {
        border-radius: 20px;
        border: 2px solid var(--System-Blue-01, #027ffe);
        background: var(--Base-White, #fff);
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 24px;
      }

      .divider {
        color: #c6c6c6;
        text-align: center;
        /* H3 */
        font-family: Prompt;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .divider:before {
        content: '';
        right: 60px;
        top: 530px;
        position: absolute;
        width: 35%;
        height: 1px;
        background: #c6c6c6;
      }

      .divider:after {
        content: '';
        left: 60px;
        top: 530px;
        position: absolute;
        width: 35%;
        height: 1px;
        background: #c6c6c6;
      }
    }
  }

  .step-two {
    .platform-links-container {
      display: flex;
      justify-content: center;
      width: 100%;

      .platform-links {
        display: flex;
        padding: 10px;
        align-items: center;
        border-radius: 10px;
        background: var(--Gray-00, #eee);
        margin-top: 1rem;
        max-width: 180px;

        .button-platform {
          display: flex;
          min-width: 80px;
          padding: 4px;
          justify-content: center;
          align-items: center;
          color: var(--Gray-03, #929292);
          text-align: center;
          font-family: Prompt;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          cursor: pointer;

          &.active {
            display: flex;
            min-width: 80px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: var(--Base-White, #fff);
            color: var(--Blue-03, #007fff);
            text-align: center;
            font-family: Prompt;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            /* Shadow/Shadow 02 */
            box-shadow:
              0px 29px 8px 0px rgba(128, 128, 128, 0),
              0px 18px 7px 0px rgba(128, 128, 128, 0.01),
              0px 10px 6px 0px rgba(128, 128, 128, 0.05),
              0px 5px 5px 0px rgba(128, 128, 128, 0.09),
              0px 1px 3px 0px rgba(128, 128, 128, 0.1);
          }
        }
      }
    }

    .box-qr-code {
      display: flex;
      justify-content: center;

      .qr-code-container {
        margin-top: 1rem;
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        align-self: stretch;
        border-radius: 20px;
        border: 1px solid var(--Systemblue, #027ffe);

        img {
          display: flex;
          width: 240px;
          height: 240px;
          padding: 12px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .step-three {
    .container-step-three {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .left-container {
        margin-right: 1rem;
      }

      .right-container {
        .qr-scan-container {
          display: flex;
          padding: 5px;
          flex-direction: column;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          border-radius: 20px;
          border: 1px solid var(--Systemblue, #027ffe);

          canvas {
            width: 252px !important;
            height: 252px !important;
          }
        }

        .ref-qr-code-scan {
          max-width: 300px;
          margin-top: 10px;
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          background: #e5f2ff;

          span {
            color: var(--Base-Black, #000);
            font-family: Prompt;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    .instruction {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Base-Black, #000);
      /* Text Reg 16 */
      font-family: Prompt;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .step-four {
    .error-otp-container {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 10px;
      background: #fed8d5;

      &.active {
        margin-top: 2.5rem;
      }

      .left-error {
        .icon-error {
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .right-error {
        span {
          color: var(--Base-Black, #000);

          /* Text Reg 14 */
          font-family: Prompt;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .otp-container {
      margin-top: 3rem;
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid var(--Grey-border, #ededed);

      .button-complete {
        //styleName: H3;
        font-family: Prompt;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        width: 100%;
        border-radius: 10px;

        .spinner-border {
          width: 1.5rem;
          height: 1.5rem;
          vertical-align: -0.2em;
        }
      }
    }
  }

  .step-five {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;

    h3 {
      font-size: 24px;
      margin-bottom: 12px;
      color: #027ffe;
    }

    p {
      font-size: 16px;
      color: #555;
    }

    .pic-complete-container {
      margin-top: 5rem;
    }

    .button-done {
      width: 100%;
      height: 50px;
      margin-top: 7rem;
      border-radius: 10px;
    }
  }
}

.footer-auth {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  position: relative;

  .button-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    width: 100%;

    .back-button {
      margin-right: 0.5rem;
      min-width: 100px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: Prompt;
      border-radius: 10px;
    }

    .next-button {
      margin-left: 0.5rem;
      min-width: 100px;
      padding: 10px 20px;
      font-size: 16px;
      font-family: Prompt;
      border-radius: 10px;
    }
  }

  .trouble-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Base-Black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .trouble-container:before {
    content: '';
    right: 40px;
    bottom: 25px;
    position: absolute;
    width: 18%;
    height: 1.5px;
    background: #c6c6c6;
    top: 50%;
    transform: translateY(-50%);
  }

  .trouble-container:after {
    content: '';
    left: 40px;
    bottom: 25px;
    position: absolute;
    width: 18%;
    height: 1.5px;
    background: #c6c6c6;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
