import { usePermissionStore } from '@/store/permissionStore';
import ability from '@/libs/acl/ability';
import { initialAbility } from '@/libs/acl/config';
import permissionModel from '@/models/permission/permission';
import apiService from '@/services/api';

class PermissionService {
  private get permissionStore() {
    return usePermissionStore();
  }

  async fetchPermissions(): Promise<Permission.Response.Key[]> {
    const { fetchPermissionKeyInfo } = permissionModel();
    try {
      const resp = await apiService.apiRequest(fetchPermissionKeyInfo);
      return resp.data.key_lists as Permission.Response.Key[];
    } catch (e) {
      // Handle or rethrow the error as needed
      console.error('Error fetching permissions:', e);
      throw e;
    }
  }

  async fetchAndSetPermissions() {
    try {
      const permissionKeys = await this.fetchPermissions();

      // Collect updates in a temporary object
      const updatedPermissions: Record<string, boolean> = {};
      const abilities: Permission.Ability[] = [];

      permissionKeys.forEach((key: Permission.Response.Key) => {
        updatedPermissions[key.permission_key] = key.status;

        if (key.status) {
          abilities.push({ action: 'portal-cdp', subject: key.permission_key });
        }
      });

      // Update the Info state in a single operation
      this.permissionStore.setPermission(updatedPermissions);
      ability.update([...abilities]);
    } catch (e) {
      console.error('Error updating permissions:', e);
      // Set initial from config
      this.permissionStore.resetPermissions();
      ability.update(initialAbility);
    }
  }
}

const permissionService = new PermissionService();
export default permissionService;
