<template>
  <div class="d-flex flex-column align-items-center justify-content-center main-expire text-center">
    <div class="check-email-expire-container">
      <div class="pic-check-email-expire-container">
        <img src="@assets/images/modules/authentication/Choco-CDP-URL-Link-Expire.png" class="w-100" />
      </div>
      <div class="header-check-email-expire-container font-f2">
        <span>{{ t('authentication.forget_password.expire_email.title') }}</span>
      </div>
      <div class="description-check-email-expire-container mt-2">
        <span>{{ t('authentication.forget_password.expire_email.description_first') }}</span>
      </div>
      <div class="footer-description-check-email-expire-container">
        <span>{{ t('authentication.forget_password.expire_email.re_click') }}</span>
        <router-link class="link-text-email font-f2" to="/forget">{{
          t('authentication.forget_password.expire_email.link_forgot_password')
        }}</router-link>
      </div>
    </div>
    <div class="footer-check-email-container"><img src="@assets/images/modules/authentication/footer-forgot-password.png" /></div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@assets/styles/modules/authentication/expire-reset-password.scss';
</style>
