<template>
  <div class="native-custom-selector">
    <div :class="{ 'back-drop-container': isVisible }" @click="isVisible = !isVisible" />
    <section class="dropdown-wrapper">
      <div v-if="props.disabled" class="selected-item">
        <span>{{ dropDownForm.selectedOption.title }}</span>
      </div>
      <div v-else @click="onOpenDropdown" class="selected-item">
        <div class="d-flex align-items-center">
          <component :is="dropDownForm.selectedOption.icon" class="me-2" fill="#606A6C" />
          <span>{{ dropDownForm.selectedOption.title ? dropDownForm.selectedOption.title : placeholder }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span v-if="dropDownForm.selectedOption.title" class="mx-2" @click="clearOptionSelected">X</span>
          <DownWardIcon class="drop-down-icon" :class="{ dropdown: isVisible }" />
        </div>
      </div>
      <div v-if="isVisible" class="dropdown-popover">
        <div v-if="props.searchable" class="form-group">
          <div class="icon d-flex align-items-center justify-content-center">
            <SearchIcon class="icon" />
          </div>
          <input v-model="dropDownForm.searchQuery" class="searching-input" :placeholder="t('component.native_custom_selector.placeholder')" />
          <span v-if="filteredOption.length <= 0" class="text-no-data">{{ t('component.native_custom_selector.no_data') }}</span>
        </div>
        <div class="options">
          <div>
            <div v-for="(item, index) in filteredOption" :key="index" @click="onChangeSelectedOption(item)">
              <component :is="item.icon" class="me-2 mb-1" fill="#606A6C" />
              <span>{{ item[item.key] }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import DownWardIcon from '@/assets/icons/base/v-select/DownWardIcon.vue';
import SearchIcon from '@/assets/icons/base/v-select/SearchIcon.vue';

interface Props {
  label: string;
  modelValue: Record<string, any> | null;
  options?: any;
  disabled?: boolean;
  searchable?: boolean;
  clearOption?: boolean;
  placeholder?: string;
}

interface DropDownForm {
  searchQuery: string;
  selectedOption: Record<string, any>;
}

const props = withDefaults(defineProps<Props>(), {
  label: '',
  modelValue: null,
  disabled: false,
  searchable: false,
  clearOption: false,
});

const emit = defineEmits(['check-dropdown-expanded', 'on-change', 'update:modelValue']);

const { t } = useI18n();

// const { isVisible, filteredOption, dropDownForm, onChangeSelectedOption, clearOptionSelected, onOpenDropdown } = useNativeCustomSelector(props);

const isVisible = ref(false);

const dropDownForm: DropDownForm = reactive({
  searchQuery: '',
  selectedOption: {
    title: '',
  },
});

const onOpenDropdown = () => {
  isVisible.value = !isVisible.value;
  emit('check-dropdown-expanded', isVisible.value);
};

const onChangeSelectedOption = (option: Record<string, any>) => {
  emit('update:modelValue', option);
  dropDownForm.selectedOption = option;
  dropDownForm.selectedOption.title = option[option.key];
  isVisible.value = false;
  emit('check-dropdown-expanded', isVisible.value);
  emit('on-change', option);
};

const clearOptionSelected = () => {
  emit('update:modelValue', null);
  emit('on-change', null);
  dropDownForm.selectedOption = {};
  isVisible.value = false;
};

const filteredOption = computed(() => {
  const query = dropDownForm.searchQuery.toLowerCase();
  if (dropDownForm.searchQuery === '') {
    return mappingKey(props.options);
  }
  return mappingKey(props.options).filter((option: Record<string, any>) => {
    return option[option.key].toLowerCase().includes(query);
  });
});

const mappingKey = (payload: any) => {
  const tempArr: Array<any> = [];
  payload.forEach((o: any) => {
    return tempArr.push({
      key: props.label,
      ...o,
    });
  });
  return tempArr;
};

onMounted(() => {
  if (props.modelValue) {
    dropDownForm.selectedOption = props.modelValue;
    dropDownForm.selectedOption.title = props.modelValue[props.label];
  }
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      dropDownForm.selectedOption = newValue;
      dropDownForm.selectedOption.title = newValue[props.label];
    }
  },
  { deep: true },
);

watch(
  () => props.clearOption,
  () => {
    clearOptionSelected();
  },
);
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/custom-vue-select.scss';
</style>

