<template>
  <div class="vertical-nav-wrp">
    <div class="navigation-area" @mouseenter="sideMenuHover(true)" @mouseleave="sideMenuHover(false)">
      <div class="navigation-header">
        <slot name="header">
          <div class="navigation-header-content">
            <img :src="brand?.brandData?.logo_url || ChocoLogo" class="logo-image" />
            <span class="text-header">{{ brand?.brandData?.brand_name }}</span>
          </div>
        </slot>
        <div class="navigation-toggle-arrow inner" @click="onClickOpenMenu">
          <button class="navigation-switch">
            <div class="icon-arrow">
              <arrow-down-icon fill="#07f" />
            </div>
          </button>
        </div>
      </div>
      <vertical-nav-menu-items :items="navMenuItems" />
    </div>
    <div class="navigation-toggle-arrow outer" @click="onClickOpenMenu">
      <button class="navigation-switch">
        <div class="icon-arrow">
          <arrow-down-icon fill="#07f" />
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBrandStore } from '@/store/brandStore';
import { onMounted, ref, Ref } from 'vue';

import navMenuItems from '../../../../navigation/vertical';
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import ArrowDownIcon from '@/assets/icons/base/navbarIcon/ArrowDownIcon.vue';
import ChocoLogo from '@/assets/icons/base/chococrm.png';

import { AUTO_HIDE_MENU_WIDTH } from '@/constants/layouts/layout-vertical';

const brand = useBrandStore().currentBrand;

const bodyRef: Ref<HTMLElement | null> = ref(null);
const sideMenuWrapperRef: Ref<HTMLElement | null> = ref(null);
const contentWrapperRef: Ref<HTMLElement | null> = ref(null);

function onClickOpenMenu() {
  if (sideMenuWrapperRef.value && contentWrapperRef.value && bodyRef.value) {
    // click to show menu
    if (bodyRef.value.classList.contains('sidebar-hide')) {
      sideMenuWrapperRef.value.classList.replace('sidebar-hide', 'sidebar-show');
      sideMenuWrapperRef.value.classList.add('sidebar-pinned');
      // Body
      bodyRef.value.classList.replace('sidebar-hide', 'sidebar-show');
      bodyRef.value.classList.add('sidebar-pinned');
    }
    // click to pin menu when hover
    else if (bodyRef.value.classList.contains('sidebar-show') && !bodyRef.value.classList.contains('sidebar-pinned')) {
      sideMenuWrapperRef.value.classList.replace('sidebar-hide', 'sidebar-show');
      bodyRef.value.classList.replace('sidebar-hide', 'sidebar-show');

      if (!bodyRef.value.classList.contains('sidebar-pinned')) {
        bodyRef.value.classList.add('sidebar-pinned');
        sideMenuWrapperRef.value.classList.add('sidebar-pinned');
      }
    }
    // click to hide menu
    else {
      sideMenuWrapperRef.value.classList.replace('sidebar-show', 'sidebar-hide');
      bodyRef.value.classList.replace('sidebar-show', 'sidebar-hide');
      if (bodyRef.value.classList.contains('sidebar-pinned')) {
        bodyRef.value.classList.remove('sidebar-pinned');
        sideMenuWrapperRef.value.classList.remove('sidebar-pinned');
      }
    }
  }
}

// // Detect event window resize
// function resizeSideMenu() {
//   if (sideMenuWrapperRef.value && contentWrapperRef.value && bodyRef.value) {
//     // breakpoint -> AUTO_HIDE_MENU_WIDTH = 1440px
//     if (width.value < AUTO_HIDE_MENU_WIDTH) {
//       if (bodyRef.value.classList.contains('sidebar-pinned')) {
//         sideMenuWrapperRef.value.classList.remove('sidebar-pinned');
//         bodyRef.value.classList.remove('sidebar-pinned');
//       }
//       if (!bodyRef.value.classList.contains('sidebar-hide')) {
//         sideMenuWrapperRef.value.classList.add('sidebar-hide');
//         bodyRef.value.classList.add('sidebar-hide');
//       }
//     }
//   }
// }

// Hover trigger
function sideMenuHover(isOpen: boolean) {
  if (sideMenuWrapperRef.value && contentWrapperRef.value && bodyRef.value) {
    if (!sideMenuWrapperRef.value.classList.contains('sidebar-pinned')) {
      if (isOpen) {
        if (bodyRef.value.classList.contains('sidebar-hide')) {
          sideMenuWrapperRef.value.classList.replace('sidebar-hide', 'sidebar-show');
          bodyRef.value.classList.replace('sidebar-hide', 'sidebar-show');
        }
      } else {
        sideMenuWrapperRef.value.classList.replace('sidebar-show', 'sidebar-hide');
        bodyRef.value.classList.replace('sidebar-show', 'sidebar-hide');
      }
    }
  }
}

// onBeforeMount(() => {
//   if (authService.getLocalBrandData()! && JSON.parse(authService.getLocalBrandData()!).length > 0) {
//     return (brand.value = JSON.parse(authService.getLocalBrandData()!));
//   }
//   return (brand.value = [
//     {
//       brandData: {
//         brand_name: '',
//         logo_url: '',
//       },
//     },
//   ]);
// });

onMounted(() => {
  // HTML Elements
  bodyRef.value = document.getElementsByTagName('body')[0] as HTMLElement;
  sideMenuWrapperRef.value = document.getElementsByClassName('vertical-nav-wrp')[0] as HTMLElement;
  contentWrapperRef.value = document.getElementsByClassName('main-content')[0] as HTMLElement;
  if (sideMenuWrapperRef.value) {
    sideMenuWrapperRef.value.classList.add('sidebar-hide');
    bodyRef.value.classList.add('sidebar-hide');
  }
  // // Detect event window resize
  // // listen window resize
  // useEventListener('resize', resizeSideMenu, { passive: true });
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/vertical-nav-bar.scss';
</style>
