<template>
  <div class="validation-modal">
    <transition name="validation-modal-animation">
      <div v-show="validationModalConfig.isOpen" class="validation-modal-container">
        <div class="validation-modal-backdrop-container" @click="closeValidationModal(validationModalConfig.onClose)"></div>
        <div class="content-container">
          <transition name="validation-modal-animation-inner">
            <div class="content-container">
              <div v-if="validationModalConfig.type == 'warning'" class="validation-modal-inner">
                <!-- Modal header -->
                <div v-if="validationModalConfig.type == 'warning'" class="d-flex justify-content-center">
                  <warning-icon fill="#fff" stroke="#B7B7B7" />
                </div>
                <div :class="validationModalConfig.type == 'warning' ? 'warning-title' : null">
                  {{ validationModalConfig.title }}
                </div>
                <div :class="validationModalConfig.type == 'warning' ? 'warning-description' : null">
                  {{ validationModalConfig.description }}
                </div>
                <div class="modal-footer justify-content-center">
                  <button v-if="!validationModalConfig.cancelBtn_Disabled" type="button" class="btn" @click="validationModalConfig.onCancel">
                    {{ t('component.modal.close') }}
                  </button>
                  <button
                    v-if="!validationModalConfig.confirmBtn_Disabled"
                    type="button"
                    class="btn btn-primary"
                    @click="validationModalConfig.onConfirm"
                  >
                    {{ validationModalConfig.confirmLabel || t('component.modal.confirm') }}
                  </button>
                </div>
              </div>
              <div v-if="validationModalConfig.type == 'warning-title-danger'" class="validation-modal-inner">
                <!-- Modal header -->
                <div v-if="validationModalConfig.type == 'warning-title-danger'" class="d-flex justify-content-center">
                  <warning-icon fill="#fff" stroke="#B7B7B7" />
                </div>
                <div :class="validationModalConfig.type == 'warning-title-danger' ? 'warning-title' : null" style="color: red">
                  {{ validationModalConfig.title }}
                </div>
                <div :class="validationModalConfig.type == 'warning-title-danger' ? 'warning-description' : null">
                  {{ validationModalConfig.description }}
                </div>
                <div class="modal-footer justify-content-center">
                  <button v-if="!validationModalConfig.cancelBtn_Disabled" type="button" class="btn" @click="validationModalConfig.onCancel">
                    {{ t('component.modal.close') }}
                  </button>
                  <button
                    v-if="!validationModalConfig.confirmBtn_Disabled"
                    type="button"
                    class="btn btn-primary"
                    @click="validationModalConfig.onConfirm"
                  >
                    {{ validationModalConfig.confirmLabel || t('component.modal.confirm') }}
                  </button>
                </div>
              </div>
              <div v-if="validationModalConfig.type == 'success'" class="validation-modal-inner">
                <!-- Modal header -->
                <div v-if="validationModalConfig.type == 'success'" class="d-flex justify-content-center">
                  <check-icon fill="#4CAF50" />
                </div>
                <div :class="validationModalConfig.type == 'success' ? 'success-title' : null">
                  {{ validationModalConfig.title }}
                </div>
                <div :class="validationModalConfig.type == 'success' ? 'warning-description' : null">
                  {{ validationModalConfig.description }}
                </div>
                <div class="modal-footer justify-content-center">
                  <button type="button" class="btn" @click="validationModalConfig.onConfirm" v-if="validationModalConfig.cancelBtn_Disabled">
                    {{ validationModalConfig.confirmLabel || t('component.modal.close') }}
                  </button>
                </div>
              </div>
              <div v-if="validationModalConfig.type == 'error'" class="validation-modal-inner">
                <!-- Modal header -->
                <div v-if="validationModalConfig.type == 'error'" class="d-flex justify-content-center">
                  <error-icon fill="#FF0000" />
                </div>
                <div :class="validationModalConfig.type == 'error' ? 'warning-title' : null">
                  {{ validationModalConfig.title }}
                </div>
                <div :class="validationModalConfig.type == 'error' ? 'warning-description' : null">
                  {{ validationModalConfig.description }}
                </div>
                <div class="modal-footer justify-content-center">
                  <button type="button" class="btn" @click="validationModalConfig.onConfirm">
                    {{ validationModalConfig.confirmLabel || t('component.modal.close') }}
                  </button>
                </div>
              </div>
              <div
                v-if="validationModalConfig.type == 'danger'"
                class="validation-modal-inner"
                :class="{ 'danger-modal': validationModalConfig.type == 'danger' }"
              >
                <!-- Modal header -->
                <div v-if="validationModalConfig.type == 'danger'" class="d-flex justify-content-center">
                  <error-icon fill="#FF0000" />
                </div>
                <div :class="validationModalConfig.type == 'danger' ? 'danger-title' : null">
                  {{ validationModalConfig.title }}
                </div>
                <div class="font-f3" :class="validationModalConfig.type == 'danger' ? 'danger-description' : null">
                  {{ validationModalConfig.description }}
                </div>
                <div class="modal-footer justify-content-center" :class="{ 'danger-footer': validationModalConfig.type == 'danger' }">
                  <button type="button" class="btn" @click="validationModalConfig.onCancel">
                    {{ t('component.modal.close') }}
                  </button>
                  <button type="button" class="btn btn-primary" @click="validationModalConfig.onConfirm">
                    {{ validationModalConfig.confirmLabel || t('component.modal.confirm') }}
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import CheckIcon from '@/assets/icons/base/modal/CheckIcon.vue';
import WarningIcon from '@/assets/icons/base/modal/WarningIcon.vue';
import ErrorIcon from '@/assets/icons/base/modal/ErrorIcon.vue';

import { useModalStore } from '@/store/modalStore';
import { storeToRefs } from 'pinia';
import useValidationModal from './hooks/useValidationModal';

const { closeValidationModal } = useValidationModal();
const modalStore = useModalStore();
let { validationModalConfig } = storeToRefs(modalStore);
const { t } = useI18n();
</script>

<style style lang="scss" scoped>
@import '@/assets/styles/base/validation-modal.scss';
</style>
