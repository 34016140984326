export default {
  delete_any: '{any} is delete',
  start_campaign: 'Start Campaign',
  confirm_start_campaign: 'Would you like start this campaign ?',
  success_start_campaign: 'Campaign Started Successfully',
  file: 'File',
  example_only: 'This is an example only. Actual performance on each device may vary.',
  recommended_aspect_ratio: 'Recommended aspect ratio',
  call_to_action: 'Call to Action',
  banner: 'Banner',
  closing_card: 'Closing Card',
  change_type: 'Change Type',
  info: 'Info',
  all_use_credit: 'Total credit used',
  person: ' People',
  all_selected: 'All Selected',
  add_an_unconsented: 'Add an Unconsented',
  confirm: 'Confirm',
  selecr_type_text: 'Please Selecr Type',
  campaign: {
    line_oa: {
      create: {
        uploading: 'Uploading',
        error: 'Error',
        filenameExists: 'This filename already exists, please rename the file.',
        fileOverSize: 'File size exceeded.',
        wrongFileType: 'Wrong file type.',
        uploadImage: 'Upload Image',
        uploadVideo: 'Upload Video',
        uploadVoice: 'Upload Voice',
        actionToolTip: 'The action label is shown on unsupported devices and read by text-to-speech services.',
      },
    },
    sms: {},
  },
  resume_campaign: {
    campaign_list_paused: {
      button_back: 'Back',
      tab: {
        all_list_paused_tab: 'All Campaign',
        commu_list_paused_tab: 'Communication',
        mka_list_paused_tab: 'Marketing Automation',
      },
      box_detail: {
        channel: 'Channel',
        detail_channel: {
          all: 'All',
          communication: 'Communication',
          mka: 'Marketing Automation',
        },
        campaignUsing: 'Campaign in use',
        requiredCredit: 'Credit to be used',
      },
      next: 'Next',
      confirm_all_button: 'Start using all',
      confirm_button: 'Start using',
      confirm_count_button: 'list',
    },

    check_export_conditions: {
      title: 'Config resend condition',
      box_detail: {
        count_campaign_select: 'Campaign selected',
        requiredCredit: 'Credit to be used',
      },
      box_conditions: {
        title_time: 'Please select schedule.',
        detail_time: {
          time: 'Schedule a later',
          now: 'Send Now',
        },

        title_return: 'Please select resend mode.',
        detail_return: {
          send: { start: '', middle: '', end: 'Resend to pending recipients.' },
          unsend: { start: '', middle: '', end: "Don't resend to previous pending recipients." },
        },

        title_round: 'Please select resend to previous mode.',
        detail_round: {
          all_round: 'Resend to all previous.',
          latest_round: 'Resend to last previous round.',
          custom_round: 'Specify previous round to resend.',
        },
        manage_campaigns: 'Select',
      },
      confirm_button: 'Resend',
    },
  },
  //modal pass
  modal_pass_confirm: 'Please enter your password again to confirm',
  modal_pass_invalid: 'Invalid password',
  // Modal Remove Un
  modal_removeUn_clearData: 'Clear customer data',
  modal_removeUn_clearUnapproved: 'Clear data of unapproved customers',
  modal_removeUn_personalData: 'Personal data cleared',
  modal_removeUn_confirmSend: 'Confirm sending message?',
  // Modal Send Un
  modal_sendUn_confirmSend: 'Do you want to send a message',
  modal_sendUn_toUnapproved: 'to unapproved customers',
  modal_sendUn_noConsent: 'who have not consented to personal data',
  modal_sendUn_confirmSendMsg: 'Confirm sending message?',
  modal_sendUn_yes: 'Yes, send to both approved and unapproved customers',
  modal_sendUn_no: 'No, send only to approved customers',
  //resume_campaign
  No: 'No.',
  All: 'All',
  communication: 'Communication',
  campaign_list_paused: 'Campaign list paused',
  mka: 'Marketing Automation',
  bi: 'Business Intelligent',
  ins: 'Customer Insight',
  dc: 'Data Connector',
  di: 'Data Ingestion',
  seg: 'Segment',
  perm: 'Permission',
  show: 'Show',
  list: 'List',
  CampaignName: 'Campaign Name',
  function: 'Function',
  module: 'Module',
  channel: 'Channel',
  schedule_mode: 'Schedule',
  count_dispatchable: 'Total Recipients',
  count_success: 'Success',
  count_pendingcredit: 'Pending',
  est_credit_use: 'Credit to be used',
  updated: 'Last Updated',
  status: 'Status',
  CampaignNameExpand: 'Template Name used in used by node',
  send_now: 'Send Now',
  schedule_later: 'Schedule a later',
  repeat: 'Repeat',
  pause: 'Pause',
  draft: 'Draft',
  complete: 'Complete',
  cancel: 'Cancel',
  dispath: 'Sending',
  scheduled: 'Scheduled', //Scheduled
  preparing: 'Preparing',
  resendInformation: 'Resend information',
  totalResending: 'Total Resending',
  round: 'Round',
  day: 'Day',
  month: 'Month',
  year: 'Year',
  outstandingAmount: 'Outstanding amount',
  apply: 'apply',
  manageCampaignsInScheduledRepeat: 'Manage campaigns in scheduled repeat.',
  time: 'time',
  minute: '',
  sentEvery: 'Sent Every',

  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  End: 'End',
  completed_successfully: 'Completed successfully',
  error_occurred: 'An error occurred.',
  check_export_conditions: 'Credit to be used',
  confirm_YesOrNo: 'Confirm, Yes or No?',
  tooltip_column_accum_recp:
    'Count cumulatively, single-round Communications campaigns are counted first round only. Repeat Communications campaigns are counted cumulatively from first round  to the last run round. Marketing Automation campaigns are counted cumulatively according to the current number of recipients that are currently in the flow. (Count only recipients who is sent but received insufficient credit)',
  tooltip_column_accum_recp_pendingcredit: 'Sent but credit not enough',
  tooltip_column_est_credit_use: 'Estimated credit required',
  tooltip_datetimeMode: 'กำหนดเวลาการเริ่มทำงานอีกครั้งของแคมเปญ',
  tooltip_resendMode: 'Choose whether to resend messages to recipients who were about to send sent but did not have enough credit.',
  tooltip_resend: 'If selected, a message will be sent to pending recipient.',
  tooltip_DoNot_resend: "If selected, a message won't be sent to all previous pending recipient.",
  tooltip_resendPreviousMode:
    'Config resend to previous mode for Communication campaigns. If the campaign is Marketing Automation, will always use Resend to all previous.',
  tooltip_resend_allPrevious: 'Resend the past rounds that has insufficient credits.',
  tooltip_resend_lastPreviousRound: 'Resend the last previous round.',
  tooltip_resend_lastPreviousRoundRepeat:
    'ต้องการส่งย้อนไปยังผู้รอรับคงค้างเฉพาะรอบล่าสุด กรณีมีมากกว่า 1 รอบการส่งค้าง ตัวอย่างเช่น มีการตั้งส่งทุกวันที่จันทร์ รอบคงค้างเนื่องจากเครดิตหมด มีจำนวน 3 จันทร์ ต้องการส่งย้อนหลังไปยังผู้รับคงค้างเฉพาะผู้ที่ควรจะได้รับรอบวันจันทร์รอบล่าสุดที่มีการคงค้างเท่านั้น',
  tooltip_resend_specifyPrevious: 'Only for Communication campaigns with schedule type repeat.',
  sunday: 'Sunday',
  expand: 'expand',
  dayMonthYear: 'day/month/year',
  language: 'en-us',
  save_wait: 'Recording, please wait a moment...',
  successful: 'Successful',
  //Limitation
  Limit_buttonEdit: 'Edit',
  Limit_buttonSave: 'Save',
  Limit_amountLimit: 'Message Sending Limits​',
  Limit_amountLimit_detail:
    'Limit the number of messages a user can send to a single customer per day, month, and year to enhance system security and user account safety.',
  Limit_step: 'step',
  Limit_stepOne_amountDetail: 'Please set the maximum number of messages sent to each customer.',
  Limit_messagesPerDay: 'Limit messages per day',
  Limit_messagesPerMonth: 'Limit messages per month',
  Limit_messagesPerYear: 'Limit messages per year',
  Limit_noMoreThan: 'Limited to no more than',
  Limit_Unlimited_messagesPerDay: 'Unlimited messages sent per day',
  Limit_Unlimited_messagesPerMonth: 'Unlimited messages sent per month',
  Limit_Unlimited_messagesPerYear: 'Unlimited messages sent per year',
  Limit_Unlimited_exportPerDay: 'No daily limit',
  Limit_Unlimited_exportPerMonth: 'No monthly limit',
  Limit_Unlimited_exportPerYear: 'No yearly limit',
  Limit_messages: 'messages',
  Limit_stepTwo_detail: 'Sending Limit Setting',
  Limit_disallow_exceedLimit:
    'Block subsequent sends if the message quota is exceeded or if the send attempt falls outside of the designated message sending limit.', //Export prohibited If the number of sent messages exceeds the limit
  Limit_nextRound_exceedLimit: 'Queue the message for the next available sending slot if the current quota is exceeded.',

  Limit_timeLimit: 'Message Time Limits​',
  Limit_timeLimit_detail: 'Set a schedule for sending messages to customers to enhance system security and protect user accounts.',
  Limit_stepOne_timeDetail: 'Please select a method to limit the export time.',
  Limit_weekly: 'Weekly',
  Limit_dateAndTime: 'Message Scheduling',
  Limit_LimitedDays: 'Limited days',
  Limit_LimitedExportTime: 'Limited export time',
  Limit_between: 'Between',
  Limit_to: 'to',
  Limit_noTimeLimit: 'No time limit',

  Limit_ResultOfMessage: 'Result of message restriction',
  Limit_sendingOfMessages_by: 'Restrict sending of messages by',
  Limit_cusReceive: 'Customers will receive',
  Limit_notMoreThan: 'not more than',
  Limit_day: 'day',
  Limit_month: 'month',
  Limit_year: 'year',

  Limit_tooltipButtonAdd: 'Add',
  Limit_tooltipButtonRemove: 'Remove',
  Limit_view_calendar: 'Day limit results and message sending time',

  Limit_ConfirmQuantityLimit: 'Confirm quantity limit',
  Limit_DateAndTimeOfExport: 'Date and time of export.',
  Limit_Confirm_yesOrNo: 'Confirm, yes or no?',
  can_sent: 'Enable',
  not_sent: 'Disable',
  all_channel: 'All Channel',
  dashboard: 'Dashboard​',
  calendar_view: 'Calendar',
  all: 'All',
  reset: 'Reset',
  export_pdf: 'Export PDF',
  export_ex: 'Export',
  schedule: 'Schedule',
  sending: 'Sending',
  completed: 'Completed',
  campaign_text: 'Campaign',
  data_info: 'Detail',
  information: 'Information',
  chart_name: 'Chart Name',
  description: 'Description',
  total_status: 'Total Status',
  date_by: 'Last Update',
  used_credit: 'Used Credit',
  credit_balance: 'Credit Balance',
  credit: 'Credit',
  open: 'Open',
  grid_show: 'Grid View​',
  calendar_show: 'Calendar Adjustment/ Calendar View​',
  campaign_name: 'Campaign Name',
  last_update: 'Last Update',
  update_by: 'Update By',
  note: 'Note',
  add_note: 'Add Note',
  add_description: 'Add Description',
  date: 'Date',
  create: 'Create',
  template: 'Template',
  sender: 'Sender​',
  select_sender: 'Select Sender​',
  select_customer: 'Select Customer​',
  setting_and_schedule: 'Setting and Schedule​',
  preview_and_send: 'Preview and Send​',
  balanced_credit: 'Balanced Credit​',
  words_count: 'Words Count​',
  mobile_messaging: 'Mobile Messaging​',
  saved: 'Saved​',
  save_as: 'Save As​',
  create_campaign: 'Create Campaign',
  edit_campaign: 'Edit Campaign',
  reload: 'Reload',
  today: 'Today',
  message: 'Message',
  template_tooltip: 'Template',
  personalize_tooltip: 'Personalize',
  coupon_tooltip: 'Coupon',
  emoji_tooltip: 'Emoji',
  tracking_link_tooltip: 'Tracking Link',
  select_template: 'Select Template',
  popular: 'Popular',
  promotion: 'Promotion',
  seasonal: 'Seasonal ',
  awareness: 'Awareness',
  win_back: 'Win-back',
  engagement: 'Engagement ',
  notification: 'Notification',
  other: 'Other',
  news: 'News',
  sales_and_promotion: 'Sales and Promotion​',
  see_more: 'See More​',
  use: 'Apply',
  standard: 'Standard',
  my_template: 'My Template',
  template_name: 'Template Name',
  category: 'Category ',
  preview: 'Preview​',
  personalize: 'Personalize',
  edit_personalize: 'Edit Personalize',
  column: 'Column',
  select: 'Select',
  add: 'Apply',
  replace_no_data: 'Replace No Data',
  coupon_code: 'Coupon Code',
  edit_coupon_code: 'Edit Coupon Code',
  reference: 'Reference',
  coupon_name: 'Coupon Name',
  value_reference: 'Value Reference',
  total: 'Total',
  coupon_code_link: 'Coupon Code Link',
  expiration_datetime: 'Expiration DateTime',
  detail: 'Detail',
  coupon: 'Coupon',
  all_folder: 'All Folder',
  folder: 'Folder',
  segment: 'Segment',
  schedule_a_later: 'Schedule a later',
  recipients: 'Recipients',
  recipient: 'Recipient',
  customer: 'Customer',
  send: 'Send',
  verify: 'Verify',
  please_fill_complete: 'Please fill in complete information.',
  create_message: 'Create Message',
  please_fill_text: 'Please fill in complete text.',
  please_fill_sender: 'Please fill in complete sender.',
  please_fill_customer: 'Please fill in complete customer.',
  please_fill_campaign: 'Please fill in complete campaign.',
  count_one: 'Total {count}',
  start_sent: 'Start',
  end_sent: 'End',
  summary: 'Summary',
  publish: 'Publish',
  use_credit: 'Used Credit',
  balance: 'Balance',
  create_line_campaign: 'Create Line Campaign',
  line_campaign_information: 'Line Campaign Information',
  characters: 'Characters​',
  message_tooltip: 'Message ',
  image_tooltip: 'Image ',
  video_tooltip: 'Video ',
  rich_message_tooltip: 'Rich Message ',
  rich_video_message_tooltip: 'Rich Video Message',
  card_message_tooltip: 'Card Message',
  voice_message_tooltip: 'Voice Message',
  multi_message_tooltip: 'Multi Message',
  upload_image: 'Upload Image',
  file_formats: 'File Format',
  file_size: 'Duration',
  maximum: 'Maximum',
  select_message_type: 'Select Message Type',
  coming_soon: 'Coming Soon',
  create_template: 'Create Template',
  edit_template: 'Edit Template',
  save_template: 'Save Template',
  subject: 'Subject',
  to: 'To',
  create_line_template: 'Create Line Template',
  line_template_information: 'Line Template Information',
  change_template: 'Change Template​',
  change_layout: 'Change Layout',
  action_label: 'Action Label',
  url_link: 'URL Link',
  upload_file: 'Upload File',
  change: 'Change',
  create_new: 'Create New',
  detail_list_tooltip: 'Detail List',
  delete_tooltip: 'Delete',
  duplicate_tooltip: 'Duplicate',
  edit_tooltip: 'Edit',
  export_tooltip: 'Export',
  design: 'Design',
  content: 'Content',
  image: 'Image',
  text: 'Text',
  video: 'Video',
  social: 'Social',
  barcode_qr: 'Barcode/QR',
  layout: 'Layout',
  visit_url: 'Visit URL',
  edit: 'Edit',
  remove: 'Remove',
  padding: 'Padding',
  top: 'Top',
  bottom: 'Bottom',
  left: 'Left',
  right: 'Right',
  font_plus: 'Font Plus',
  font_minus: 'Font Minus',
  font_background: 'Font Background',
  font_bold: 'Font Bold',
  font_italic: 'Font Italic',
  font_underline: 'Font Underline',
  align_left: 'Align Left',
  align_center: 'Align Center',
  align_right: 'Align Right',
  font_style: 'Font Style',
  font_size: 'Font Size',
  font_case: 'Font Case',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  center: 'Center',
  bullet: 'Bullet',
  ordered: 'Ordered',
  text_vertical_align: 'Text Vertical Align',
  text_color: 'Text Color',
  done: 'Done',
  edit_image: 'Edit Image',
  size: 'Size',
  width: 'Width',
  height: 'Height',
  alignment: 'Alignment',
  radius: 'Radius',
  border: 'Border',
  alt_text: 'Alt Text',
  link: 'Link',
  open_link: 'Open Link',
  open_link_in_new__window: 'Open Link In New Window',
  spacing: 'Spacing',
  edit_social: 'Edit Social',
  choose_social: 'Choose Social',
  p_choose_social: 'Please, Choose Social',
  p_choose_coupon: 'Please, Choose Coupon Code.',
  add_social: 'Add Social',
  add_coupon: 'Add Coupon',
  icon_text: 'Icon&Text',
  icon_only: 'Icon Only',
  text_only: 'Text Only',
  setting: 'Setting',
  edit_barcode_qr: 'Edit Barcode/QR',
  edit_layout: 'Edit Layout',
  edit_style: 'Edit Style',
  edit_video: 'Edit Video',
  clear_format: 'Clear format',
  replace: 'Replace',
  show_display: 'Show Display',
  text_position: 'Text Position',
  icon_styles: 'Icon Styles',
  scale: 'Scale',
  margin: 'Margin',
  not_found: 'Not Found',
  add_credit_success: 'Add Credit Success',
  layout_type: 'Layout Type',
  content_width: 'Content Width',
  full_width: 'Full Width',
  background: 'Background',
  body_color: 'Body Color',
  select_background: 'Select Background',
  select_image: 'Select Image',
  or: 'Or',
  coupon_type: 'Coupon Type',
  barcode_styles: 'Barcode Styles',
  barcode_color: 'Barcode Color',
  background_color: 'Background Color',
  border_radius: 'Border Radius',
  video_type: 'Video Type',
  video_link: 'Video Link',
  upload_video: 'Upload Video',
  upload_thumbnail: 'Upload Thumbnail',
  sender_name: 'Sender Name',
  email_address: 'E-mail Address',
  created_on: 'Created On',
  created_by: 'Created By',
  created_time: 'Created Time',
  line_provider_id: 'Line Provider Id',
  close: 'Close',
  amount: 'Amount',
  ex_link: 'Ex. www.chococdp.com',
  all_date: 'All Date',
  head_delete_note: 'Delete Note',
  confirm_delete_note: 'Confirm Delete Note ?',
  error: 'Error',
  cannot_download_report: 'Cannot Download Report',
  html_click_map: 'HTML Click Map',
  top_clicked_links: 'Top Clicked Links',
  top_5_engaged_contacts: 'Top 5 Engaged Contacts',
  fail: 'Fail',
  select_all_filter_date: '(Since Start Campaign To Today)',
  type_menu_recipient: {
    OPENED: 'Opened',
    CLICKED: 'Clicked',
    SENT: 'Sent',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
    BOUNCE_RATE: 'Bounce Rate',
    SPAM_REPORT: 'Spam Report',
    UNSUBSCRIBE: 'Unsubscribe',
    OUT_OF_CREDIT: 'Out Of Credit',
    LIMITATION: 'Limitation',
  },
  clicked: 'Clicked',
  lists: 'Lists',
  tracking_link: 'Tracking Link',
  edit_tracking_link: 'Edit Tracking Link',
  sent: 'Sent',
  opened: 'Opened',
  go_to_campaign_dashboard: 'Go to campaign dashboard.',
  preview_url_link: 'Preview URL Link',
  unique_customer: 'Unique Customer',
  first_name: 'First_name',
  last_name: 'Last_name',
  mobile_no: 'Mobile No',
  line_user_id: 'Line User Id',
  confirm_do_not_resend: 'Confirm Do Not Resend',
  next_schedule_date: 'Next Schedule Date',
  action: 'Action',
  select_brand_and_branch: 'Select Brand and Branch',
  mark_completed: 'Mark Completed',
  mark_uncompleted: 'Mark Uncompleted',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  none: 'None',
  Unconsent_PDPA_Modal: 'This brand does not enable the function to send messages to individuals without their consent',
  this_month: 'This Month',
  rv_title: 'Interactive Video',
  rv_show_button: 'Display',
  rv_hide_button: 'Hide',
  rv_show_button_tooltip: 'Display action button linking to URL',
  rv_hide_button_tooltip: 'Do not want to show action button after video ends',
  rv_detail: 'Action Button Details',
  rv_message_tag: 'Label',
  rv_file_recommend: 'Supported File format',
  rv_size_file: 'File Size',
  rv_not_over: 'Not Over',
  rv_learn_more: 'Learn more',
  rv_install_app: 'Install app',
  rv_purchase: 'Purchase',
  rv_pre_register: 'Preregister',
  rv_enter: 'Enter',
  rv_apply: 'Apply',
  rv_join_event: 'Join event',
  rv_vote: 'Vote',
  rv_search_for_store: 'Search for store',
  rv_contact_us: 'Contact us',
  rv_request_materials: 'Request materials',
  rv_view_more_detail: 'View more videos',
  rv_custom: 'Custom',
  all_channel_credit_tooltip: 'Available credits for communication campaigns only. This does not affect your dashboard.',
};
