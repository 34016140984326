export default {
  delete_any: 'ลบ {any} เรียบร้อย',
  start_campaign: 'เริ่ม Campaign',
  confirm_start_campaign: 'คุณต้องการเริ่ม Campaign นี้หรือไม่ ?',
  success_start_campaign: 'เริ่ม Campaign สำเร็จ',
  file: 'ไฟล์',
  example_only: 'ภาพที่แสดงเป็นเพียงตัวอย่าง ผลลัพธ์จริงอาจแตกต่างกันไปขึ้นอยู่กับอุปกรณ์',
  recommended_aspect_ratio: 'อัตราส่วนภาพที่แนะนำ',
  call_to_action: 'ปุ่มแอ็กชัน',
  banner: 'แบนเนอร์',
  closing_card: 'การ์ดปิดท้าย',
  change_type: 'เปลี่ยนประเภท',
  info: 'ข้อมูล',
  all_use_credit: 'เครติดที่ใช้ทั้งหมด',
  person: ' คน',
  all_selected: 'เลือกทั้งหมด',
  add_an_unconsented: 'เพิ่มรายชื่อผู้ใช้งานที่ไม่ยินยอมสิทธิ์',
  confirm: 'ยืนยัน',
  selecr_type_text: 'กรุณาเลือกประเภทข้อความ',
  campaign: {
    line_oa: {
      create: {
        uploading: 'กำลังอัพโหลด',
        error: 'เกิดข้อผิดพลาด',
        filenameExists: 'มีไฟล์ชื่อนี้อยู่แล้ว โปรดเปลี่ยนชื่อไฟล์',
        fileOverSize: 'ขนาดไฟล์ใหญ่เกินไป',
        wrongFileType: 'ไฟล์ผิดประเภท',
        uploadImage: 'อัพโหลดรูปภาพ',
        uploadVideo: 'อัพโหลดวิดีโอ',
        uploadVoice: 'อัพโหลดเสียง',
        actionToolTip: 'ป้ายแอคชั่นจะแสดงบนอุปกรณ์ที่ไม่รองรับและอ่านโดยการแปลงข้อความเป็นคำพูด',
      },
    },
    sms: {},
  },
  resume_campaign: {
    campaign_list_paused: {
      button_back: 'ย้อนกลับ',
      tab: {
        all_list_paused_tab: 'แคมเปญทั้งหมด',
        commu_list_paused_tab: 'การสื่อสาร',
        mka_list_paused_tab: 'การตลาดอัตโนมัติ',
      },
      box_detail: {
        channel: 'ช่องทาง',
        detail_channel: {
          all: 'ทั้งหมด',
          communication: 'การสื่อสาร',
          mka: 'การตลาดอัตโนมัติ',
        },
        campaignUsing: 'แคมเปญที่ใช้งานอยู่',
        requiredCredit: 'เครดิตที่ต้องใช้',
      },
      next: 'ถัดไป',
      confirm_all_button: 'เริ่มการใช้งานทั้งหมด',
      confirm_button: 'เริ่มการใช้งาน',
      confirm_count_button: 'รายการ',
    },

    check_export_conditions: {
      title: 'ตรวจสอบเงื่อนไขการส่งออก',
      box_detail: {
        count_campaign_select: ' จำนวนเเคมเปญที่เลือก',
        requiredCredit: 'เครดิตที่ต้องใช้',
      },
      box_conditions: {
        title_time: 'กรุณาเลือกเวลาส่งซ่อม ',
        detail_time: {
          time: 'กำหนดวัน และเวลา',
          now: 'ส่งทันที',
        },

        title_return: 'กรุณาเลือกโหมดการส่งย้อนหลัง',
        detail_return: {
          send: { start: '', middle: 'ส่งข้อความ', end: 'ไปยังผู้รอรับคงค้าง' },
          unsend: { start: '', middle: 'ไม่ส่งข้อความ', end: 'ไปยังผู้รอรับคงค้าง' },
        },

        title_round: 'กรุณาเลือกรอบการส่งย้อนหลัง',
        detail_round: {
          all_round: 'ส่งย้อนหลังทั้งหมด',
          latest_round: 'ส่งย้อนหลังเฉพาะรอบการส่งล่าสุด',
          custom_round: 'กำหนดรอบการส่งย้อนหลัง',
        },
        manage_campaigns: 'เลือก',
      },
      confirm_button: 'เริ่มการส่งซ้ำ',
    },
  },
  //modal pass
  modal_pass_confirm: 'กรุณากรอกรหัสผ่านอีกครั้งเพื่อยืนยัน',
  modal_pass_invalid: 'รหัสผ่านไม่ถูกต้อง',
  // Modal Remove Un
  modal_removeUn_clearData: 'ทำการเคลียร์ข้อมูลลูกค้า',
  modal_removeUn_clearUnapproved: 'เคลียร์ข้อมูลลูกค้าที่ยังไม่ได้ยินยอม',
  modal_removeUn_personalData: 'ข้อมูลส่วนบุคคลเรียบร้อย',
  modal_removeUn_confirmSend: 'ยืนยันการส่งข้อความ ใช่หรือไม่?',
  // Modal Send Un
  modal_sendUn_confirmSend: 'คุณต้องการส่งข้อความ',
  modal_sendUn_toUnapproved: 'ไปยังลูกค้าที่ยัง',
  modal_sendUn_noConsent: 'ไม่ได้ยินยอมข้อมูลส่วนบุคคล',
  modal_sendUn_confirmSendMsg: 'ยืนยันการส่งข้อความ ใช่หรือไม่?',
  modal_sendUn_yes: 'ใช่, ส่งทั้งคนที่ยอมรับ และไม่ยอมรับนโยบายความเป็นส่วนตัว',
  modal_sendUn_no: 'ไม่, ส่งเฉพาะคนที่ยอมรับนโยบายความเป็นส่วนตัว เท่านั้น',
  //resume_campaign
  No: 'ลำดับ',
  All: 'ทั้งหมด',
  communication: 'การสื่อสาร',
  campaign_list_paused: 'รายการแคมเปญหยุดชั่วคราว',
  mka: 'การตลาดอัตโนมัติ',
  bi: 'แผนภูมิ',
  ins: 'ข้อมูลลูกค้าเชิงลึก',
  dc: 'เชื่อมต่อข้อมูล (นำเข้า)',
  di: 'การนำเข้าข้อมูล',
  seg: 'จัดกลุ่มเป้าหมาย',
  perm: 'สิทธิการใช้งาน',
  show: 'โชว์',
  list: 'รายการ',
  CampaignName: 'ชื่อแคมเปญ',
  function: 'ฟังก์ชั่น',
  module: 'โมดูล',
  channel: 'ช่องทาง',
  schedule_mode: 'รูปแบบการส่ง',
  count_dispatchable: 'จำนวนผู้รับ',
  count_success: 'จำนวนผู้รับที่ส่งออกสำเร็จ',
  count_pendingcredit: 'จำนวนผู้รับที่รอการส่ง',
  est_credit_use: 'เครดิตที่ต้องใช้',
  updated: 'อัปเดตล่าสุด',
  status: 'สถานะ',
  CampaignNameExpand: 'ชื่อเทมเพลตที่ใช้ใน node',
  send_now: 'ส่งทันที',
  schedule_later: 'กำหนดเวลาในภายหลัง',
  repeat: 'ส่งซ้ำ',
  pause: 'หยุดชั่วคราว',
  draft: 'แบบร่าง',
  complete: 'สำเร็จ',
  cancel: 'ยกเลิก',
  dispath: 'กำลังส่ง',
  scheduled: 'รอดำเนินการ', //Scheduled
  preparing: 'เตรียมข้อมูล',
  resendInformation: 'ข้อมูลการส่งซ้ำ',
  totalResending: 'จำนวนการส่งซ้ำคงเหลือ',
  round: 'รอบที่',
  day: 'วัน',
  month: 'เดือน',
  year: 'ปี',
  outstandingAmount: 'จำนวนคงค้าง',
  apply: 'นำไปใช้',
  manageCampaignsInScheduledRepeat: 'จัดการแคมเปญในรูปแบบบการส่งซ้ำ',
  time: 'เวลา',
  minute: 'น.',
  sentEvery: 'ส่งทุก',

  monday: 'วันจันทร์',
  tuesday: 'วันอังคาร',
  wednesday: 'วันพุธ',
  thursday: 'วันพฤหัสบดี',
  friday: 'วันศุกร์',
  saturday: 'วันเสาร์',
  End: 'สิ้นสุด',
  completed_successfully: 'ทำรายการสำเร็จ',
  error_occurred: 'เกิดข้อผิดพลาด',
  check_export_conditions: 'ยืนยันตรวจสอบเงื่อนไขการส่งออก',
  confirm_YesOrNo: 'ต้องการยืนยันใช่หรือไม่?',
  tooltip_column_accum_recp:
    'นับแบบสะสม, แคมเปญการสื่อสารแบบส่งครั้งเดียวจะมีจำนวนตามรอบเดียวที่ run, แคมเปญการสื่อสารแบบวนส่งซ้ำจะนับสะสมตั้งแต่รอบที่ 1 - รอบล่าสุดที่ run ไปแล้ว, แคมเปญการตลาดอัตโนมัติจะนับสะสมเรื่อยๆ ตามยอดผู้รับปัจจุบัน (นับเฉพาะผู้รับที่ส่งแล้วเครดิตไม่พอ)',
  tooltip_column_accum_recp_pendingcredit: 'ผู้รับที่ส่งแล้วเครดิตไม่พอ​​',
  tooltip_column_est_credit_use: 'เครติดที่ต้องใช้โดยประมาณ',
  tooltip_datetimeMode: 'กำหนดเวลาการเริ่มทำงานอีกครั้งของแคมเปญ',
  tooltip_resendMode: 'ต้องการให้ระบบส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกเนื่องจากเครดิตหมดหรือไม่​',
  tooltip_resend: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_DoNot_resend: 'ไม่ต้องการส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_resendPreviousMode: 'เลือกรูปแบบการส่งย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_resend_allPrevious: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างทั้งหมด​',
  tooltip_resend_lastPreviousRound: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างเฉพาะรอบการส่งออกล่าสุด',
  tooltip_resend_lastPreviousRoundRepeat:
    'ต้องการส่งย้อนไปยังผู้รอรับคงค้างเฉพาะรอบล่าสุด กรณีมีมากกว่า 1 รอบการส่งค้าง ตัวอย่างเช่น มีการตั้งส่งทุกวันที่จันทร์ รอบคงค้างเนื่องจากเครดิตหมด มีจำนวน 3 จันทร์ ต้องการส่งย้อนหลังไปยังผู้รับคงค้างเฉพาะผู้ที่ควรจะได้รับรอบวันจันทร์รอบล่าสุดที่มีการคงค้างเท่านั้น',
  tooltip_resend_specifyPrevious: 'ต้องการเลือกรอบส่งแบบกำหนดเอง กรณีมีมากกว่า 1 รอบการส่งค้าง',
  sunday: 'วันอาทิตย์',
  expand: 'ขยาย',
  dayMonthYear: 'วัน/เดือน/ปี',
  language: 'th-th',
  save_wait: 'กำลังบันทึก กรุณารอสักครู่...',
  successful: 'ทำรายการสำเร็จ',
  //Limitation
  Limit_buttonEdit: 'แก้ไข',
  Limit_buttonSave: 'นำไปใช้',
  Limit_amountLimit: 'จำกัดจำนวนส่งออก',
  Limit_amountLimit_detail: 'จำกัดจำนวนข้อความที่ผู้ใช้งานสามารถส่งได้ต่อวัน, เดือน และปี ต่อลูกค้า 1 คน เพื่อความปลอดภัยให้ระบบ และบัญชีผู้ใช้',
  Limit_step: 'ขั้นตอนที่',
  Limit_stepOne_amountDetail: 'กรุณากำหนดจำนวนเพื่อจำกัดการส่งออกข้อความไปยังลูกค้าต่อ 1 คน',
  Limit_messagesPerDay: 'จำกัดข้อความต่อวัน',
  Limit_messagesPerMonth: 'จำกัดข้อความต่อเดือน',
  Limit_messagesPerYear: 'จำกัดข้อความต่อปี',
  Limit_noMoreThan: 'จำกัดไม่เกิน',
  Limit_Unlimited_messagesPerDay: 'ไม่จำกัดการส่งข้อความต่อวัน',
  Limit_Unlimited_messagesPerMonth: 'ไม่จำกัดการส่งข้อความต่อเดือน',
  Limit_Unlimited_messagesPerYear: 'ไม่จำกัดการส่งข้อความต่อปี',
  Limit_Unlimited_exportPerDay: 'ไม่จำกัดการส่งต่อวัน',
  Limit_Unlimited_exportPerMonth: 'ไม่จำกัดการส่งต่อเดือน',
  Limit_Unlimited_exportPerYear: 'ไม่จำกัดการส่งต่อปี',
  Limit_messages: 'ข้อความ',
  Limit_stepTwo_detail: 'ตั้งค่าการจำกัดการส่งข้อความ',
  Limit_disallow_exceedLimit: 'ห้ามการส่งออกย้อนหลัง หากจำนวนข้อความที่ส่งออกเกินกำหนด หรือ อยู่ในช่วงเวลาที่ระบบกำหนดห้ามส่งออก', //ห้ามการส่งออก หากจำนวนข้อความที่ส่งออกเกินที่จำกัด
  Limit_nextRound_exceedLimit: 'ส่งออกรอบถัดไป หากจำนวนข้อความที่ส่งออกเกินที่จำกัด',

  Limit_timeLimit: 'จำกัดเวลาส่งออก',
  Limit_timeLimit_detail: 'กำหนดตารางเวลาการส่งข้อความถึงลูกค้าทั้งหมด เพื่อความปลอดภัยของระบบและบัญชีผู้ใช้',
  Limit_stepOne_timeDetail: 'กรุณาเลือกวิธีการจำกัดเวลาการส่งออก',
  Limit_weekly: 'รายสัปดาห์',
  Limit_dateAndTime: 'การกำหนดเวลาส่งข้อความ',
  Limit_LimitedDays: 'จำกัดวัน',
  Limit_LimitedExportTime: 'จำกัดช่วงเวลาการส่งออก',
  Limit_between: 'ระหว่าง',
  Limit_to: 'ถึง',
  Limit_noTimeLimit: 'ไม่จำกัดเวลา',

  Limit_ResultOfMessage: 'ผลลัพธ์การจำกัดส่งข้อความ',
  Limit_sendingOfMessages_by: 'จำกัดการส่งข้อความโดย',
  Limit_cusReceive: 'ลูกค้าจะได้รับ',
  Limit_notMoreThan: 'ไม่เกิน',
  Limit_day: 'วัน',
  Limit_month: 'เดือน',
  Limit_year: 'ปี',

  Limit_tooltipButtonAdd: 'เพิ่มเวลาจำกัด',
  Limit_tooltipButtonRemove: 'ลบ',
  Limit_view_calendar: 'ผลลัพธ์การจำกัดวัน และเวลาการส่งข้อความ',

  Limit_ConfirmQuantityLimit: 'ยืนยันการจำกัดจำนวน',
  Limit_DateAndTimeOfExport: 'วัน และเวลาการส่งออก',
  Limit_Confirm_yesOrNo: 'ต้องการยืนยัน ใช่หรือไม่ ?',
  can_sent: 'ส่งออกได้',
  not_sent: 'ปิดการส่งออก',
  all_channel: 'รวมช่องทาง',
  dashboard: 'แดชบอร์ด​',
  calendar_view: 'ปฏิทิน',
  all: 'ทั้งหมด',
  reset: 'รีเซ็ต',
  export_pdf: 'ส่งออก PDF',
  export_ex: 'ส่งออก',
  schedule: 'กำหนดเวลาแคมเปญ',
  sending: 'ส่งออกแคมเปญ',
  completed: 'สำเร็จ',
  campaign_text: 'แคมเปญ',
  data_info: 'รายละเอียด',
  information: 'ข้อมูล',
  chart_name: 'ชื่อแผนภูมิ​',
  description: 'คำบรรยาย​',
  total_status: 'สถานะทั้งหมด',
  date_by: 'วันที่อัปเดตล่าสุด​',
  used_credit: 'เครดิตที่ถูกใช้​',
  credit_balance: 'เครดิตคงเหลือ',
  credit: 'เครดิต',
  open: 'เปิด​',
  grid_show: 'แสดงเป็นตาราง',
  calendar_show: 'แสดงเป็นปฏิทิน',
  campaign_name: 'ชื่อแคมเปญ​',
  last_update: 'อัปเดตล่าสุด​',
  update_by: 'อัปเดตโดย',
  note: ' โน๊ต​',
  add_note: 'เพิ่มโน๊ต​',
  add_description: 'เพิ่มคำบรรยาย​',
  date: 'วันที่​',
  create: 'สร้างแคมเปญ',
  template: 'เทมเพลต',
  sender: 'ผู้ส่ง​',
  select_sender: 'เลือกชื่อผู้ส่ง​',
  select_customer: 'เลือกกลุ่มลูกค้า​',
  setting_and_schedule: 'ตั้งค่า และกำหนดตารางเวลา​',
  preview_and_send: 'ตัวอย่าง และส่งออกแคมเปญ​',
  balanced_credit: 'เครดิตคงเหลือ',
  words_count: 'จำนวนอักษร',
  mobile_messaging: 'ข้อความบนมือถือ​',
  saved: 'บันทึกแล้ว​',
  save_as: 'บันทึกเป็น​',
  create_campaign: 'สร้างแคมเปญ',
  edit_campaign: 'แก้ไขแคมเปญ',
  reload: 'โหลดใหม่',
  today: 'วันนี้',
  message: 'ข้อความ',
  template_tooltip: 'เทมเพลต​',
  personalize_tooltip: 'ลักษณะส่วนบุคคล​',
  coupon_tooltip: 'คูปอง',
  emoji_tooltip: 'อีโมจิ',
  tracking_link_tooltip: 'ลิงก์',
  select_template: 'เลือกเทมเพลต',
  popular: 'ยอดนิยม',
  promotion: 'โปรโมชัน',
  seasonal: 'เทศกาล',
  awareness: 'การสร้างการรับรู้​',
  win_back: 'Win-back',
  engagement: 'การมีส่วนร่วม​',
  notification: 'แจ้งเตือน',
  other: 'อื่นๆ',
  news: 'ข่าวสาร',
  sales_and_promotion: '​การขายและโปรโมชัน​',
  see_more: 'ดูเพิ่มเติม​',
  use: 'นำไปใช้',
  standard: 'ทั่วไป',
  my_template: 'เทมเพลตของฉัน',
  template_name: 'ชื่อเทมเพลต​',
  category: 'หมวดหมู่แคมเปญ​',
  preview: 'ตัวอย่าง',
  personalize: 'ส่วนบุคคล​',
  edit_personalize: 'แก้ไขข้อมูลส่วนบุคคล',
  column: 'คอลัมน์/ แถว​',
  select: 'เลือก​',
  add: 'เพิ่ม',
  replace_no_data: 'ข้อความแทนช่องไม่มีข้อมูล',
  coupon_code: 'รหัสคูปอง​',
  edit_coupon_code: 'แก้ไขรหัสคูปอง​',
  reference: 'หมายเลขอ้างอิง',
  coupon_name: 'ชื่อคูปอง​',
  value_reference: 'มูลค่าอ้างอิง​',
  total: 'จำนวนคูปองทั้งหมด​',
  coupon_code_link: 'ลิงก์รหัสคูปอง​',
  expiration_datetime: 'วันเวลาหมดอายุ​',
  detail: 'รายละเอียด',
  coupon: 'คูปอง',
  all_folder: 'โฟลเดอร์ทั้งหมด​',
  folder: 'โฟลเดอร์​',
  segment: 'กลุ่มลูกค้า​',
  schedule_a_later: 'กำหนดภายหลัง​',
  recipients: 'ผู้รับ',
  recipient: 'ผู้รับ',
  customer: 'ลูกค้า',
  send: 'ส่ง',
  verify: 'ตรวจสอบ',
  please_fill_complete: 'กรุณากรอกข้อมูลให้ครบ',
  create_message: 'สร้างข้อความ',
  please_fill_text: 'โปรดเพิ่มข้อความ',
  please_fill_sender: 'โปรดเลือกผู้ส่ง',
  please_fill_customer: 'โปรดเลือกผู้รับ',
  please_fill_campaign: 'โปรดเพิ่มชื่อแคมเปญ',
  count_one: 'จำนวน {count} คน',
  start_sent: 'เริ่มส่ง',
  end_sent: 'สิ้นสุด',
  summary: 'สรุปแคมเปญ​',
  publish: 'แคมเปญที่ถูกเผยแพร่​',
  use_credit: 'เครดิตที่ถูกใช้ไปแล้ว​',
  balance: 'คงเหลือ',
  create_line_campaign: 'สร้างแคมเปญไลน์',
  line_campaign_information: 'ข้อมูลแคมเปญไลน์',
  characters: 'ตัวอักษร​',
  message_tooltip: 'ข้อความ',
  image_tooltip: 'รูปภาพ',
  video_tooltip: 'วิดีโอ',
  rich_message_tooltip: 'ริชเมสเสจ',
  rich_video_message_tooltip: 'ริชวิดีโอเมสเสจ​',
  card_message_tooltip: 'การ์ดเมสเสจ​',
  voice_message_tooltip: 'วอยซ์เมสเสจ การส่งข้อความเสียง',
  multi_message_tooltip: 'มัลติเมสเสจ',
  upload_image: 'อัปโหลดรูปภาพ',
  file_formats: 'รูปแบบไฟล์​',
  file_size: 'ขนาดไฟล์​',
  maximum: 'รองรับสูงสุด',
  select_message_type: 'เลือกประเภทข้อความ',
  coming_soon: 'เร็วๆนี้',
  create_template: 'สร้างเทมเพลต',
  edit_template: 'แก้ไขเทมเพลต',
  save_template: 'บันทึกเทมเพลต',
  subject: 'หัวข้อ',
  to: 'ถึง',
  create_line_template: 'สร้างเทมเพลตไลน์',
  line_template_information: 'ข้อมูลเทมเพลตไลน์',
  change_template: 'เปลี่ยนเทมเพลต',
  change_layout: 'เปลี่ยนรูปแบบ',
  action_label: 'ตัวอักษร',
  url_link: 'ลิงก์',
  upload_file: 'อัปโหลดไฟล์',
  change: 'เปลี่ยน',
  create_new: 'สร้างใหม่',
  detail_list_tooltip: 'รายละเอียด',
  delete_tooltip: 'ลบ',
  duplicate_tooltip: 'คัดลอก',
  edit_tooltip: 'แก้ไข',
  export_tooltip: 'ส่งออก',
  design: 'ออกแบบ หรือ ดีไซน์​',
  content: 'เนื้อหา',
  image: 'รูปภาพ',
  text: 'ข้อความ',
  video: 'วิดีโอ',
  social: 'โซเชียล',
  barcode_qr: 'บาร์โค้ด',
  layout: 'รูปแบบ/เลย์เอาท์',
  visit_url: 'เยี่ยมชม URL',
  edit: 'แก้ไข',
  remove: 'ลบ/ล้าง',
  padding: 'ระยะห่าง',
  top: 'บน',
  bottom: 'ล่าง',
  left: 'ซ้าย',
  right: 'ขวา',
  font_plus: 'Font Plus',
  font_minus: 'Font Minus',
  font_background: 'Font Background',
  font_bold: 'Font Bold',
  font_italic: 'Font Italic',
  font_underline: 'Font Underline',
  align_left: 'Align Left',
  align_center: 'Align Center',
  align_right: 'Align Right',
  font_style: 'สไตล์ตัวอักษร​',
  font_size: 'ขนาดตัวอักษร​',
  font_case: 'รูปแบบตัวอักษร',
  bold: 'หนา',
  italic: 'เอียง',
  underline: 'ขีดเส้นใต้​',
  center: 'กึ่งกลาง',
  bullet: 'หัวข้อ',
  ordered: 'ลำดับ',
  text_vertical_align: 'ระยะห่างระหว่างบรรทัด',
  text_color: 'สีข้อความ',
  done: 'เสร็จสิ้น',
  edit_image: 'แก้ไขรูปภาพ',
  size: 'ขนาด',
  width: 'กว้าง',
  height: 'สูง',
  alignment: 'การจัดวางตำแหน่ง',
  radius: 'รัศมี',
  border: 'ขอบ',
  alt_text: 'คำอธิบายรูปภาพ​',
  link: 'ลิงก์',
  open_link: 'เปิดลิงก์',
  open_link_in_new__window: 'เปิดลิงก์ในหน้าต่างใหม่',
  spacing: 'ช่องว่างระหว่างบรรทัด',
  edit_social: 'แก้ไขโซเชียล',
  choose_social: 'เลือกโซเชียล',
  p_choose_social: 'กรุณาเลือกโซเชียล',
  p_choose_coupon: 'กรุณาเลือกคูปอง',
  add_social: 'เพิ่มช่องทางโซเชียล',
  add_coupon: 'เพิ่มคูปอง',
  icon_text: 'ไอคอน และข้อความ',
  icon_only: 'ไอคอนเท่านั้น​',
  text_only: 'ข้อความเท่านั้น',
  setting: 'ตั้งค่า',
  edit_barcode_qr: 'แก้ไข บาร์โค้ด',
  edit_layout: 'แก้ไขเลย์เอาท์',
  edit_style: 'แก้ไขรูปแบบ',
  edit_video: 'แก้ไขรูปแบบ',
  clear_format: 'ล้างรูปแบบ',
  replace: 'แทนที่',
  show_display: 'แสดง',
  text_position: 'ตำแหน่งข้อความ',
  icon_styles: 'รูปแบบ icon',
  scale: 'ขนาด',
  margin: 'เว้นระยะ',
  not_found: 'ไม่พบข้อมูล',
  add_credit_success: 'การเติมเครดิตสำเร็จ',
  layout_type: 'ประเภทเลย์เอาท์',
  content_width: 'Content Width',
  full_width: 'Full Width',
  background: 'พื้นหลัง',
  body_color: 'สีองค์ประกอบ',
  select_background: 'เลือกพื้นหลัง',
  select_image: 'เลือกรูปภาพ',
  or: 'หรือ',
  coupon_type: 'ประเภทคูปอง',
  barcode_styles: 'รูปแบบบาร์โค้ด',
  barcode_color: 'สีบาร์โค้ด',
  background_color: 'สีพื้นหลัง',
  border_radius: 'ขอบโค้ง',
  video_type: 'รูปแบบวีดีโอ',
  video_link: 'ลิงก์วีดีโอ',
  upload_video: 'อัปโหลดวีดีโอ',
  upload_thumbnail: 'อัปโหลดภาพตัวอย่าง',
  sender_name: 'ชื่อผู้ส่ง',
  email_address: 'อีเมลล์',
  created_on: 'สร้างเมื่อ',
  created_by: 'สร้างโดย',
  created_time: 'สร้างเวลา',
  line_provider_id: 'Line Provider Id',
  close: 'ปิด',
  amount: 'จำนวน',
  ex_link: 'ตัวอย่าง www.chococdp.com',
  all_date: 'วันที่ทั้งหมด',
  head_delete_note: 'ลบโน๊ต',
  confirm_delete_note: 'คุณต้องการลบโน๊ตนี้หรือไม่',
  error: 'เกิดข้อผิดพลาด',
  cannot_download_report: 'ไม่สามารถดาวน์โหลด Report ได้',
  html_click_map: 'คลิก HTML',
  top_clicked_links: 'ลิงก์ที่ถูกคลิกสูงสุด',
  top_5_engaged_contacts: 'ผู้ติดต่อที่มีส่วนร่วม 5 อันดับแรก',
  fail: 'ล้มเหลว',
  select_all_filter_date: '(วันที่สร้าง campaign ถึง วันปัจจุบัน)',
  type_menu_recipient: {
    OPENED: 'คนที่เปิดแล้ว',
    CLICKED: 'คนที่คลิกลิงก์แล้ว',
    SENT: 'คนที่ส่งแล้ว',
    COMPLETED: 'คนที่ส่งสำเร็จ',
    FAILED: 'คนที่ส่งผิดพลาด',
    BOUNCE_RATE: 'คนที่ Bounce Rate',
    SPAM_REPORT: 'คนที่ Spam Report',
    UNSUBSCRIBE: 'คนที่ Unsubscribe',
    OUT_OF_CREDIT: 'คนที่เครดิตหมด',
    LIMITATION: 'คนที่ติดลิมิตการส่ง',
  },
  clicked: 'คลิก',
  lists: 'รายการ',
  tracking_link: 'ตั้งค่าลิงก์',
  edit_tracking_link: 'แก้ไขตั้งค่าลิงก์',
  sent: 'ส่งออก',
  opened: 'เปิด',
  go_to_campaign_dashboard: 'ไปที่แดชบอร์ดแคมเปญ',
  preview_url_link: 'ลิงก์ตัวอย่าง',
  unique_customer: 'ลูกค้า',
  first_name: 'ชื่อจริง',
  last_name: 'นามสกุล',
  mobile_no: 'เบอร์โทร',
  line_user_id: 'ไอดีไลน์',
  confirm_do_not_resend: 'ไม่ต้องส่งข้อความ',
  next_schedule_date: 'เวลาส่งรอบถัดไป',
  action: 'กระทำ',
  select_brand_and_branch: 'แหล่งที่มา และสาขาทั้งหมด',
  mark_completed: 'ทำเสร็จสิ้นแล้ว',
  mark_uncompleted: 'ทำไม่เสร็จสิ้น',
  high: 'สูง',
  medium: 'ปานกลาง',
  low: 'ต่ำ',
  none: 'ไม่มี',
  Unconsent_PDPA_Modal: 'แบรนด์นี้ไม่ได้เปิดใช้งานฟังก์ชันการส่งข้อความถึงบุคคลที่ไม่ได้ให้ความยินยอม',
  this_month: 'เดือนนี้',
  rv_title: 'การตอบโต้วิดีโอ',
  rv_show_button: 'แสดงปุ่ม',
  rv_hide_button: 'ซ่อนปุ่ม',
  rv_show_button_tooltip: 'แสดงปุ่มแอคชันเพื่อเชื่อมไปยังลิงก์ URL',
  rv_hide_button_tooltip: 'ไม่ต้องการแสดงปุ่มแอคชันหลังจบวิดิโอ',
  rv_detail: 'รายละเอียดปุ่มแอคชัน',
  rv_message_tag: 'ข้อความกำกับ',
  rv_file_recommend: 'รูปแบบไฟล์ที่รองรับ',
  rv_size_file: 'ขนาดไฟล์',
  rv_not_over: 'ไม่เกิน',
  rv_learn_more: 'ดูเพิ่มเติม',
  rv_install_app: 'ติดตั้ง',
  rv_purchase: 'ซื้อ',
  rv_pre_register: 'จอง',
  rv_enter: 'เข้าร่วม',
  rv_apply: 'สมัคร',
  rv_join_event: 'ร่วมกิจกรรม',
  rv_vote: 'โหวต',
  rv_search_for_store: 'ค้นหาร้าน',
  rv_contact_us: 'ติดต่อสอบถาม',
  rv_request_materials: 'ขอเอกสาร',
  rv_view_more_detail: 'ดูวิดีโออื่น ๆ',
  rv_custom: 'กำหนดเอง',
  all_channel_credit_tooltip: 'แสดงเพียงจำนวนเครดิตเท่านั้นไม่ส่งผลถึงหน้าแดชบอร์ด',
};
