<template>
  <div class="d-flex flex-column align-items-center justify-content-center main-forget text-center">
    <div class="check-email-forget-container">
      <div class="pic-check-email-forget-container"><img src="@assets/images/modules/authentication/check-email-forget.png" class="w-100" /></div>
      <div class="header-check-email-forget-container font-f2">
        <span>{{ t('authentication.forget_password.check_email.title') }}</span>
      </div>
      <div class="description-check-email-forget-container mt-2">
        <span>{{ t('authentication.forget_password.check_email.description_first') }}</span>
        <br />
        <span>{{ t('authentication.forget_password.check_email.description_second') }}</span>
        <span class="text-email font-f2"> {{ $route.query.email }}</span>
      </div>
      <div class="footer-description-check-email-forget-container">
        <span>{{ t('authentication.forget_password.check_email.go_back') }}</span>
        <router-link class="link-text-email font-f2" to="/login">{{ t('authentication.forget_password.check_email.link_login') }}</router-link>
      </div>
    </div>
    <div class="footer-check-email-container"><img src="@assets/images/modules/authentication/footer-forgot-password.png" /></div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import '@assets/styles/modules/authentication/check-email-forget.scss';
</style>
