import router from '@/router';
import { ref, Ref } from 'vue';
import { i18n } from '@/libs/i18n';

import api from '@/services/api';
import useLoading from '@/views/components/loading/hooks/useLoading';

import loginModel from '@/models/authentication/login';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';

export default function useForgetPassword() {
  const { fetchSendEmailToResetPass } = loginModel();

  const { isLoading } = useLoading();

  const isEmail: Ref<boolean> = ref(false);
  const isLoadingBtn: Ref<boolean> = ref(false);
  const isHaveEmail: Ref<boolean> = ref(true);

  const forgetEmail: Ref<string> = ref('');
  const errorMessage: Ref<string> = ref('');

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSendForget = () => {
    isLoading(true);
    fetchSendEmailToResetPass.payload.Email = forgetEmail.value.trim();
    api
      .apiRequest(fetchSendEmailToResetPass)
      .then((response) => {
        isHaveEmail.value = true;
        if (response.status == 'success') {
          router.replace({
            name: 'check-email-forget',
            query: {
              email: forgetEmail.value,
            },
          });
        }
      })
      .catch((err) => {
        isHaveEmail.value = false;
        errorMessage.value = err.data.error.locale[getLanguageDisplayModalError()].message;
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const onChangeEmail = () => {
    if (forgetEmail.value.length != 0) {
      if (validateEmail(forgetEmail.value)) {
        isEmail.value = true;
      } else {
        isEmail.value = false;
      }
    } else {
      isEmail.value = false;
    }
  };

  return {
    onSendForget,
    onChangeEmail,
    isEmail,
    isHaveEmail,
    forgetEmail,
    isLoadingBtn,
    errorMessage,
  };
}
