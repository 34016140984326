export default function segmentModel() {
  const fetchGetTableList: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetTableList`,
    method: 'GET',
    payload: {},
  };
  const fetchGetDataDisplayList: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetDataDisplayList`,
    method: 'POST',
    payload: {},
  };
  const fetchCountRecord: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/CountRecord`,
    method: 'POST',
    payload: {},
  };
  const fetchFolderListModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetFolderList`,
    method: 'POST',
    payload: {},
  };
  const fetchSegmentListModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentList`,
    method: 'POST',
    payload: {
      Ordering: [],
      Folder_Id: null!,
      Segment_Type: [], //list segment type
      Filters: [],
      Page: null!,
      Limit: null!,
      Search_Value: null!,
    },
  };

  const createFolderModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/CreateFolder`,
    method: 'POST',
    payload: {
      Folder_Name: null!,
      Parent_Folder_Id: null!,
    },
  };

  const fetchEditFolder: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/EditFolder`,
    method: 'POST',
    payload: {
      Folder_Id: null!,
      Folder_Name: null!,
    },
  };
  const fetchDeleteFolder: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/DeleteFolder`,
    method: 'POST',
    payload: {
      Folder_Id: null!,
    },
  };
  const fetchDeleteSegment: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/DeleteSegment`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };
  const fetchExportSegmentData: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/ExportSegmentData`,
    method: 'POST',
    payload: {
      Table_List: null!,
    },
    responseType: 'blob',
  };

  const fetchSourcesDetailModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSourceDetail`,
    method: 'GET',
    payload: {},
  };

  const createPreviewDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/CreatePreviewData`,
    method: 'POST',
    payload: {
      rule_set_list: null!,
      view_table_list: null!,
      view_column_list: null!,
    },
  };

  const fetchSegmentPreviewInfoModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentationPreviewInformation`,
    method: 'POST',
    payload: {
      Preview_Ref: null!,
    },
  };

  const fetchSegmentPreviewDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentPreviewData`,
    method: 'POST',
    payload: {
      Preview_Ref: null!,
    },
  };

  const saveSegmentModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/SaveSegment`,
    method: 'POST',
    payload: {},
  };

  const editSegmentModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/EditSegment`,
    method: 'POST',
    payload: {},
  };

  const editSegmentNameModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_SEGMENTv2!}/segmentV2/EditSegmentName`,
    method: 'POST',
    payload: {},
  };

  const createPreviewSelectCustomModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom`,
    method: 'POST',
    payload: {
      clone_id: null,
      select: {},
    },
  };

  const fetchPreviewSelectCustomInfoModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref`,
    method: 'GET',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const fetchPreviewSelectCustomResultModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref/query`,
    method: 'POST',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const updatePreviewSelectCustomConfigModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref/config`,
    method: 'PUT',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const fetchPreviewSelectCustomAvailableDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref/available-data/query`,
    method: 'POST',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const addPreviewSelectDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref/add`,
    method: 'PUT',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const removePreviewSelectDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/preview/select-custom/:preview_ref/remove`,
    method: 'PUT',
    payload: {},
    pathParams: {
      preview_ref: null,
    },
  };

  const saveSegmentSelectCustomModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/segments/create/select-custom`,
    method: 'POST',
    payload: {},
  };

  const editSegmentSelectCustomModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_CUSTOM!}/segments/edit/select-custom`,
    method: 'POST',
    payload: {},
  };

  const fetchSegmentResultModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentResult`,
    method: 'POST',
    payload: {},
  };

  const fetchSegmentDataModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/FetchSegmentData`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };

  const fetchGetSegmentConfigModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetSegmentConfig`,
    method: 'POST',
    payload: {
      SegmentId: null!,
    },
  };

  const fetchGetUniqueData: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2!}/segmentV2/GetUniqueData`,
    method: 'POST',
    payload: {},
  };

  const fetchColumnDisplayStateModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_COLUMN_DISPLAYSTATE!}/column-display-state/:reference_type/:reference_id`,
    method: 'GET',
    payload: {},
    pathParams: {
      reference_type: null,
      reference_id: null,
    },
  };

  const updateColumnDisplayStateModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_SEGMENTv2_COLUMN_DISPLAYSTATE!}/column-display-state/:reference_type/:reference_id`,
    method: 'POST',
    payload: {
      display_states: [],
    },
    pathParams: {
      reference_type: null,
      reference_id: null,
    },
  };

  return {
    fetchGetTableList,
    fetchGetDataDisplayList,
    fetchCountRecord,
    fetchFolderListModel,
    fetchSegmentListModel,
    createFolderModel,
    fetchEditFolder,
    fetchDeleteFolder,
    fetchDeleteSegment,
    fetchExportSegmentData,
    fetchSourcesDetailModel,
    createPreviewDataModel,
    fetchSegmentPreviewInfoModel,
    fetchSegmentPreviewDataModel,
    saveSegmentModel,
    editSegmentModel,
    createPreviewSelectCustomModel,
    fetchPreviewSelectCustomInfoModel,
    fetchPreviewSelectCustomResultModel,
    updatePreviewSelectCustomConfigModel,
    fetchPreviewSelectCustomAvailableDataModel,
    addPreviewSelectDataModel,
    removePreviewSelectDataModel,
    saveSegmentSelectCustomModel,
    editSegmentSelectCustomModel,
    fetchSegmentResultModel,
    fetchSegmentDataModel,
    fetchGetSegmentConfigModel,
    fetchGetUniqueData,
    fetchColumnDisplayStateModel,
    updateColumnDisplayStateModel,
    editSegmentNameModel,
  };
}
