<template>
  <layout-vertical>
    <router-view />
    <noti-credit-modal :is-modal-open="isNotiCreditModalOpen" @open-modal="onOpenNotiCreditModal" @close-modal="onCloseNotiCreditModal" />
    <noti-credit-success :is-modal-open="isNotiCreditSuccessOpen" @open-modal="onOpenNotiCreditSuccess" @close-modal="onCloseNotiCreditSuccess" />
  </layout-vertical>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import LayoutVertical from '../../../layouts/layout-vertical/LayoutVertical.vue';
import NotiCreditModal from '@/views/components/noti-credit-modal/notiCreditModal.vue';
import NotiCreditSuccess from '@/views/components/noti-credit-success/notiCreditSuccess.vue';
import { useAuthStore } from '@/store/authStore';

export default defineComponent({
  components: {
    LayoutVertical,
    NotiCreditModal,
    NotiCreditSuccess,
  },

  setup() {
    const authStore = useAuthStore();
    const isNotiCreditModalOpen = ref<boolean>(false);
    const isNotiCreditSuccessOpen = ref<boolean>(false);

    const onCloseNotiCreditModal = () => {
      isNotiCreditModalOpen.value = false;
    };
    const onCloseNotiCreditSuccess = () => {
      isNotiCreditSuccessOpen.value = false;
    };
    const onOpenNotiCreditSuccess = () => {
      if (authStore.isCreditChecked == true) {
        isNotiCreditSuccessOpen.value = true;
      } else {
        isNotiCreditSuccessOpen.value = false;
      }
    };
    const onOpenNotiCreditModal = () => {
      if (authStore.isCreditChecked == true) {
        if (isNotiCreditSuccessOpen.value == true) {
          isNotiCreditModalOpen.value = false;
        } else {
          isNotiCreditModalOpen.value = true;
        }
      } else {
        isNotiCreditModalOpen.value = false;
      }
    };
    onMounted(() => {
      if (authStore.isCreditChecked == true) {
        // isNotiCreditModalOpen.value = true;
        // isNotiCreditSuccessOpen.value = true;
      } else {
        isNotiCreditModalOpen.value = false;
        isNotiCreditSuccessOpen.value = false;
      }
    });
    return {
      isNotiCreditModalOpen,
      onCloseNotiCreditModal,
      isNotiCreditSuccessOpen,
      onCloseNotiCreditSuccess,
      onOpenNotiCreditSuccess,
      onOpenNotiCreditModal,
    };
  },
});
</script>
