<template>
  <div style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="nav-breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, index) in breadcrumb" :key="index">
        <a class="breadcrumb-text" :class="{ 'breadcrumb-active': item.active }" @click="onClickBreadcrumb(item)">{{ t(item.text) }}</a>
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import useBreadCrumb from './useBreadCrumb';
import { useI18n } from 'vue-i18n';

const { breadcrumb, onClickBreadcrumb } = useBreadCrumb();
const { t } = useI18n();
</script>

<style scoped>
@import '@assets/styles/base/bread-crumb.scss';
</style>
