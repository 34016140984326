<template>
  <div v-if="isLoading ? false : isProvider ? false : true" class="header-card-prodider">
    <div class="icon-card-provider"><WarningIcon class="icon-not-provider" /></div>
    <div class="text-card-provider-container">
      <div class="text-card-provider">
        {{ t('notification.card_provider_one') }}
      </div>
      <div class="text-card-provider">
        {{ t('notification.card_provider_two') }}
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="card-noti-container">
    <div class="loading-card-noti-header"></div>
    <div class="card-noti-content">
      <div class="icon-loading-card-noti"></div>
      <div class="loading-card-noti"></div>
      <div class="loading-card-noti"></div>
    </div>
    <div class="card-noti-footer">
      <div class="loading-card-noti-footer"></div>
    </div>
  </div>
  <div v-else class="card-noti-container">
    <div :class="typeCard == 1 ? 'card-noti-header-r' : typeCard == 2 ? 'card-noti-header-y' : 'card-noti-header-n'">
      <div v-if="typeCard == 1" class="header-container">
        <div class="icon-header-card"><notiRedIcon /></div>

        <div class="text-header-card-r">{{ t('notification.card_credit_notification.title.empty') }}</div>
      </div>
      <div v-else-if="typeCard == 2" class="header-container">
        <div class="icon-header-card"><notiYellowIcon /></div>

        <div class="text-header-card-y">{{ t('notification.card_credit_notification.title.non_empty') }}</div>
      </div>
      <div v-else-if="typeCard == 3" class="header-container">
        <div class="icon-header-card"><notiNormalIcon /></div>

        <div class="text-header-card-n">{{ t('notification.card_credit_notification.title.normal') }}</div>
      </div>
    </div>
    <div class="card-noti-content"><slot name="content-card" /></div>
    <div class="card-noti-footer"><slot name="text-footer" /></div>
  </div>
</template>
<script setup lang="ts">
import { ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import notiRedIcon from '@/assets/icons/modules/communication/notiRedIcon.vue';
import notiYellowIcon from '@/assets/icons/modules/communication/notiYellowIcon.vue';
import notiNormalIcon from '@/assets/icons/modules/communication/notiNormalIcon.vue';
import WarningIcon from '@/assets/icons/base/WarningIcon.vue';

import BaseLoading from '@/views/components/loading/BaseLoading.vue';

interface Props {
  typeCard?: number;
  isLoading?: boolean;
  isProvider?: boolean;
}
const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  isProvider: true,
});
</script>

<style lang="scss">
.header-card-prodider {
  display: flex;
  width: 19.438rem;
  align-items: center;
  background: #ffd657;
  border-radius: 20px 20px 0px 0px;
  .icon-card-provider {
    display: flex;
    margin: 0.5rem;
    .icon-not-provider {
      fill: #000000;
      width: 40px;
      height: 40px;
      path {
        fill: #ffd657;
      }
    }
  }
  .text-card-provider-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-right: 0.25rem;
    margin-bottom: 1rem;
    margin-left: 0.25rem;
    .text-card-provider {
      color: #000000;
    }
  }
}
.card-noti-container {
  display: flex;
  flex-direction: column;
  margin-top: -0.7rem;
  width: 19.438rem;
  height: 27rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px #e5f0fa;
  border-radius: 20px;
  .card-noti-header-r {
    height: 20%;
    background: #e0534025 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
  }
  .card-noti-header-y {
    height: 20%;
    background: #ffd65725 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
  }
  .card-noti-header-n {
    height: 20%;
    background: #e2e2e24d 0% 0% no-repeat padding-box;
    border-radius: 20px 20px 0px 0px;
  }
  .loading-card-noti-header {
    height: 20%;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    animation: colorChangeCredit 1s infinite alternate;
  }
  .card-noti-content {
    height: 60%;

    .icon-loading-card-noti {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 1rem 0.2rem 0 1rem;
      animation: colorChangeCredit 1s infinite alternate;
    }
    .loading-card-noti {
      margin-left: 1rem;
      margin-top: 1rem;
      height: 10%;
      border-radius: 20px;
      width: 80%;
      animation: colorChangeCredit 1s infinite alternate;
    }
  }
  .card-noti-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    font: normal normal normal 18px/31px Prompt;
    letter-spacing: 0px;
    color: #007fff;
    .loading-card-noti-footer {
      height: 30%;
      border-radius: 20px;
      width: 50%;
      animation: colorChangeCredit 1s infinite alternate;
    }
  }
  @keyframes colorChangeCredit {
    0% {
      background-color: #e2e2e225;
    }
    100% {
      background-color: #0080ff21;
    }
  }
}
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .icon-header-card {
    margin-right: 0.5rem;
  }
  .text-header-card-r {
    color: #e05240;
    font: normal normal normal 16px/13px Prompt;
  }
  .text-header-card-y {
    color: #000000;
    font: normal normal normal 16px/13px Prompt;
  }
  .text-header-card-n {
    color: #000000;
    font: normal normal normal 16px/13px Prompt;
  }
}
</style>
