import { jwtDecode, JwtPayload } from 'jwt-decode';

export const validateTokenStructure = (token: string): boolean => {
  const parts = token.split('.');
  if (parts.length !== 3) {
    console.error('Invalid token structure: A valid token must have three parts');
    return false;
  }
  return true;
};

export const validateTokenHeader = (token: string): boolean => {
  try {
    const decodeHeaderToken = jwtDecode<JwtPayload>(token, { header: true });
    if (decodeHeaderToken) return true;
  } catch (e) {
    return false;
  }
  return false;
};

export const decodeTokenSafely = (token: string): any | null => {
  if (!validateTokenStructure(token)) return null;

  try {
    const decodedHeader = validateTokenHeader(token);
    const decodedPayload = jwtDecode<JwtPayload>(token);
    const tokenExp = decodedPayload.exp;
    if (decodedHeader && decodedPayload && tokenExp && tokenExp >= (Date.now() / 1000)) {
      return decodedPayload;
    }
    return null;
  } catch (error: any) {
    console.error('Error decoding token:', error.message);
    return null;
  }
};

export const isTokenValid = (token: string): boolean => {
  try {
    return !!decodeTokenSafely(token);
  } catch (e) {
    console.error('Token is invalid or corrupted', e);
    return false;
  }
};

//
//   // Example: Validate expiration (exp claim)
//   if (decodedPayload.exp && Date.now() >= decodedPayload.exp * 1000) {
//     console.error('Token is expired');
//     return false;
//   }
//
//   console.log('Token is valid');
//   return true;
// };
