<template>
  <div class="navigation-body">
    <template v-for="(item, index) in items" :key="index">
      <component
        v-if="resolveComponentDisplay(item)"
        :is="resolveNavItemComponent(item)"
        :item="item"
        :indexTab="index"
        :tabIndexSelect="menuSelected"
        v-on="resolveEmits()"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../../../../../utils';
import { provide, ref, defineComponent, onBeforeMount } from 'vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
// import { useSegmentVersion } from '@/store/segmentV2Store';
//Martech
import { useModuleStore } from '@/store/moduleStore';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

export default defineComponent({
  components: {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const menuSelected = ref(0);
    //Martech
    // const segmentVersionStore = useSegmentVersion();
    const allModuleStore = useModuleStore();
    function resolveComponentDisplay(item: any) {
      switch (item?.versionKey) {
        case segmentVersionKey:
          if (item?.version == allModuleStore.getSegmentVersion) return true;
          return false;
        default:
          return true;
      }
    }

    const onMenuSelect = (valSelect: any) => {
      menuSelected.value = valSelect;
    };

    const resolveEmits = (): object => {
      let itemEmits = {} as object;

      itemEmits = {
        ['on-click-menu']: (valSelect: object) => onMenuSelect(valSelect),
      };

      return itemEmits;
    };

    onBeforeMount(() => {
      const baseUrl: string = import.meta.env.BASE_URL || '';
      const currentRoute = window.location;
      const redirect = currentRoute.pathname.replaceAll(baseUrl, '/');
      const indexByPath = props.items.map((e: any) => e.path).indexOf(redirect);
      menuSelected.value = indexByPath;
      //Martech
      // allModuleStore.updateVersionByModule('2.0.0', 'Segment');
      // await segmentVersionStore.fetchSegmentVersion();
    });

    provide('openGroups', ref([]));
    return {
      resolveComponentDisplay,
      resolveNavItemComponent,
      menuSelected,
      resolveEmits,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/vertical-nav-bar.scss';
</style>
