// import { RouteRecordRaw } from 'vue-router';
//
// // Helper function to set requiresAuth to true for all routes
// function addRequiresAuth(routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
//   return routes.map((route) => {
//     // Ensure `meta` is initialized and set `requiresAuth` to true by default
//     route.meta = { ...(route.meta || {}), requiresAuth: route.meta?.requiresAuth !== false };
//
//     // Recursively process child routes, if they exist
//     if (Array.isArray(route.children)) {
//       route.children = addRequiresAuth(route.children);
//     }
//
//     return route;
//   });
// }

// import { RouteRecordRaw } from 'vue-router';
//
// function addRequiresAuth(routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
//   return routes.map((route) => {
//     route.meta = {
//       ...(route.meta || {}),
//       requiresAuth: route.meta?.requiresAuth !== false,
//     } as RouteRecordRaw['meta'];
//
//     if (Array.isArray(route.children)) {
//       route.children = addRequiresAuth(route.children);
//     }
//
//     return route;
//   });
// }

// import { RouteRecordRaw, RouteRecordSingleViewWithChildren, RouteRecordNormalized } from 'vue-router';
//
// // interface RouteRecordRawWithChildren extends RouteRecordNormalized {
// //
// // }
//
// function addRequiresAuth(routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
//   return routes.map((route) => {
//     // Type assertion to handle routes with potential children
//     const routeWithChildren = route as RouteRecordRawWithChildren;
//
//     // Ensure `meta` is initialized and set `requiresAuth` to true by default
//     routeWithChildren.meta = {
//       ...(routeWithChildren.meta || {}),
//       requiresAuth: routeWithChildren.meta?.requiresAuth !== false,
//     };
//
//     // Check and process child routes
//     if (Array.isArray(routeWithChildren.children)) {
//       routeWithChildren.children = addRequiresAuth(routeWithChildren.children);
//     }
//
//     return routeWithChildren;
//   });
// }

import { RouteRecordRaw } from 'vue-router';

function addRequiresAuth(routes: any): Array<RouteRecordRaw> {
  return routes.map((route: any) => {
    // Create a copy of the route that allows optional children
    return {
      ...route,
      meta: {
        ...(route.meta || {}),
        requiresAuth: route.meta?.requiresAuth !== false,
      },
      children: route.children ? addRequiresAuth(route.children) : undefined,
    } as RouteRecordRaw;
  });
}

// import { RouteRecordRaw, RouteRecordSingleViewWithChildren,RouteRecordSingleView, RouteRecordMultipleViews } from 'vue-router';
//
// type ExtendedRouteRecord = (RouteRecordRaw | RouteRecordSingleView | RouteRecordMultipleViews) & {
//   children?: ExtendedRouteRecord[];
// };
//
// function addRequiresAuth(routes: Array<ExtendedRouteRecord>): Array<ExtendedRouteRecord> {
//   return routes.map((route) => {
//     // Ensure `meta` is initialized and set `requiresAuth` to true by default
//     route.meta = { ...(route.meta || {}), requiresAuth: route.meta?.requiresAuth !== false };
//
//     // Recursively process child routes, if they exist
//     if (route.children && Array.isArray(route.children)) {
//       route.children = addRequiresAuth(route!.children);
//     }
//
//     return route;
//   });
// }

export { addRequiresAuth };
