<script setup lang="ts">
// composables
import useTwoFactorInput from '../hooks/useTwoFactorInput';

interface Props {
  inputValue: { input: string };
  maxDigit: number;
  validError?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  maxDigit: 6,
  validError: false,
});

interface Emits {
  (e: 'clearErrorClass'): void;
  (e: 'callbackMaxDegit'): void;
}

const emits = defineEmits<Emits>();

const { inputDigit, inputTwoFactor, focusInput, clearInput } = useTwoFactorInput(props, emits);

defineExpose({
  clearInput,
});
</script>
<template>
  <div class="otp-input-container">
    <div class="verify-otp-6-digit">
      <div class="wrp-otp" v-for="i in maxDigit" :key="'otp-' + i">
        <input
          @input="inputTwoFactor"
          v-model="inputDigit[`input${i}`]"
          @focus="focusInput"
          type="tel"
          pattern="[0-9]"
          :id="`digit-${i}`"
          class="otp-input font-f2"
          :class="[inputDigit[`input${i}`] ? 'fill-in' : '', validError ? 'valid-error' : '']"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.otp-input-container {
  .verify-otp-6-digit {
    display: flex;
    justify-content: space-between;
    width: 334px;
    .wrp-otp {
      .otp-input {
        width: 54px;
        height: 60px;
        font-size: 1.6rem;
        text-align: center;
        border: 1px solid #e3e3e3;
        background-color: #ffffff;
        border-radius: 10px;
        color: #000000;
        padding: unset;
        line-height: 1;

        &:focus-visible {
          outline: none;
        }
        &.fill-in {
          border: 1px solid #007fff;
        }
        &.valid-error {
          border: 1px solid #e05240;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .otp-input-container {
    .verify-otp-6-digit {
      width: 476px;
      .wrp-otp {
        .otp-input {
          width: 60px;
          height: 86px;
          font-size: 2.72rem;
        }
      }
    }
  }
}
</style>
