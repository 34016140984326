import { validateUrl } from '@/utils/router';
import { fetchThrottledTokenRefresh } from '@/libs/axios';
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { throttle } from 'lodash-es';

// Constant
import { moduleName } from '@/constants/global/module';

// Components & Page
const Login = () => import('@/views/modules/authentication/pages/LoginPage.vue');
const Preview = () => import('@/views/modules/preview/pages/Preview.vue');
// Fall back page
const NotFoundPage = () => import('@/views/modules/unauthorized/404NotFound.vue');

// Routes
import authenticationRoute from './routes/authentication';
import segmentRoutes from './routes/segment';
import mkaRoutes from './routes/mka';
import dataConnectRoutes from './routes/data-connect';
import communicationRoutes from './routes/communication';
import analyticsRoutes from './routes/analytics';
import reportRoutes from './routes/report';
import settingRoutes from './routes/setting';
import customerInsightRoutes from './routes/customer-insight';
import permissionRoutes from './routes/permission';
import MyAccountRoutes from './routes/My-Account';
import MainNotificationCreditRoutes from './routes/notification-credit';
import SegmentV2Routes from './routes/segment-v2';
// custom brands
import bangchakAnalyticsRoute from '@/router/routes/custom-brands/bangchak/bangchak';

// Hook for modals
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

// Store
import { useRouterBypassStore } from '@/store/routerStore';
import { useModuleStore } from '@/store/moduleStore';
import { $ResetPinia } from '@/store';
import { useBrandStore } from '@/store/brandStore';

// Service
import moduleService from '@/services/module';
import authService from '@/services/authentication';
import brandService from '@/services/brand';

import { useSettingStore } from '@/store/settingStore';
import { storeToRefs } from 'pinia';
import { addRequiresAuth } from '@/helpers/router-helper';

const routes: Array<RouteRecordRaw> = addRequiresAuth([
  {
    path: '/',
    name: 'auth-login',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'auth-login' },
  },
  {
    path: '/not-found',
    name: 'not-found-page',
    component: NotFoundPage,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  ...authenticationRoute,
  ...dataConnectRoutes,
  ...mkaRoutes,
  ...segmentRoutes,
  ...analyticsRoutes,
  ...communicationRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...permissionRoutes,
  ...customerInsightRoutes,
  // custom brands
  ...bangchakAnalyticsRoute,
  ...MyAccountRoutes,
  ...MainNotificationCreditRoutes,
  ...SegmentV2Routes,

  // markdown preview
  {
    path: '/docs-markdown',
    name: 'docs-markdown',
    component: () => import('@/views/modules/docs-markdown/DocsMarkdown.vue'),
  },
  {
    path: '/docs-markdown-direct',
    name: 'docs-markdown-direct',
    component: () => import('@/views/modules/docs-markdown/test.md'),
  },
]);

// reduce built file
if (import.meta.env.VITE_INCLUDE_PREVIEW === 'true') {
  routes.push({
    path: '/preview/:component',
    name: 'preview-component',
    component: () => import('@/views/modules/preview/pages/Preview.vue'),
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
});

const baseUrl: string = import.meta.env.VITE_BASE_URL || '';
// const brand = authService.getLocalBrandData() ? JSON.parse(authService.getLocalBrandData()!)[0] : null;

// const throttledApiRequest = throttle(async () => {
//   const now = Math.floor(Date.now() / 1000);
//   const now = 1727666971; // เอาไว้ Test เอา time มาใส่
// });
// Helper function to reset user state
const resetUserState = () => {
  $ResetPinia();
  localStorage.clear();
};

const handleRouterGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  try {
    const routerStore = useRouterBypassStore();
    const { openWarningModal } = useValidationModal();
    const settingStore = useSettingStore();
    const { currentLocale } = storeToRefs(settingStore);
    // Handle route confirmation modal
    if (
      from.meta.requiresConfirmation &&
      !routerStore.isBypassConfirmation &&
      Array.isArray(from.meta.bypassConfirmations) &&
      !from.meta.bypassConfirmations.includes(to.name)
    ) {
      openWarningModal(
        currentLocale.value == 'th' ? 'ต้องการออกจากหน้านี้หรือไม่' : 'Leave this page?',
        currentLocale.value == 'th'
          ? 'ต้องการยกเลิกการสร้างเงื่อนไขกลุ่มเป้าหมายและออกจากหน้านี้?'
          : 'Do you want to cancel the segmentation  settings and leave this page?',
        async () => {
          // return router.push({ name: to.name as string });
          routerStore.update(true);
          // return next(to);
          return await router.push({ ...to }).then(() => routerStore.update(false));
        },
      );
      return next(false);
    }

    await handleModuleVersion(to, from, next);
  } catch (e) {
    console.error('Error in handleRouterGuard:', e);
    next();
  }
};

const handleModuleVersion = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  try {
    const hasLocalModuleVersion = moduleService.hasModuleVersionFromLocalStorage();
    const moduleStore = useModuleStore();

    // Check exist module version in local storage
    if (!hasLocalModuleVersion && authService.isUserLoggedIn()) {
      await moduleService.fetchAndSetModuleVersions();
    }

    //     // call API refresh token
    //     await apiService
    //       .apiRequest(fetchRefreshTokenModel)
    //       .then((res: Record<string, any>) => {
    //         let tempBrand: Array<any> = [];
    //         if (authService.getLocalBrandData()) {
    //           tempBrand = [
    //             ...JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
    //               return o.brandRef != fetchBrandRef();
    //             }),
    //           ];
    //         }

    //         tempBrand.push({
    //           brandRef: fetchBrandRef(),
    //           brandData: authService.fetchBrandData()?.brandData,
    //           accessToken: res.data.access_token,
    //           refreshToken: res.data.refresh_token,
    //         });

    //         // set item local new token
    //         authService.setLocalBrandData(JSON.stringify(tempBrand));
    //       })
    //       .catch((error) => {
    //         console.error('Failed to refresh token:', error);
    //       });
    //   } catch (error) {
    //     console.error('Error calling API:', error);
    //   }
    // }
    //case segment
    const metaModuleVersion = to.meta.moduleVersion as string;
    if (to.meta.moduleName == moduleName.Segment && metaModuleVersion) {
      // if (to.name == 'data-segment-detail-list') {
      //   return next();
      // } else
      if (moduleStore.getSegmentVersion && moduleStore.getSegmentVersion !== metaModuleVersion) {
        switch (moduleStore.getSegmentVersion) {
          case '1.0.0':
            if (to.meta.redirectNotMatchVersion) return next({ name: to.meta.redirectNotMatchVersion as string });
            // Prevent Infinite
            if (to.name == 'data-segment-main') return next();
            else return next({ name: 'data-segment-main' });
          case '2.0.0':
            if (to.meta.redirectNotMatchVersion) return next({ name: to.meta.redirectNotMatchVersion as string });
            // Prevent Infinite
            if (to.name == 'segmentV2') return next();
            else return next({ name: 'segmentV2' });
          default:
            return next({ path: '/' });
        }
      }
    }
    next();
  } catch (e) {
    console.error('Error in handleModuleVersion:', e);
    next();
  }
};

const addBrandRefQueryParams = async (to: RouteLocationNormalized, currentBrandRef: string) => {
  // Add `brandRef` to query if it's missing
  await router.push({
    ...to,
    query: { ...to.query, brandRef: currentBrandRef },
  });
  // router.go(0);
};

router.beforeEach(async (to, from, next) => {
  const brandStore = useBrandStore();
  const isUserLoggedIn = authService.isUserLoggedIn();
  const redirectUrl = to.query.redirect as string;

  // Handle by login status
  if (isUserLoggedIn) {
    // Redirect for logged-in users
    if (to.meta.redirectIfLoggedIn) {
      if (redirectUrl && redirectUrl !== '/' && validateUrl(redirectUrl)) {
        // Redirect to a valid URL if set
        return await handleRouterGuard(to, from, () => next(redirectUrl));
      } else {
        return next({ name: 'analytics-dashboard' });
      }
    }

    await fetchThrottledTokenRefresh();
    const currentBrandRef = brandStore.getBrandRef;
    const brandRefQuery = to.query.brandRef;

    // Add `brandRef` to query if its missing
    if (!brandRefQuery && currentBrandRef) {
      await addBrandRefQueryParams(to, currentBrandRef);
    }
  }

  // Handle by meta
  // Handle authentication routes
  if (to.meta.requiresAuth) {
    if (!isUserLoggedIn) {
      resetUserState();
      return next({ name: 'auth-login' });
    }
    if (redirectUrl && redirectUrl !== '/' && validateUrl(redirectUrl)) {
      // Redirect to a valid URL if set
      return await handleRouterGuard(to, from, () => next(redirectUrl));
    }
    return await handleRouterGuard(to, from, next);
  }

  // await throttledApiRequest();
  return next();
});

export default router;
