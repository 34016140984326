export default function mfaModel() {
  const fetchMfaRegister: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/MFA/Register`,
    method: 'POST',
    payload: {
      Token: '',
    },
  };

  const fetchMfaTotpConfirm: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/MFA/TOTP_Confirm`,
    method: 'POST',
    payload: {
      Token: '',
      MFA_Credential: '',
    },
  };

  const fetchMfaCheck: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/MFA/Check`,
    method: 'POST',
    payload: {
      Token: '',
      MFA_Credential: '',
    },
  };

  const fetchMfaVerify: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/MFA/Verify`,
    method: 'POST',
    payload: {
      Token: '',
      MFA_Credential: '',
    },
  };

  return {
    fetchMfaRegister,
    fetchMfaTotpConfirm,
    fetchMfaCheck,
    fetchMfaVerify,
  };
}
