<template>
  <div @click="onClickMainMenu(item.path)" v-if="item.path">
    <div :class="`navigation-menu-item-wrp ${indexTab == tabIndexSelect ? 'active' : ''}`">
      <div :class="`navigation-menu-item-content-wrp ${indexTab == tabIndexSelect ? 'active' : ''}`">
        <div class="icon">
          <component v-if="item.customIcon" :is="item.customIcon" />
        </div>
        <div class="title">
          {{ t(item.title) }}
        </div>
      </div>
    </div>
  </div>
  <div @click="updateGroupOpen(!isOpen)" v-else>
    <div :class="`navigation-menu-item-wrp ${indexTab == tabIndexSelect ? 'active' : ''}`">
      <div :class="`navigation-menu-item-content-wrp ${indexTab == tabIndexSelect ? 'active' : ''}`">
        <div class="icon">
          <component v-if="item.customIcon" :is="item.customIcon" />
        </div>
        <div class="title">
          {{ t(item.title) }}
        </div>
        <div class="arrow-down">
          <arrow-down-icon />
        </div>
      </div>
    </div>
  </div>
  <div :class="!isOpen ? 'hidden' : 'show'">
    <template v-for="child in item.children" :key="child.title">
      <component
        v-if="resolveComponentDisplay(child)"
        :is="resolveNavItemComponent(child)"
        ref="groupChild"
        :item="child"
        :mainIndexMenu="indexTab"
        v-on="resolveEmits('data_connector')"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '../../../../../utils';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup';
import ArrowDownIcon from '@/assets/icons/base/navbarIcon/ArrowDownIcon.vue';
import SegmentIcon from '@/assets/icons/base/navbarIcon/SegmentIcon.vue';
import CommunicationIcon from '@/assets/icons/base/navbarIcon/CommunicationIcon.vue';
import MkaIcon from '@/assets/icons/base/navbarIcon/MkaIcon.vue';
import AnalyticIcon from '@/assets/icons/base/navbarIcon/AnalyticIcon.vue';
import DataConnectorIcon from '@/assets/icons/base/navbarIcon/DataConnectorIcon.vue';
import MarketingAutomationIcon from '@/assets/icons/base/navbarIcon/MarketingAutomationIcon.vue';
import ReportIcon from '@/assets/icons/base/navbarIcon/ReportIcon.vue';
import SettingIcon from '@/assets/icons/base/navbarIcon/SettingIcon.vue';
import { useI18n } from 'vue-i18n';

import { useModuleStore } from '@/store/moduleStore';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

export default defineComponent({
  components: {
    VerticalNavMenuLink,
    ArrowDownIcon,
    AnalyticIcon,
    SegmentIcon,
    DataConnectorIcon,
    CommunicationIcon,
    MkaIcon,
    MarketingAutomationIcon,
    ReportIcon,
    SettingIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    indexTab: {
      type: Number,
      required: false,
    },
    tabIndexSelect: {
      type: Number,
      required: false,
    },
  },
  emits: ['on-click-menu'],
  setup(props) {
    const { isOpen, updateGroupOpen, doesHaveSubChild, resolveEmits, onClickMainMenu } = useVerticalNavMenuGroup(props.item, props.indexTab);
    const { t } = useI18n();

    const allModuleStore = useModuleStore();

    function resolveComponentDisplay(item: any) {
      switch (item?.versionKey) {
        case segmentVersionKey:
          if (item?.version == allModuleStore.getSegmentVersion) return true;
          return false;
        default:
          return true;
      }
    }
    return {
      resolveComponentDisplay,
      resolveNavItemComponent,
      doesHaveSubChild,
      updateGroupOpen,
      resolveEmits,
      onClickMainMenu,
      t,
      isOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/vertical-nav-bar.scss';
</style>
