import { deepClone } from '@/views/modules/customer-insight/utils';
import { acceptHMRUpdate, defineStore } from 'pinia';
// define store
// see: https://pinia.vuejs.org/core-concepts/
//
// alternative to main store state type, we can also force state type by define the object interface
export interface UserInfoState {
  first_name: string;
  last_name: string;
  picture_url?: string | null;
  mobileno: string | null;
  email: string;
  role_th: string | null;
  role_en: string | null;
  mfa_token?: string;
  mfa_status?: number; // 1 pending, 2 active
  mfa_modal?: boolean;
  credential_valid?: boolean;
}

export interface UserToken {
  access_token: string;
  refresh_token: string;
}

export const userInfoConstant: UserInfoState = {
  first_name: '',
  last_name: '',
  picture_url: '',
  mobileno: null,
  email: '',
  role_th: '',
  role_en: '',
  mfa_token: '',
  mfa_status: 0,
  mfa_modal: false,
  credential_valid: false,
};

interface AuthStoreState {
  userInfo: UserInfoState | null;
  access_token: string | null;
  refresh_token: string | null;
  isCreditChecked: boolean;
  getFullName: string;
  clearUserInfo: () => void;
  clearTokens: () => void;
  loadAuthInfoFromLocalStorage: () => void;
  setIsCreditChecked: (isFirstLogin: boolean) => void;
  setUserInfo: (userInfo: UserInfoState) => void;
  setTokens: (accessToken: string, refreshToken: string) => void;
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreState => ({
    userInfo: null,
    access_token: null,
    refresh_token: null,
    isCreditChecked: false,
    getFullName: '',
    clearUserInfo: () => {},
    clearTokens: () => {},
    loadAuthInfoFromLocalStorage: () => {},
    setIsCreditChecked: (isFirstLogin: boolean) => {},
    setUserInfo: (userInfo: UserInfoState) => {},
    setTokens: (accessToken: string, refreshToken: string) => {},
  }),
  getters: {
    getFullName: (state): string =>
      `${state.userInfo?.first_name ? state.userInfo.first_name : ''} ${state.userInfo?.last_name ? state.userInfo.last_name : ''}`,
  },
  actions: {
    loadAuthInfoFromLocalStorage() {
      const userInfoString = localStorage.getItem('app:authInfo');
      if (userInfoString) {
        this.$patch(JSON.parse(userInfoString));
      }
    },
    setUserInfo(userInfo: UserInfoState) {
      this.userInfo = userInfo;
      // localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
    },
    clearUserInfo() {
      this.userInfo = null;
      // localStorage.removeItem('userInfo');
    },
    setTokens(accessToken: string, refreshToken: string) {
      this.access_token = accessToken;
      this.refresh_token = refreshToken;
      // localStorage.setItem('access_token', accessToken);
      // localStorage.setItem('refresh_token', refreshToken);
    },
    clearTokens() {
      this.access_token = null;
      this.refresh_token = null;
      // localStorage.removeItem('access_token');
      // localStorage.removeItem('refresh_token');
    },
    setIsCreditChecked(isCreditCheck: boolean) {
      this.isCreditChecked = isCreditCheck;
      // localStorage.setItem('isCreditChecked', JSON.stringify(this.isCreditChecked));
    },
    logout() {
      // Clear user information and tokens
      this.clearUserInfo();
      this.clearTokens();
      // this.$reset();
      this.isCreditChecked = false;
      // localStorage.removeItem('isCreditChecked');
    },
  },
  persist: {
    key: 'app:authInfo',
    paths: ['userInfo', 'access_token', 'refresh_token', 'isCreditChecked'],
    storage: localStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
