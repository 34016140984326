import { acceptHMRUpdate, defineStore } from 'pinia';

interface BrandStoreState {
  currentBrand: Authentication.CurrentBrand | null;
  brandList: Authentication.RespBrandList[];
  brandRefSession: string | null;
  lastActiveBrand: string | null;
  isLoading: boolean;
  loadBrandInfoFromLocalStorage: () => void;
  getBrandRef: string;
  clearCurrentBrand: () => void;
  setCurrentBrand: (brand: Authentication.CurrentBrand) => void;
  setBrandSessionData: (brandRef: string) => void;
  setToken: (accessToken: string, refreshToken: string) => void;
  setBrandListNew: (brandList: Authentication.RespBrandList[]) => void;
}

const currentBrandConstant: Authentication.CurrentBrand = {
  brandRef: null,
  brandData: null,
  accessToken: null,
  refreshToken: null,
};

export const useBrandStore = defineStore('brand', {
  state: (): BrandStoreState => ({
    currentBrand: null,
    brandList: [],
    brandRefSession: null,
    lastActiveBrand: null,
    isLoading: false,
    loadBrandInfoFromLocalStorage: () => {},
    getBrandRef: '',
    clearCurrentBrand: () => {},
    setCurrentBrand: (brand: Authentication.CurrentBrand) => {},
    setBrandSessionData: (brandRef: string) => {},
    setToken: (accessToken: string, refreshToken: string) => {},
    setBrandListNew: (brandList: Authentication.RespBrandList[]) => {},
  }),
  getters: {
    getBrandRef: (state) => state.currentBrand?.brandRef || null,
    isLoading: (state) => state.isLoading,
  },
  actions: {
    loadBrandInfoFromLocalStorage() {
      const localBrandInfoString = localStorage.getItem('app:brandInfo');
      if (localBrandInfoString) {
        const parsedBrand = JSON.parse(localBrandInfoString) as BrandStoreState;
        this.$patch(parsedBrand);
      }
    },
    setCurrentBrand(brand: Authentication.CurrentBrand) {
      this.currentBrand = brand;
      // localStorage.setItem('brandData', JSON.stringify(brand));
    },
    setBrandListNew(brandList: Authentication.RespBrandList[]) {
      this.brandList = brandList;
    },
    setBrandSessionData(brandRef: string) {
      this.brandRefSession = brandRef;
      // localStorage.setItem('brandSessionData', brandRef);
    },
    setToken(accessToken: string, refreshToken: string) {
      if (this.currentBrand) {
        this.currentBrand.accessToken = accessToken;
        this.currentBrand.refreshToken = refreshToken;
      }
    },
    clearCurrentBrand() {
      this.currentBrand = currentBrandConstant;
      // localStorage.removeItem('brandData');
    },
    clearBrandList() {
      this.brandList = [];
      // localStorage.removeItem('brandData');
    },
    clearBrandRefSession() {
      this.brandRefSession = '';
      // localStorage.removeItem('brandSessionData');
    },
    // Last active brand
    setLastActiveBrand(brandRef: string) {
      this.lastActiveBrand = brandRef;
      sessionStorage.setItem('lastActiveBrand', JSON.stringify(brandRef));
    },
    clearLastActiveBrand() {
      sessionStorage.removeItem('lastActiveBrand');
    },
    setLoading(loading: boolean) {
      this.isLoading = loading;
    },
  },
  persist: {
    key: 'app:brandInfo',
    paths: ['currentBrand', 'brandList'],
    storage: localStorage,
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBrandStore, import.meta.hot));
}
