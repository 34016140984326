<template>
  <div class="forget-password-container">
    <div class="pic-forget-container"><img src="@assets/images/modules/authentication/pic-forgot-password.png" class="w-100" /></div>
    <div class="header-forget-container font-f2 text-center">
      <span>{{ t('authentication.forget_password.title') }}</span>
    </div>
    <div class="description-forget-container text-center">
      <span>
        {{ t('authentication.forget_password.description_first') }}
      </span>
      <br />
      <span>
        {{ t('authentication.forget_password.description_second') }}
      </span>
    </div>
    <div class="form-forget-container">
      <form @submit.prevent="onSendForget">
        <div :class="[isHaveEmail ? 'form-group' : 'form-group-error']">
          <input
            :class="[isHaveEmail ? 'form-control' : 'form-control-error']"
            :placeholder="t('authentication.forget_password.placeholder')"
            @input="onChangeEmail"
            v-model="forgetEmail"
            autocomplete="off"
          />
          <div class="icon d-flex align-items-center justify-content-center">
            <email-icon />
          </div>
          <template v-if="!isEmail && forgetEmail">
            <span class="text-error text-danger">{{ t('authentication.forget_password.error_text') }}</span>
            <br />
          </template>
          <div v-if="!isHaveEmail" class="not-have-text">
            <span class="text-error text-danger">{{ errorMessage }}</span>
          </div>
        </div>
        <div class="action-form">
          <button class="btn w-100" type="submit" :disabled="!isEmail">
            {{ isLoadingBtn ? '' : t('authentication.forget_password.button') }}
            <div class="spinner-border" role="status" v-if="isLoadingBtn">
              <span class="visually-hidden">{{ t('global.loading') }}</span>
            </div>
          </button>
        </div>
      </form>
      <div class="text-center mt-4">
        <router-link class="link-text-email font-f2" to="/login">{{ t('authentication.forget_password.back') }}</router-link>
      </div>
    </div>
  </div>
  <div class="footer-forget-container w-100 text-center"><img src="@assets/images/modules/authentication/footer-forgot-password.png" /></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import EmailIcon from '@/assets/icons/modules/login/Email.vue';
import useForgetPassword from './hooks/useForgetPassword';

const { t } = useI18n();

const { onSendForget, onChangeEmail, isEmail, isHaveEmail, forgetEmail, isLoadingBtn, errorMessage } = useForgetPassword();
</script>

<style lang="scss" scoped>
@import '@assets/styles/modules/authentication/forget-password.scss';
</style>
