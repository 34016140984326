<template>
  <div class="wrapper">
    <!-- Vertical Nav Menu -->
    <div id="layout-vertical">
      <vertical-nav-menu>
        <template #header="slotProps">
          <slot name="vertical-menu-header" v-bind="slotProps" />
        </template>
      </vertical-nav-menu>
      <!-- Navbar Header -->
      <div class="main-content">
        <nav class="navbar-top">
          <slot name="navbar-header">
            <app-nav-bar-vertical-layout />
          </slot>
        </nav>
        <!-- Content -->
        <transition mode="out-in">
          <component :is="layoutContentRenderer">
            <template v-for="(index, name) in slots" v-slot:[name]="data">
              <slot :name="name" />
            </template>
          </component>
        </transition>
      </div>
    </div>
    <!-- /Footer -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LayoutContentRenderer from '../components/layout-content-renderer/LayoutContentRenderer.vue';
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue';
import AppNavBarVerticalLayout from '../components/app-navbar/AppNavBarVerticalLayout.vue';

export default defineComponent({
  components: {
    VerticalNavMenu,
    LayoutContentRenderer,
    AppNavBarVerticalLayout,
  },
  computed: {
    layoutContentRenderer() {
      return 'layout-content-renderer';
    },
  },

  setup(props, { slots }) {
    return {
      slots,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/layout-vertical.scss';
</style>
