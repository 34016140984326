<template>
  <div class="noti-credit">
    <base-modal :size:="'xl'" class="modal-noti-credit" hide-footer hideHeader :is-modal-open="isModalOpen" @hide="onHideModal()">
      <template #modal-body>
        <div class="noti-modal-container">
          <div class="noti-modal-detail">
            <div class="noti-modal-body-top">
              <div class="noti-modal-title">{{ t('notification.noti_credit_modal.title') }}</div>
              <div class="noti-modal-picture">
                <img width="222" height="210" src="/base/noti-credit/notiPopup.png" />
              </div>
            </div>

            <div v-if="loading" class="noti-modal-body-bottom">
              <div class="noti-modal-bottom-title"><div class="loading-content-card-noti" /></div>
              <div>
                <card-noti :is-loading="loading" />
                <card-noti :is-loading="loading" />
              </div>
              <div class="noti-modal-bottom-title"><div class="loading-content-card-noti" /></div>
              <div>
                <card-noti :is-loading="loading" />
              </div>
            </div>

            <div v-else class="noti-modal-body-bottom">
              <div class="if-noti-modal-body" v-if="filter_listCardNotiData.length != 0">
                <div v-for="(item, index) in filter_listCardNotiData" v-bind:key="index">
                  <div class="noti-modal-bottom-title">{{ item.section }}</div>
                  <div>
                    <div v-for="(itemdata, indexdata) in item.noti_list" v-bind:key="indexdata">
                      <card-noti
                        :is-loading="loading"
                        @click-list="onClickDetailList(item.topic)"
                        :noti-type="item.topic"
                        :status="itemdata.icon == 'noti_danger' ? 1 : 2"
                      >
                        <template #content-channel> {{ `${itemdata.title}`.toUpperCase() }} </template>
                      </card-noti>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty-noti-modal-body" v-else><EmptyTableIcon /></div>
            </div>
            <div class="noti-modal-body-footer">
              <button class="see-more-button" @click="onClickDetailList(topicFromPage ? topicFromPage : showMenuMainPage[0])">
                <div class="text-seemore">{{ t('notification.noti_credit_modal.button') }}</div>
              </button>
            </div>
          </div>
          <div v-if="topicFromPage != null" class="modal-button">
            <div style="padding: 0.5rem 0" @click="onCloseModal(topicFromPage)"><span style="cursor: pointer">X</span></div>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive, Ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import api from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import notificationModel from '@/models/notification/notification';
import router from '@/router';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import cardNoti from './component/detailCreditNotiCard.vue';
import EmptyTableIcon from '@/assets/icons/base/table/EmptyTableIcon.vue';
import { useAuthStore } from '@/store/authStore';

interface Props {
  topicFromPage?: string;
  isModalOpen: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits(['hide', 'close-modal', 'open-modal']);
const authStore = useAuthStore();
const { t } = useI18n();
const { openSuccessModal, openErrorModal } = useValidationModal();
const { getAlertNotification, readAlert } = notificationModel();
const loading: Ref<boolean> = ref(true);
const listCardNotiData: Ref<any[]> = ref([]);
const filter_listCardNotiData: Ref<any[]> = ref([]);
const showMenuMainPage: Ref<any[]> = ref([]);

function onClickDetailList(notiType: string) {
  let showMenu = '';
  authStore.setIsCreditChecked(false);
  // localStorage.removeItem('firstLogin');
  if (props.topicFromPage != null) {
    if (listCardNotiData.value.length != 0) {
      checkReadByTopic(notiType);
    }
    switch (notiType) {
      case 'Commu_Credit':
        router.push({ path: '/my-account', query: { tab: 'Credit' } });
        break;
      case 'Storage':
        router.push({ path: '/my-account', query: { tab: 'Storage' } });
        break;
      default:
        break;
    }
  } else {
    showMenu = JSON.stringify(showMenuMainPage.value);
    switch (notiType) {
      case 'Commu_Credit':
        router.push({ path: '/notification-credit', query: { tab: 'Commu_Credit', menu: showMenu } });
        break;
      case 'Storage':
        router.push({ path: '/notification-credit', query: { tab: 'Storage', menu: showMenu } });
        break;
      default:
        break;
    }
  }

  // tab
}

function fetchData() {
  loading.value = true;
  listCardNotiData.value = [];
  filter_listCardNotiData.value = [];
  if (props.topicFromPage) {
    switch (props.topicFromPage) {
      case 'Commu_Credit':
        getAlertNotification.payload.Topic = ['Commu_Credit'];
        break;
      case 'Storage':
        getAlertNotification.payload.Topic = ['Storage'];
        break;
    }
  } else {
    getAlertNotification.payload.Topic = ['*'];
  }

  return api
    .apiRequest(getAlertNotification)
    .then((response) => {
      setTimeout(() => {
        if (response.data.length == 0) {
          onCloseModal(props.topicFromPage!);
        } else {
          response.data.forEach((value: any) => {
            showMenuMainPage.value.push(value.topic);
          });
          onOpenModal();
          listCardNotiData.value = response.data;
          filter_listCardNotiData.value = listCardNotiData.value;
        }
      }, 3000);
    })
    .catch((error) => {
      // openErrorModal('GetListTable : ' + String(error.data.error.locale['th-th'].title), String(error.data.error.locale['th-th'].message));
    })
    .finally(() => {
      loading.value = false;
    });
}
function checkReadByTopic(topic: string) {
  let topicFromPageForRead: any = [];
  topicFromPageForRead.push(topic);
  readAlert.payload.Topic = topicFromPageForRead;

  return api
    .apiRequest(readAlert)
    .then((response) => {})
    .catch((error) => {
      // openErrorModal('GetListTable : ' + String(error.data.error.locale['th-th'].title), String(error.data.error.locale['th-th'].message));
    })
    .finally(() => {});
}
function onCloseModal(topic: string) {
  if (listCardNotiData.value.length != 0) {
    checkReadByTopic(topic);
  }
  emits('close-modal');
}
function onOpenModal() {
  emits('open-modal');
}
function onHideModal() {
  emits('hide');
}

// function onModalOpen() {
//   fetchData();
// }
onMounted(() => {
  if (authStore.isCreditChecked == true) {
    fetchData();
  } else {
    if (props.topicFromPage) {
      fetchData();
    }
  }
});
</script>
<style lang="scss" scoped>
.modal-button {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 40px;
  background-color: transparent !important;
  border-color: transparent;
  color: #6a6a6a;
  font-weight: 500;
  font-size: large;
  &:hover {
    color: #0a0a0a;
  }
}
.modal-noti-credit {
  :deep(.modal-inner) {
    max-width: 40rem !important;
    border-radius: 20px !important;
    overflow: auto !important;
    width: 100% !important;
  }

  :deep(.modal-body) {
    padding: 0 !important;
    margin-right: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
  }
}
.noti-modal-container {
  position: relative;
  .noti-modal-detail {
    padding: 0rem 4rem 2rem 4rem;

    .noti-modal-body-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .noti-modal-title {
        /* UI Properties */
        text-align: center;
        font: normal normal normal 34px/74px Prompt;
        color: #000000;
      }
    }
    .noti-modal-body-bottom {
      height: 395px;
      max-height: 395px;
      overflow-y: auto;
      margin-bottom: 1rem;
      .empty-noti-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      .loading-content-card-noti {
        height: 1.5rem;
        border-radius: 20px;
        width: 8rem;
        animation: colorChangeCredit 1s infinite alternate;
      }
      .noti-modal-bottom-title {
        /* UI Properties */
        text-align: left;
        font: normal normal 500 20px/17px Prompt;
        color: #000000;
        margin-bottom: 10px;

        margin-top: 1rem;
      }
      @keyframes colorChangeCredit {
        0% {
          background-color: #e2e2e225;
        }
        100% {
          background-color: #00204121;
        }
      }
    }

    .noti-modal-body-footer {
      display: flex;
      justify-content: center;
      min-height: 70px;
      width: 100%;
      .see-more-button {
        background: transparent linear-gradient(270deg, #0062ff 0%, #00baff 100%) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        width: 420px;
        border: none;
        .text-seemore {
          text-align: left;
          font: normal normal 500 26px/46px Prompt;
          color: #ffffff;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>
