import { Permission } from '@/types/modules/permission/permission';

export default function () {
  const confirmPasswordModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/account/CheckLogin`,
    method: 'POST',
    payload: {
      password: '',
    },
  };

  const fetchProfileDetailModel: API.RequestModel<Permission.ProfileDetail.Request.Payload> = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/ProfileDetail`,
    method: 'GET',
    payload: {
      Identity_SRef: null,
    },
  };

  const fetchProfileEditModel: API.RequestModel<Permission.ProfileEdit.Request.Payload> = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/ProfileEdit`,
    method: 'POST',
    payload: {
      AccountId: null,
      FirstName: null,
      LastName: null,
      MobileNo: null,
      PictureUrl: null,
      Status: null,
      Role_Key: null,
    },
  };

  const fetchUserListModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/UserList`,
    method: 'POST',
    payload: {},
  };

  const fetchRoleListModel: API.RequestModel<Permission.RoleList.Request.Payload> = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/RoleList`,
    method: 'POST',
    payload: {
      Page: 0,
      Limit: 0,
      Query: null,
      Role: null,
      Status: null,
    },
  };

  const fetchRoleDetailModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/RoleDetail`,
    method: 'POST',
    payload: {
      Role_key: '',
      Brand_Ref: '',
    },
  };

  const fetchUserInviteModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/UserInvite`,
    method: 'POST',
    payload: {
      Invite_List: [],
    },
  };

  const fetchUserResendModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/UserResend`,
    method: 'POST',
    payload: {
      AccountId: null,
    },
  };

  const fetchUserUnsendModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/UserUnsend`,
    method: 'POST',
    payload: {
      AccountId: null,
    },
  };

  const fetchUserDeleteModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/UserDelete`,
    method: 'POST',
    payload: {
      AccountId: null,
    },
  };

  const fetchResetPasswordModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Account/ResetPassword`,
    method: 'POST',
    payload: {},
  };
  const fetchPermissionKeyInfo: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/KeyInfo`,
    method: 'GET',
    payload: {},
  };

  const fetchVerifyAccount: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/VerifyAccount`,
    method: 'POST',
    payload: {
      Brand_Ref: '',
      Invite_Ref: '',
    },
  };

  const fetchInitProfileModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE!}/Permission/InitProfile`,
    method: 'POST',
    payload: {},
  };

  const fetchGetActionModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/Activity/GetAction`,
    method: 'GET',
    payload: {},
  };

  const fetchGetModuleModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/Activity/GetModule`,
    method: 'GET',
    payload: {},
  };

  const fetchActivityLogModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/Activity/ActivityLog`,
    method: 'POST',
    payload: {},
  };

  const fetchGetLastActivityGroupByEmailsModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/Activity/LastActivity`,
    method: 'POST',
    payload: {},
  };

  const fetchExportActivityLogModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_BACKEND!}/Activity/ExportActivityLog`,
    method: 'POST',
    payload: {},
  };

  const uploadFileModel: API.RequestModel<{
    File: File;
    Is_Temp: boolean;
    Options: {
      Gen_preview?: boolean;
    };
    Path?: string;
    Brand_Ref?: string;
  }> = {
    endpoint: `${import.meta.env.VITE_BASE_URL_FILE_SERVICE!}/File/AddAndShareByPassToken`,
    method: 'POST',
    payload: {
      File: null!,
      Is_Temp: null!,
      Options: {},
      Path: null!,
      Brand_Ref: null!,
    },
  };

  return {
    confirmPasswordModel,
    fetchProfileEditModel,
    fetchProfileDetailModel,
    fetchUserListModel,
    fetchRoleListModel,
    fetchRoleDetailModel,
    fetchUserInviteModel,
    fetchUserResendModel,
    fetchUserUnsendModel,
    fetchUserDeleteModel,
    fetchResetPasswordModel,
    fetchPermissionKeyInfo,
    fetchVerifyAccount,
    fetchInitProfileModel,
    uploadFileModel,
    fetchGetActionModel,
    fetchGetModuleModel,
    fetchActivityLogModel,
    fetchGetLastActivityGroupByEmailsModel,
    fetchExportActivityLogModel,
  };
}
