import { Ref, ref, reactive, watch, onBeforeMount } from 'vue';
import { useSettingStore } from '@/store/settingStore';
import { useBrandStore } from '@/store/brandStore';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import router from '@/router';

// import api from '@/services/api';
// import loginModel from '@/models/authentication/login';
import brandService from '@/services/brand';
import { useAuthStore } from '@/store/authStore';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import ability from '@/libs/acl/ability';

export default function useMenuBar() {
  const { t } = useI18n();

  const settingStore = useSettingStore();
  const { currentLocale } = storeToRefs(settingStore);

  const store = useBrandStore();
  const { brandList } = storeToRefs(store);

  const { openErrorModal } = useValidationModal();

  const brandSelected: Record<string, any> = ref(null);

  const loading: Ref<boolean> = ref(false);

  const modalType = reactive({
    changeBrand: false,
  });

  const menuList = ref([
    {
      label: 'brand_account',
      icon: 'BrandAccountIcon',
      action: () => onBrandAccount(),
      disable: false,
      hide: false,
    },
    {
      label: 'permission',
      icon: 'PermissionIcon',
      action: () => openPagePermission(),
      disable: false,
      hide: false,
    },
    {
      label: 'change_role',
      icon: 'ChangeRoleIcon',
      action: () => {},
      disable: true,
      hide: false,
    },
    // {
    //   label: 'manage_storage',
    //   icon: 'ManageStorageIcon',
    //   action: () => onStorage(),
    //   disable: false,
    //   hide: false,
    // },
    {
      label: 'configuration',
      icon: 'ConfigurationIcon',
      action: () => {},
      disable: true,
      hide: true,
    },
  ]);

  const openPageChangePassword = () => {
    loading.value = true;

    router.push({ path: '/check-old-password' });
  };

  const openPagePermission = () => {
    loading.value = true;

    router.push({ path: '/permission/profile' });
  };

  const openChangeBrandModal = () => {
    modalType.changeBrand = true;
  };

  const closeChangeBrandModal = () => {
    modalType.changeBrand = false;
  };

  const handlerSubmitSuccess = (resp: Record<string, any>) => {
    const brandStore = useBrandStore();
    const authStore = useAuthStore();
    brandStore.clearCurrentBrand();

    const tempBrand: Authentication.CurrentBrand = {
      brandRef: resp.brand_ref,
      brandData: brandSelected.value,
      accessToken: resp.access_token,
      refreshToken: resp.refresh_token,
    };
    const userInfo = {
      first_name: resp.first_name,
      last_name: resp.last_name,
      picture_url: resp.picture_url,
      email: resp.email,
      role_en: resp.role_en,
      role_th: resp.role_th,
      mobileno: resp.mobileno || null,
    };
    // localStorage.setItem('userInfo', JSON.stringify(userInfo));
    // localStorage.setItem('firstLogin', 'true');
    // authStore.setIsCreditChecked(true);
    authStore.setUserInfo(userInfo);
    brandStore.setCurrentBrand(tempBrand);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    window.location.href = `/analytics/dashboard?brandRef=${resp.brand_ref}`;
  };

  const onBrandAccount = () => {
    router.push({
      path: '/my-account',
    });
  };

  const onSubmitBrand = async () => {
    if (!brandSelected.value) return;
    loading.value = true;
    const access_token = useAuthStore().access_token as string;
    const brand_ref = brandSelected.value.brand_ref as string;

    try {
      const submitBrandResp = await brandService.fetchSubmitBrand(access_token, brand_ref);
      if (submitBrandResp) {
        useBrandStore().setBrandSessionData(submitBrandResp.brand_ref);
        sessionStorage.removeItem('segment_source_detail');
        handlerSubmitSuccess(submitBrandResp);
        modalType.changeBrand = false;
        loading.value = false;
      }
    } catch (e) {
      openErrorModal(t('change_brand_modal.error'), t('change_brand_modal.error_description'));
    }
  };

  const onSwitchLanguage = () => {
    if (currentLocale.value == 'th') {
      useSettingStore().changeLocale('en-US');
    } else {
      useSettingStore().changeLocale('th');
    }
    location.reload();
  };

  watch(
    () => brandList.value,
    () => {
      if (brandList.value.length > 1) {
        menuList.value.splice(1, 0, {
          label: 'change_brand_account',
          icon: 'ChangeBrandAccountIcon',
          action: () => openChangeBrandModal(),
          disable: false,
          hide: false,
        });
      }
    },
    { immediate: true },
  );

  onBeforeMount(() => {
    if (ability.can('portal-cdp', 'cdp-portal-password-change')) {
      menuList.value.push({
        label: 'change_password',
        icon: 'PermissionIcon',
        action: () => openPageChangePassword(),
        disable: false,
        hide: false,
      });
    }
    menuList.value.push({
      label: 'switchlanguage',
      icon: 'LanguageIcon',
      action: () => onSwitchLanguage(),
      disable: false,
      hide: false,
    });
  });

  return {
    loading,
    menuList,
    brandList,
    brandSelected,
    modalType,
    currentLocale,
    onSubmitBrand,
    closeChangeBrandModal,
    onSwitchLanguage,
  };
}
