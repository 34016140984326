<template>
  <div class="d-flex align-items-center w-100">
    <div class="p-2 flex-grow-1">
      <app-bread-crumb />
    </div>
    <ul class="nav justify-content-end align-items-center">
      <li class="nav-item">
        <user-info />
      </li>
      <li class="nav-item">
        <noti-bell />
      </li>
      <li class="nav-item">
        <menu-bar />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UserInfo from './components/UserInfo.vue';
import MenuBar from './components/MenuBar.vue';
import AppBreadCrumb from '../AppBreadCrumb.vue';
import NotiBell from './components/NotiBell.vue';

export default defineComponent({
  components: {
    AppBreadCrumb,
    MenuBar,
    UserInfo,
    NotiBell,
  },
  setup() {},
});
</script>
