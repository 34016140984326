<template>
  <div class="d-flex align-items-center">
    <div class="info-container">
      <div>{{ userRole }}</div>
      <div>{{ authStore.getFullName }}</div>
    </div>
    <img
      v-if="userInfo?.picture_url"
      :src="userInfo?.picture_url == 'DefaultProfile' ? DefaultProfile : userInfo?.picture_url"
      class="img-container"
      alt="profile"
    />
    <div v-else class="navbar-avatar">
      {{ 'A' }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/authStore';

import DefaultProfile from '@/assets/icons/base/default-profile.png';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { userInfo } = authStore;
const { t, locale } = useI18n();
const userRole = locale.value == 'th' ? userInfo?.role_th : userInfo?.role_en;
</script>

<style lang="scss" scoped>
@import '@assets/styles/base/horizontal-header-bar.scss';
</style>
