import { Directive, DirectiveBinding } from 'vue';

const vPermission: Directive = {
  mounted: (el, binding) => {
    if (!binding.value) {
      el.style.display = 'none'; // ซ่อนแทนการลบ เพื่อลด error ที่อาจเกิดขึ้น
    }
  },
};


export default vPermission;
