export default function loginModel() {
  const fetchLoginModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/login`,
    method: 'POST',
    payload: {
      username: '',
      password: '',
      brand_ref: null,
    },
  };

  const fetchLogoutModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/logout`,
    method: 'POST',
    payload: {
      access_token: null,
    },
  };

  const fetchRefreshTokenModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/refreshToken`,
    method: 'POST',
    payload: {
      access_token: null,
      refresh_token: null,
    },
  };

  const fetchBrandListModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/GetBrandList`,
    method: 'POST',
    payload: {
      access_token: null,
    },
  };

  const fetchSubmitBrandModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/submitBrand`,
    method: 'POST',
    payload: {
      access_token: null,
      brand_ref: null,
    },
  };

  const fetchResetPasswordModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/ResetPassword`,
    method: 'POST',
    payload: {
      password: '',
    },
  };

  const fetchSendEmailToResetPass: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/SendEmailToResetPass`,
    method: 'POST',
    payload: {
      Email: null,
    },
  };

  const fetchResetForgetPassword: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/ResetForgetPassword`,
    method: 'POST',
    payload: {
      tx_Reference: null,
      Token: null,
      New_Password: null,
    },
  };

  const fetchVerifyTokenToResetPass: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/VerifyTokenToResetPass`,
    method: 'POST',
    payload: {
      tx_Reference: null,
      Token: null,
    },
  };

  const fetchAllModuleVersions: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/ConfigBrand/GetAllModuleVersions`,
    method: 'GET',
    payload: {},
  };

  const fetchChangePasswordModel: API.RequestModel = {
    endpoint: `${import.meta.env.VITE_BASE_URL_CDP_CORE}/account/ChangePassword`,
    method: 'POST',
    payload: {
      Username: '',
      Password: '',
      Token: '',
    },
  };

  return {
    fetchLoginModel,
    fetchLogoutModel,
    fetchRefreshTokenModel,
    fetchBrandListModel,
    fetchSubmitBrandModel,
    fetchResetPasswordModel,
    fetchSendEmailToResetPass,
    fetchResetForgetPassword,
    fetchVerifyTokenToResetPass,
    fetchAllModuleVersions,
    fetchChangePasswordModel,
  };
}
