import { useModuleStore } from '@/store/moduleStore';
import apiService from '@/services/api';
import loginModel from '@/models/authentication/login';

class ModuleService {
  private get moduleStore() {
    return useModuleStore();
  }

  async fetchModuleVersions(): Promise<CDPModule.ModuleVersion> {
    try {
      const { fetchAllModuleVersions } = loginModel();
      const versions: Partial<CDPModule.ModuleVersion> = {};
      const response = await apiService.apiRequest(fetchAllModuleVersions);
      response.data.module_versions.forEach(({ module_name, version }: { module_name: string; version: string }) => {
        const moduleName = module_name as keyof CDPModule.ModuleVersion;
        versions[moduleName] = version;
      });
      return versions as CDPModule.ModuleVersion;
    } catch (e) {
      throw e;
    }
  }

  async fetchAndSetModuleVersions() {
    const moduleVersions = await this.fetchModuleVersions();
    this.moduleStore.setModuleVersions(moduleVersions);
  }

  hasModuleVersionFromLocalStorage(): boolean {
    const localModuleVersion = localStorage.getItem('app:moduleVersion');
    if (localModuleVersion) {
      const paredVersions = JSON.parse(localModuleVersion)?.moduleVersion;
      if (paredVersions && paredVersions !== null) {
        return true;
      }
    }
    return false;
  }
}

const moduleService = new ModuleService();
export default moduleService;
