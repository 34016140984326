import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';

// Lazy load components
const ChannelPage = () => import('@/views/modules/communication/pages/main/ChannelPage.vue');
const CreateSmsCampaignPage = () => import('@/views/modules/communication/pages/sms-campaign/CreateSmsCampaignPage.vue');
const EditSmsCampaignPage = () => import('@/views/modules/communication/pages/sms-campaign/EditSmsCampaignPage.vue');
const AllTemplateList = () => import('@/views/modules/communication/pages/all-template/AllTemplateList.vue');
const LineOACreateCampaignPage = () => import('@/views/modules/communication/pages/line-oa-campaign/LineOACreateCampaignPage.vue');
const LineOAEditCampaignPage = () => import('@/views/modules/communication/pages/line-oa-campaign/LineOAEditCampaignPage.vue');
const LineOACreateTemplate = () => import('@/views/modules/communication/pages/line-oa-template/CreateTemplate.vue');
const LineOAEditTemplate = () => import('@/views/modules/communication/pages/line-oa-template/EditTemplate.vue');
const CreateEDMCampaignPage = () => import('@/views/modules/communication/pages/edm-campaign/CreateEDMCampaignPage.vue');
const EditEDMCampaignPage = () => import('@/views/modules/communication/pages/edm-campaign/EditEDMCampaignPage.vue');
const EdmCreateTemplatePage = () => import('@/views/modules/communication/pages/edm-template/CreateEdmTemplate.vue');
const EdmEditTemplatePage = () => import('@/views/modules/communication/pages/edm-template/EditEdmTemplate.vue');
const EdmDashboardByCampaign = () => import('@/views/modules/communication/pages/main/components/edm-campaign-dashboard/EdmDashboardByCampaign.vue');
const LineOaDashboardByCampaign = () =>
  import('@/views/modules/communication/pages/main/components/line-oa-campaign-dashboard/LineOaDashboardByCampaign.vue');
const EdmDashboardExportPreviewVue = () =>
  import('@/views/modules/communication/pages/main/components/edm-campaign-dashboard/dashboard/EdmDashboardExportPreviewVue.vue');
const SmsDashboardExportPreviewVue = () => import('@/views/modules/communication/pages/sms-dashboard/SmsDashboardExportPreviewVue.vue');
const LineOaDashboardExportPreviewVue = () => import('@/views/modules/communication/pages/line-oa-dashboard/LineOaDashboardExportPreviewVue.vue');
const AllDashboardExportPreviewVue = () =>
  import('@/views/modules/communication/pages/main/components/all-channel-tab/dashboard-all-channel/AllDashboardExportPreviewVue.vue');

const SMSByCampaign = () => import('@/views/modules/communication/pages/main/components/sms-campaign-dashboard/SMSByCampaign.vue');
//template
const CreateSmsTemplate = () => import('@/views/modules/communication/pages/sms-template/CreateSmsTemplate.vue');
const EditSmsTemplate = () => import('@/views/modules/communication/pages/sms-template/EditSmsTemplate.vue');

const MainResumeCampaign = () => import('@/views/modules/communication/pages/resume-campaign/MainResumeCampaignPage.vue');
//Limitation
const LimitationPage = () => import('@/views/modules/communication/pages/main/LimitationPage.vue');

const communicationBreadcrumb = [
  {
    text: 'Communication',
  },
];

const meta = {
  moduleName: moduleName.Communication,
  moduleVersion: '1.0.0',
};

const communicationRoutes: Array<CustomRouteRecord> = [
  {
    path: '/dashboard',
    name: 'dash-board',
    component: () => import('@/views/modules/unauthorized/Unauthorized.vue'),
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'communication.dashboard',
        active: true,
      },
    ],
  },
  {
    path: '/communication',
    name: 'communication',
    component: ChannelPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
      },
    ],
  },
  {
    path: '/communication/limitation',
    name: 'Limitation',
    component: LimitationPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'main_menu.communication_limitation',
        active: true,
      },
    ],
  },

  {
    path: '/communication/template/list',
    name: 'all-template',
    component: AllTemplateList,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
      },
    ],
  },
  {
    path: '/coupon-code-management',
    name: 'coupon-code-management',
    component: () => import('@/views/modules/unauthorized/Unauthorized.vue'),
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Coupon Code Management',
        active: true,
      },
    ],
  },
  //#region sms
  {
    path: '/communication/sms/create-campaign/:id',
    name: 'create-sms-campaign',
    component: CreateSmsCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'communication.create_campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/edit-campaign/:id',
    name: 'edit-sms-campaign',
    component: EditSmsCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'edit_campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/template/create',
    name: 'sms-create-template',
    component: CreateSmsTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Create Template',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/template/info/:id',
    name: 'sms-edit-template',
    component: EditSmsTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'Edit Template',
        active: true,
      },
    ],
  },
  {
    path: '/communication/sms/dashboard-by-campaign/:id',
    name: 'sms-dashboard-by-campaign',
    component: SMSByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'SMS',
        active: true,
        to: '/communication',
        query: { channel: 'sms' },
      },
      {
        text: 'campaign_list',
        active: true,
        to: '/communication',
        query: { channel: 'sms', tab: 2 },
      },
      {
        text: 'communication.dashboard',
      },
    ],
  },
  //#endregion sms

  //#region line-oa
  {
    path: '/communication/line-oa/campaign/create',
    name: 'line-oa-campaign-create',
    component: LineOACreateCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Line',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Campaign',
      },
      {
        text: 'Create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/campaign/edit',
    name: 'line-oa-campaign-info',
    component: LineOAEditCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'Campaign',
      },
      {
        text: 'campaign_info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/template/create',
    name: 'line-oa-template-create',
    component: LineOACreateTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'communication.template',
      },
      {
        text: 'communication.create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/template/info/:id',
    name: 'line-oa-template-info',
    component: LineOAEditTemplate,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Line',
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'communication.template',
      },
      {
        text: 'Info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/line-oa/dashboard-by-campaign/:id',
    name: 'line-oa-dashboard-by-campaign',
    component: LineOaDashboardByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'Line',
        active: true,
        to: '/communication',
        query: { channel: 'line-oa' },
      },
      {
        text: 'campaign_list',
        active: true,
        to: '/communication',
        query: { channel: 'line-oa', tab: 2 },
      },
      {
        text: 'communication.dashboard',
      },
    ],
  },
  //#endregion line-oa

  //region email
  {
    path: '/communication/email/create-campaign/:id',
    name: 'create-edm-campaign',
    component: CreateEDMCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'communication.create_campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/edit-campaign/:id',
    name: 'edit-edm-campaign',
    component: EditEDMCampaignPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...communicationBreadcrumb,
      {
        text: 'Channel',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'edit_campaign',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/template/create',
    name: 'email-template-create',
    component: EdmCreateTemplatePage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Email',
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'communication.template',
      },
      {
        text: 'communication.create',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/template/info/:id',
    name: 'email-template-info',
    component: EdmEditTemplatePage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        to: '/communication',
      },
      {
        text: 'Channel',
        to: '/communication',
      },
      {
        text: 'Email',
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'communication.template',
      },
      {
        text: 'communication.info',
        active: true,
      },
    ],
  },
  {
    path: '/communication/email/dashboard-by-campaign/:id',
    name: 'edm-dashboard-by-campaign',
    component: EdmDashboardByCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'Communication',
        active: true,
      },
      {
        text: 'Email',
        active: true,
        to: '/communication',
        query: { channel: 'email' },
      },
      {
        text: 'campaign_list',
        active: true,
        to: '/communication',
        query: { channel: 'email', tab: 2 },
      },
      {
        text: 'communication.dashboard',
      },
    ],
  },
  //#endregion email
  {
    path: '/communication/all-export-preview',
    name: 'all-export-preview',
    component: AllDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'communication.dashboard',
      },
    ],
  },
  {
    path: '/communication/edm-export-preview',
    name: 'edm-export-preview',
    component: EdmDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'communication.dashboard',
      },
    ],
  },
  {
    path: '/communication/sms-export-preview',
    name: 'sms-export-preview',
    component: SmsDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'communication.dashboard',
      },
    ],
  },
  {
    path: '/communication/line-export-preview',
    name: 'line-export-preview',
    component: LineOaDashboardExportPreviewVue,
    meta: {
      ...meta,
      layout: 'full',
    },
    breadcrumb: [
      {
        text: 'communication.dashboard',
      },
    ],
  },
  {
    path: '/communication/resume-campaign',
    name: 'resume-campaign',
    component: MainResumeCampaign,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'communication.communication', //communication
        active: true,
        to: '/communication',
      },
      {
        text: 'communication.campaign_list_paused', //Not enough credit Campaigns
      },
    ],
  },
];

export default communicationRoutes;
