<template>
  <div v-if="!isSessionChecked" class="h-100">
    <!-- Loading Indicator -->
    <app-loading />
  </div>
  <div v-else class="h-100">
    <component :is="layout" class="w-100 h-100">
      <router-view />
    </component>
    <validation-modal />
    <page-loading />
    <confirm-welcome-modal />
    <authenticator-modal />
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/authStore';
import permissionService from '@/services/permission';
import { ref, computed, onBeforeMount, onBeforeUnmount, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';

// Import your stores and services
import { useBrandStore } from '@/store/brandStore';
import { useLoadingStore } from './store/loadingStore';
import { usePermissionStore } from './store/permissionStore';
import { useModuleStore } from './store/moduleStore';

import ValidationModal from './views/components/modal/ValidationModal.vue';
import AuthenticatorModal from '@/views/components/modal/MFAModal.vue';

import LayoutVertical from './views/layouts/vertical/LayoutVertical.vue';
import PageLoading from './views/components/loading/PageLoading.vue';
import AppLoading from './views/components/loading/AppLoading.vue';
import ConfirmWelcomeModal from '@/views/modules/permission/components/permission-modal/ConfirmWelcomeModal.vue';

import brandService from '@/services/brand';
import authService from '@/services/authentication';
import moduleService from '@/services/module';
import { signalRService } from '@/services/signalR';

const brandStore = useBrandStore();
const loadingStore = useLoadingStore();
const permissionStore = usePermissionStore();
const moduleStore = useModuleStore();
const authStore = useAuthStore();

const { appLoadingConfig } = storeToRefs(loadingStore);

const layout = computed(() => {
  if (router.currentRoute.value.meta.layout === 'full') return 'layout-full';
  return LayoutVertical;
});

// Reactive variable to control rendering
const isSessionChecked = ref(false);

const initializeStore = () => {
  // Load data from Local
  authStore.loadAuthInfoFromLocalStorage();
  brandStore.loadBrandInfoFromLocalStorage();
  moduleStore.loadModuleVersionsFromLocalStorage();
};

const initialAppState = async () => {
  initializeStore();

  try {
    // Fetch Module Version
    // Fetch and assign permissions
    await permissionService.fetchAndSetPermissions();
    await moduleService.fetchAndSetModuleVersions();
    // Fetch brand list
    const accessToken = authStore.access_token;
    if (!accessToken) throw new Error('Access token not set');
    const success = await brandService.fetchBrandListAndSet(accessToken);
    if (!success) throw new Error('Brand list not found');
    // Start SignalR service
    // await signalRService.start();
  } catch (error) {
    // If an error occurs, redirect to login
    console.error('Error during session check:', error);
    await authService.logout();
  } finally {
    // Session check complete
    isSessionChecked.value = true;
    appLoadingConfig.value.isOpen = false;
  }
};

onBeforeMount(async () => {
  const brandRefQuery = brandService.getBrandRefParams();
  if (authService.isUserLoggedIn()) {
    if (brandRefQuery && brandStore.getBrandRef !== brandRefQuery) {
      const success = await brandService.fetchSubmitBrandAndSet(brandRefQuery);
      // await new Promise((resolve) => setTimeout(resolve, 300));
      if (success) {
        await initialAppState();
      }
    } else {
      // initial normal flow
      await initialAppState();
    }
  } else {
    // User is not logged in, redirect to login
    appLoadingConfig.value.isOpen = false;

    await new Promise((resolve) => setTimeout(resolve, 500));
    isSessionChecked.value = true;
  }
});

onBeforeUnmount(() => {
  signalRService.removeVisibilityChangeListener();
});
onUnmounted(() => {
  signalRService.disconnect();
  permissionStore.$reset();
});
</script>
<style lang="scss">
#app {
  font-family: 'Prompt', Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// Hide eye close and open input type password
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
</style>
