import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';
//Source
const MainPage = () => import('@/views/modules/data-connect/pages/mainPage/MainPage.vue');
const AddDataSourcePage = () => import('@/views/modules/data-connect/pages/addDataSourcePage/AddDataSourcePage.vue');
const AddDataSourceImportExcelPage = () => import('@/views/modules/data-connect/pages/addDataSourceImportExcelPage/AddDataSourceImportExcelPage.vue');
const SettingFasthelp5Page = () => import('@/views/modules/data-connect/pages/settingFasthelp5/SettingFasthelp5Page.vue');
// DataRelationship
const DataRelationship = () => import('@/views/modules/data-connect/pages/data-relationship/MainDataRelation.vue');
const OverallDiagram = () => import('@/views/modules/data-connect/pages/data-relationship/OverallDiagram.vue');

// Coupon
const CouponPage = () => import('@/views/modules/data-connect/pages/CouponManagement/CouponManagementPage.vue');
const CheckCouponList = () => import('@/views/modules/data-connect/pages/CouponManagement/CheckCouponList.vue');
const DetailListPage = () => import('@/views/modules/data-connect/pages/CouponManagement/DetailListCoupon.vue');

//History
const HistoryPage = () => import('@/views/modules/data-connect/pages/fileHistoryPage/FileHistoryPage.vue');

const dataConnectorBreadcrumb = [
  {
    text: 'data_connector.tab_data_connector',
    to: '/dataConnector',
  },
];

const meta = {
  moduleName: moduleName.DataConnector,
  moduleVersion: '1.0.0',
};

const metaSegmentV2 = {
  moduleName: moduleName.Segment,
  moduleVersion: '2.0.0',
};

const dataConnectRoutes: Array<CustomRouteRecord> = [
  {
    path: '/dataConnector',
    name: 'dataConnector',
    component: MainPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'data_connector.tab_data_connector',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_data_source_list',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/addDataSource',
    name: 'addDataSource',
    component: AddDataSourcePage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_data_source_list',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_add_datasource',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/addDataSource/settingFasthelp5',
    name: 'settingFasthelp5',
    component: SettingFasthelp5Page,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_data_source_list',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_add_datasource',
        to: '/dataConnector/addDataSource',
      },
      {
        text: 'data_connector.tab_Setting_fasthelp5_Integration',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/addDataSource/excelDataSource',
    name: 'excelDataSource',
    component: AddDataSourceImportExcelPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_data_source_list',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_add_datasource',
        to: '/dataConnector/addDataSource',
      },
      {
        text: 'data_connector.tab_excel_datasource',
        active: true,
      },
    ],
  },

  //Data Relationship
  {
    path: '/dataConnector/dataRelationship',
    name: 'dataRelationship',
    component: DataRelationship,
    meta: {
      ...metaSegmentV2,
      redirectNotMatchVersion: 'dataConnector',
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'dataRelation.data_relationship',
        to: '/dataConnector/dataRelationship',
      },
      {
        text: 'dataRelation.relationship_overall',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/dataRelationship/overallDiagram',
    name: 'overallDiagram',
    component: OverallDiagram,
    meta: {
      ...metaSegmentV2,
      redirectNotMatchVersion: 'dataConnector',
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'dataRelation.data_relationship',
        to: '/dataConnector/dataRelationship',
      },
      {
        text: 'dataRelation.overall_diagram',
        active: true,
      },
    ],
  },
  //coupon
  {
    path: '/dataConnector/couponManagement',
    name: 'couponManagement',
    component: CouponPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_coupon_management',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/checkCouponList',
    name: 'checkCouponList',
    component: CheckCouponList,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_coupon_management',
        active: true,
      },
    ],
  },
  {
    path: '/dataConnector/detailListCoupon',
    name: 'detailListCoupon',
    component: DetailListPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      ...dataConnectorBreadcrumb,
      {
        text: 'data_connector.tab_coupon_management',
        to: '/dataConnector/couponManagement',
      },
      {
        text: 'data_connector.tab_coupon_detail',
        active: true,
      },
    ],
  },
  // History
  {
    path: '/dataConnector/excelFileHistory',
    name: 'fileHistory',
    component: HistoryPage,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'data_connector.tab_data_connector',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_data_source_list',
        to: '/dataConnector',
      },
      {
        text: 'data_connector.tab_file_history',
        active: true,
      },
    ],
  },
];

export default dataConnectRoutes;
