// import axios, { AxiosInstance } from 'axios';
import router from '@/router';
import { isTokenValid } from '@/utils/token';

import axios, { AxiosInstance } from 'axios';
import { useAuthStore } from '@/store/authStore';
import authService from '@/services/authentication';
import { throttle } from 'lodash-es';
import { jwtDecode, JwtPayload } from 'jwt-decode';

// Lazy-load the auth store to ensure Pinia is initialized
const getAuthStore = () => useAuthStore();

// Create Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.BASE_URL || '',
});

let isLoggingOut = false; // Flag to prevent infinite logout loop

// Throttled token refresh
export const fetchThrottledTokenRefresh = throttle(async () => {
  const now = Math.floor(Date.now() / 1000);

  const accessToken = getAuthStore().access_token;

  if (!accessToken) {
    console.warn('Missing access or refresh token. Skipping token renewal.');
    return;
  }

  const tokenDecode = jwtDecode<JwtPayload>(accessToken);
  const exp = tokenDecode.exp; // รับค่า exp จาก tokenDecode

  if (!exp) {
    console.error('Token does not have an expiration time. Logging out.');
    return; // Handle logout scenario if required
  }

  if (now > exp) {
    console.warn('Token expired. Redirecting to login.');
    return router.push('/login'); // Redirect to login or logout the user here // ถ้าเลยเวลาหมดอายุแล้ว ให้ไปหน้า Login ใหม่
  }

  // ถ้ายังไม่เลยเวลาให้ลบ 1 ชั่วโมง
  const expMinusOneHour = exp - 3600; // 3600 วินาที = 1 ชั่วโมง

  // เช็คเวลาที่ลบ 1 ชั่วโมงแล้วกับเวลาปัจจุบัน
  // ตรวจสอบการหมดอายุของ JWT และทำการ refresh token
  if (now > expMinusOneHour) {
    try {
      await authService.refreshToken();
      console.log('Token refreshed successfully.');
    } catch (error) {
      console.error('Error during token handling:', error);
    }
  }
}, 10000); // ตั้งค่าให้เรียกใช้ครั้งเดียวทุก 10 วินาที 1000 = 1 sec

// Request interceptor to add Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = getAuthStore(); // Fetch the store dynamically
    const token = authStore.access_token; // Or use a shared state store
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response Interceptor: Handle 401 Errors and Token Refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    const accessToken = getAuthStore().access_token;
    // if (!accessToken) return Promise.reject('access token is required');
    if (!accessToken) return Promise.reject(error);
    if (!isTokenValid(accessToken)) {
      return router.push('/login');
    }
    if (error.response) {
      const { status } = error.response;

      // if (status == 404) {
      //   console.log('axios 404');
      // }
      // Handle 401 Unauthorized
      if (status === 401 && !originalConfig._retry) {
        if (isLoggingOut) {
          // If already logging out, prevent further actions
          return Promise.reject(error);
        }

        originalConfig._retry = true;
        isLoggingOut = true; // Set the logout flag

        try {
          // Refresh The Token
          // await authService.refreshToken();
          // Move to refresh from fetchThrottledTokenRefresh [auth service]
          await authService.logout(); // Clear user data
          // Redirect to login
          // await router.push({ name: 'auth-login' }); // Ready in services/auth

          // Retry the original request with new token
          // const newAccessToken = useAuthStore().access_token;
          // if (!newAccessToken) throw new error('newAccessToken invalid');

          // Update the Authorization header with new token
          // originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;

          // Retry the original request with new token
          // return axiosInstance(originalConfig);
        } catch (logoutError) {
          console.error('Logout failed:', logoutError);

          // If refresh token also fails, redirect to login
          // await authService.logout(); // Clear user data
          // return Promise.reject(refreshError);
        } finally {
          isLoggingOut = false; // Reset the flag after logout completes
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
