<template>
  <div v-if="isLoading">
    <div class="card-noti-credit">
      <div class="icon-card-noti">
        <div class="icon-noti"><div class="icon-loading-content-card-noti" /></div>
      </div>
      <div class="content-card-noti-credit">
        <div class="content-card-title"><div class="title-loading-content-card-noti" /></div>
        <div class="content-card-detail"><div class="loading-content-card-noti" /></div>
      </div>
    </div>
  </div>
  <div v-else>
    <div @click="emit('click-list')">
      <div class="card-noti-credit">
        <div class="icon-card-noti">
          <div v-if="status == 1" class="icon-noti"><WarningIcon class="icon-empty" /></div>
          <div v-else-if="status == 2" class="icon-noti"><WarningIcon class="icon-not-empty" /></div>
          <div v-else-if="status == 0" class="icon-noti"><WarningIcon class="icon-success" /></div>
        </div>
        <div v-if="notiType == 'Commu_Credit'" class="content-card-noti-credit">
          <div class="content-card-title"><slot name="content-channel" /></div>
          <div class="content-card-detail">
            <slot name="content-channel" />
            {{ status == 1 ? t('notification.noti_credit_modal.credit_empty') : t('notification.noti_credit_modal.credit_non_empty') }}
          </div>
        </div>
        <div v-else-if="notiType == 'Storage'" class="content-card-noti-credit">
          <div class="content-card-title">{{ t('notification.noti_credit_modal.detail.storage') }}</div>
          <div class="content-card-detail">
            {{ status == 1 ? t('notification.noti_credit_modal.storage_empty') : t('notification.noti_credit_modal.storage_non_empty') }}
          </div>
        </div>
        <div v-else-if="notiType == '3'" class="content-card-noti-credit">
          <div class="content-card-title">{{ t('notification.noti_credit_modal.detail.top_up') }}</div>
          <div class="content-card-detail">{{ t('notification.add_credit_success') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import WarningIcon from '@/assets/icons/base/WarningIcon.vue';

interface Props {
  notiType?: string;
  status?: number;
  isLoading?: boolean;
}
const { t } = useI18n();

const props = defineProps<Props>();
const emit = defineEmits(['click-list']);
</script>

<style lang="scss">
.icon-not-empty {
  path {
    fill: #000000;
  }
}
</style>
<style lang="scss" scoped>
.card-noti-credit {
  display: flex;
  min-height: 70px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  margin-top: 10px;
  cursor: pointer;

  .icon-loading-content-card-noti {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: colorChangeCredit 1s infinite alternate;
  }
  .title-loading-content-card-noti {
    height: 30%;
    border-radius: 20px;
    width: 8rem;
    animation: colorChangeCredit 1s infinite alternate;
  }
  .loading-content-card-noti {
    height: 30%;
    border-radius: 20px;
    width: 16rem;
    animation: colorChangeCredit 1s infinite alternate;
  }
  .icon-card-noti {
    min-width: 100px;
    .icon-noti {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .icon-empty {
        fill: #e05240;
        width: 40px;
        height: 40px;
      }
      .icon-not-empty {
        fill: #ffd657;
        width: 40px;
        height: 40px;
        path {
          fill: #000000;
        }
      }
      .icon-success {
        fill: #67ad5b;
        width: 40px;
        height: 40px;
      }
    }
  }
  .content-card-noti-credit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    .content-card-title {
      display: flex;
      align-items: center;
      text-align: left;
      font: normal normal normal 22px/9px Prompt;
      letter-spacing: 0px;
      color: #000000;
      min-height: 30px;
    }
    .content-card-detail {
      display: flex;
      align-items: center;
      text-align: left;
      font: normal normal normal 16px/13px Prompt;
      letter-spacing: 0px;
      color: #707070;
      min-height: 30px;
    }
  }
  @keyframes colorChangeCredit {
    0% {
      background-color: #e2e2e225;
    }
    100% {
      background-color: #00183021;
    }
  }
}
</style>
