<template>
  <transition name="modal-animation" appear>
    <div :id="modalId" :class="['modal', 'base-modal']" tabindex="-1" aria-modal="true" ref="modalRef" role="dialog">
      <div :class="['modal-dialog', 'modal-dialog-centered', isScrollable && 'modal-dialog-scrollable', `modal-${size}`]">
        <transition name="modal-animation-inner" appear>
          <div v-if="isModalOpen" class="modal-content modal-inner" :class="{ 'overflow-y-visible': overflowYVisible }">
            <!-- Modal header -->
            <div v-if="$slots['modal-header'] || !hideHeader" class="modal-header">
              <slot name="modal-header" />
              <div v-if="$slots['modal-action']" class="modal-action">
                <slot name="modal-action" />
              </div>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <slot name="modal-body" />
            </div>
            <!-- Modal footer -->
            <div v-if="$slots['modal-footer'] || !hideFooter" class="modal-footer justify-content-center">
              <slot name="modal-footer" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { onMounted, watch, ref, onUnmounted } from 'vue';
import { Modal } from 'bootstrap';

interface Props {
  overflowYVisible?: boolean;
  modalId?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  isModalOpen: boolean;
  isEscapable?: boolean;
  isScrollable?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
}

const props = withDefaults(defineProps<Props>(), {
  modalId: 'base-modal',
  hideFooter: false,
  hideHeader: false,
  isModalOpen: false,
  isEscapable: false,
  isScrollable: false,
  size: 'xl',
});
const emits = defineEmits(['hide', 'open-modal']);
const modalRef = ref<HTMLElement | null>(null);
let modalInstance: Modal | null = null;

// Close modal and emit hide event
const closeModal = () => {
  if (modalInstance) modalInstance.hide();
  emits('hide');
};

// Watch for changes in isModalOpen prop
watch(
  () => props.isModalOpen,
  (newVal) => {
    if (modalInstance) {
      if (newVal) {
        modalInstance.show();
        emits('open-modal');
      } else {
        modalInstance.hide();
      }
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (modalRef.value) {
    modalInstance = new Modal(modalRef.value, {
      backdrop: 'static',
      keyboard: true,
    });

    modalRef.value.addEventListener('hidden.bs.modal', closeModal);
  }

  if (props.isModalOpen && modalInstance) {
    modalInstance.show();
  }
});

onUnmounted(() => {
  modalInstance?.dispose();
});
</script>
<style></style>

<style scoped lang="scss">
@import '@/assets/styles/base/base-modal.scss';
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal {
  display: none;
  overflow: hidden;
  // -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: #00000063;
}
.modal-content {
  padding: 1rem;
  border-radius: 20px;
  margin: auto;
  max-height: 95vh;
}

/* Additional custom sizes if needed */
.modal-dialog.modal-xxl {
  max-width: 90vw;
}

.modal-dialog.modal-xxxl {
  max-width: 95vw;
}

.modal-header {
  display: flex;
  width: 100%;

  .modal-action {
    margin-left: auto;
  }
}
</style>
