<template>
  <div class="change-brand-modal">
    <base-modal @hide="$emit('closeModal')" hideFooter :is-modal-open="isModalOpen" size="md">
      <template #modal-header>
        <div class="header-section">
          <ChangeBrandAccountIcon fill="#007FFF" class="icon" />
          <span class="text-primary">{{ t('change_brand_modal.change_brand_account') }}</span>
        </div>
      </template>
      <template #modal-body>
        <div class="p-3">
          <label for="change-brand">{{ t('change_brand_modal.select_brand_account') }}</label>
          <form id="change-brand" @submit.prevent="onSubmitBrand">
            <div class="mt-2">
              <native-custom-selector :options="brandList" :label="'brand_name'" searchable v-model="brandSelected" />
            </div>
            <div class="d-flex justify-content-center mt-4">
              <button type="button" class="btn btn-outline-secondary me-3" @click="$emit('closeModal')">
                {{ t('change_brand_modal.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary" :disabled="String(brandQuery) == brandSelected.brand_ref" :class="[loading && 'loading']">
                {{ t('change_brand_modal.change') }}
              </button>
            </div>
          </form>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup lang="ts">
import router from '@/router';
import { useBrandStore } from '@/store/brandStore';
import { useRouterBypassStore } from '@/store/routerStore';
import brandService from '@/services/brand';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import BaseModal from '@/views/components/modal/BaseModal.vue';
import NativeCustomSelector from '@/views/components/custom-v-select/NativeCustomSelector.vue';

import ChangeBrandAccountIcon from '@/assets/icons/base/menubar/ChangeBrandAccountIcon.vue';

interface Props {
  isModalOpen: boolean;
}

const { t } = useI18n();

defineProps<Props>();

const emit = defineEmits(['closeModal']);

const brandStore = useBrandStore();
const { brandList } = storeToRefs(brandStore);
const { openErrorModal } = useValidationModal();

const loading = ref(false);

const brandSelected = ref<Authentication.RespBrandList>({ brand_ref: '', brand_name: '', logo_url: null });
const brandQuery = brandService.getBrandRefParams();
const onSubmitBrand = async () => {
  if (brandQuery && brandQuery == brandSelected.value.brand_ref) return;

  if (!brandSelected.value || !brandService.isValidBrandRef(brandSelected.value.brand_ref)) {
    console.error('selected brand invalid');
    return;
  }
  loading.value = true;
  const changeBrandSuccess = await brandService
    .fetchSubmitBrandAndSet(brandSelected.value.brand_ref)
    .catch((e) => {
      console.error(e);
      openErrorModal(t('change_brand_modal.error'), t('change_brand_modal.error_description'));
    })
    .finally(() => {
      loading.value = false;
    });
  if (changeBrandSuccess) {
    emit('closeModal');

    // If has page confirmation GO Home!!!!!!
    if (router.currentRoute.value.meta && router.currentRoute.value.meta.requiresConfirmation == true) {
      useRouterBypassStore().update(true);
      // return next(to);
      return await router
        .push({
          path: '/',
          query: { ...router.currentRoute.value.query, brandRef: null },
        })
        .then(() => useRouterBypassStore().update(false))
        .then(() => router.go(0));
    } else {
      await router.push({ ...router.currentRoute.value, query: { ...router.currentRoute.value.query, brandRef: null } });
      router.go(0);
      // router.go(0);
      // reload page
      // window.location.reload();
    }
  }
};
</script>

<style lang="scss">
@import '@assets/styles/base/menubar/change-brand-modal.scss';
</style>
