<template>
  <div class="app-loading-container">
    <div class="app-loading-wrp">
      <div class="app-loading-img-cdp">
        <img class="w-100" src="/cdp_logo.png" draggable="false" alt="logo" />
        <span class="app-loader"></span>
      </div>
      <div class="app-loading-img-choco">
        <img class="w-100" src="/choco_powerby.png" draggable="false" alt="loading" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-loading-container {
  width: 100%;
  height: 100vh;
  padding: 28vh 0 2vh 0;

  .app-loading-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;

    .app-loading-img-cdp {
      width: 15vmin;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .app-loading-img-choco {
      width: 200px;
    }

    .app-loader {
      width: 48px;
      height: 48px;
      border: 4px solid #0086ff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 0.7s linear infinite;
      margin-top: 5rem;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
