import { CustomRouteRecord } from '@/types/router/router';

const MainMyAccount = () => import('@/views/modules/my-account/MainMyAccountPage.vue');

const MyAccountRoutes: Array<CustomRouteRecord> = [
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MainMyAccount,
    breadcrumb: [
      {
        text: 'account.myAccount', //'My Account'
        to: '/my-account',
      },
    ],
  },
];

export default MyAccountRoutes;
