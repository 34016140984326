import { acceptHMRUpdate, createPinia, getActivePinia, Pinia, Store } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

// create pinia instance, for use in application
// pinia must be created before use its store in any part of the application
// (see: https://pinia.vuejs.org/core-concepts/outside-component-usage.html#single-page-applications)
export const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export const $ResetPinia = (): Record<string | 'all', () => void> => {
  const pinia = getActivePinia() as ExtendedPinia;

  if (!pinia) {
    throw new Error('There is no stores');
  }

  const resetStores: Record<string, () => void> = {};

  pinia._s.forEach((store, name) => {
    resetStores[name] = () => store.$reset();
  });

  resetStores.all = () => pinia._s.forEach((store) => store.$reset());
  return resetStores;
};

// TODO: initialize pinia (e.g. its plugins)

// for store definition or namespace/module like vuex, see each store file in "@/store" folder
// (pinia use store+id to separate store module, unlike vuex that use namespace+nested store module name
// this much more easily to work and maintain with multiple/separate store module)

