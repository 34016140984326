import { acceptHMRUpdate, defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
  state: () =>
    <BaseLoading.LoadingState>{
      baseLoading: {
        isOpen: false,
        title: '',
      },
      appLoading: {
        isOpen: true,
      },
    },
  getters: {
    isLoadingConfig: (state) => state.baseLoading,
    appLoadingConfig: (state) => state.appLoading,
  },
  actions: {
    OPEN_LOADING(value: BaseLoading.PayloadValue) {
      const payload = value;

      this.baseLoading.isOpen = payload.isOpen ? payload.isOpen : false;
      this.baseLoading.title = payload.title || '';
    },
    CLOSE_LOADING() {
      this.baseLoading.isOpen = false;
      this.baseLoading.title = '';
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
}
