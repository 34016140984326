import { i18n } from '@/libs/i18n';
import { acceptHMRUpdate, defineStore } from 'pinia';
interface SettingStoreState {
  currentLocale: 'en-US' | 'th';
}
export const useSettingStore = defineStore('setting', {
  state: (): SettingStoreState => ({
    currentLocale: 'en-US',
  }),
  actions: {
    // TODO: validate locale
    changeLocale(locale: 'en-US' | 'th') {
      // change i18n locale
      i18n.global.locale = locale;

      // update state and local storage setting
      this.currentLocale = locale;
      localStorage.setItem('setting.currentLocale', locale);
    },
    setupLocale() {
      // load locale and set state
      this.currentLocale = (localStorage.getItem('setting.currentLocale') as 'en-US' | 'th') || 'th';

      // change i18n locale
      i18n.global.locale = this.currentLocale;
    },
  },
});
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingStore, import.meta.hot));
}
