export default {
  notification: 'การแจ้งเตือน',
  contact_us_modal: {
    contact_us: 'ติดต่อเรา',
    line_add: 'เพิ่มเพื่อน',
    scan_qr: 'สแกน QR CODE',
    request_add_credit: 'เพื่อส่งคำขอเติมเครดิต',
  },

  noti_credit_modal: {
    title: 'การแจ้งเตือน',
    button: 'ดูเพิ่มเติม',
    detail: {
      storage: 'พื้นที่จัดเก็บข้อมูล',
      top_up: 'เติมเครดิต',
    },
    credit_empty: 'เครดิตหมดแล้ว',
    credit_non_empty: 'เครดิตใกล้หมดแล้ว',
    storage_empty: 'พื้นที่จัดเก็บข้อมูลเต็มแล้ว',
    storage_non_empty: 'พื้นที่จัดเก็บข้อมูลใกล้เต็มแล้ว',
  },

  noti_credit_success: {
    title: 'การเติมเครดิตสำเร็จ',
    content_one: 'มีแคมเปญที่ใช้งานอยู่ได้หยุดชั่วคราว',
    num: '',
    list: 'แคมเปญ',
    campaign: 'แคมเปญ',
    content_two: 'หากต้องการจัดการแคมเปญ กรุณาตรวจสอบได้ที่',
    manage: 'จัดการแคมเปญ',
    add_credit: 'เครดิตที่เติมไป',
    total_credit: 'เครดิตที่คงเหลือ',
    skip_button: 'ข้าม',
  },

  card_credit_notification: {
    title: {
      normal: 'เครดิตที่ใช้งานได้ปกติ',
      empty: 'เครดิตหมดแล้ว',
      non_empty: 'เครดิตใกล้หมด',
    },
    remaining_credit: 'เครดิตคงเหลือ',
    text_underline_credit: 'ข้อมูลแคมเปญและเครดิตที่ใช้งานอยู่',
    active_campaign: 'แคมเปญที่ใช้งานอยู่',
    pre_credit: 'เครดิตที่ต้องใช้',
    see_more: 'ดูเพิ่มเติม',
  },

  main_notification: {
    tooltip_text: 'กรุณากดอ่านแจ้งเตือนทั้งหมด',
    ask_more: 'สอบถามเพิ่มเติม',

    menu: {
      credit: 'แจ้งเตือนเครดิต',
      storage: 'แจ้งเตือนพื้นที่เก็บข้อมูล',
      top_up: 'แจ้งเตือนการเติมเครดิต',
    },

    credit: {
      title: 'เครดิต',
      tab: {
        all: 'ทั้งหมด',
        empty: 'เครดิตหมดแล้ว',
        non_empty: 'เครดิตใกล้หมด',
        normal: 'เครดิตที่ใช้งานได้ปกติ',
      },
      button: 'เติมเครดิต',
    },

    detail_credit: {
      list: 'รายการ',
      show: 'โชว์',
      back: 'Back',
      title_header: 'รายละเอียดเพิ่มเติม',
      des_header_one: 'รายละเอียดเพิ่มเติมของ',
      des_header_two: '',
      popup: {
        red: {
          title: 'เครดิตหมด',
          description: 'กรุณาเติมเครดิตให้เพียงพอต่อการทำงานของแคมเปญที่ทำงานอยู่และแคมเปญที่ต้องการสร้างขึ้นใหม่',
        },
        yellow: {
          title: 'เครดิตใกล้หมด',
          description: 'กรุณาเติมเครดิตให้เพียงพอต่อการทำงานของแคมเปญที่ทำงานอยู่และแคมเปญที่ต้องการสร้างขึ้นใหม่',
        },
      },
      tab: {
        all: 'แคมเปญทั้งหมด',
        commu: 'การสื่อสาร',
        mka: 'การตลาดอัตโนมัติ',
      },
      button: 'เติมเครดิต',
    },

    storage: {},
    empty: 'ไม่พบข้อมูล',
  },

  campaign_pause_notification: 'แคมเปญที่เครดิตไม่พอ',
  box_credit_notification: {
    content: 'เครดิตคงเหลือไม่เพียงพอต่อ การส่งออกทั้งหมด',
    add_credit: 'เติมเครดิต',
  },

  notification_bell: {
    title: 'การแจ้งเตือน',
    seconds: 'วินาทีที่แล้ว',
    minutes: 'นาทีที่แล้ว',
    hours: 'ชั่วโมงที่แล้ว',
    month_thai: [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ],
  },
  no: 'ลำดับที่',
  CampaignName: 'ชื่อแคมเปญ',
  Module: 'โมดูล',
  channel: 'ช่องทาง',
  schedule_mode: 'รูปแบบการส่ง',
  count_dispatchable: 'จำนวนผู้รับทั้งหมด​',
  count_success: 'จำนวนผู้รับที่ส่งออกสำเร็จ',
  count_pendingcredit: 'จำนวนผู้รับที่รอการส่ง',
  est_credit_use: 'เครดิตที่ต้องใช้',
  updated: 'อัปเดตล่าสุด',
  status: 'สถานะ',
  send_now: 'ส่งทันที',
  schedule_later: 'กำหนดเวลาในภายหลัง',
  repeat: 'ส่งซ้ำ',
  pause: 'หยุดชั่วคราว',
  dispath: 'กำลังส่ง',
  scheduled: 'ส่งออกสำเร็จ',
  preparing: 'เตรียมข้อมูล',
  not_found:'ไม่พบข้อมูล',
  tooltip_column_accum_recp:
    'นับแบบสะสม, แคมเปญการสื่อสารแบบส่งครั้งเดียวจะมีจำนวนตามรอบเดียวที่ run, แคมเปญการสื่อสารแบบวนส่งซ้ำจะนับสะสมตั้งแต่รอบที่ 1 - รอบถัดไป, แคมเปญการตลาดอัตโนมัติจะนับสะสมเรื่อยๆ ตามยอดผู้รับปัจจุบัน (นับเฉพาะผู้รับที่ส่งแล้วเครดิตไม่พอ)',
  card_provider_one: 'ยังไม่ได้มีการเชื่อมตัวการส่งออก',
  card_provider_two: 'กรุณาติดต่อเราเพื่อทำการเชื่อมต่อ',
};
