export default {
  loading: 'Loading',
  search_column: 'Search Column',
  search_table: 'Search Table',
  renamesegment: 'Edit Segment Successfully',
  all_customer: {
    tabs: {
      customer: 'All Customer',
      product: 'All Product',
      transaction: 'All Transaction',
    },

    button: {
      create_segment: 'Create Segment',
      duplicate: 'Duplicate',
      export: 'Export',
    },
    table: {
      countdata_before: 'All',
      countdata_after: 'segments on this page are selected.',
      clear_select: ' Clear selection',
      segments_inbox_before: 'Select all ',
      segments_inbox_after: ' segments in Inbox.',
    },
  },

  all_folder: {
    tabs: 'All Folder',

    button: {
      action: 'Manage',
      create_folder: 'Folder',
      create_segment: 'Create Segment',
    },

    table: {
      folder_name: 'Folder Name',
      total_segment: 'Total Segment',
      update_by: 'Update By',
      last_update: 'Last Update',
    },

    action: {
      edit: 'Edit',
      delete: 'Delete',
      copy: 'Duplicate',
      tooltip: 'Detail List',
    },

    popup: {
      delete_success: 'Delete Success',
    },

    modal: {
      create_folder: {
        header: 'Create Folder',
        folder_name: 'Folder name',
        placeholder: 'specify',
      },

      delete_confirm: {
        topic: 'Delete Folder?',
        description_first: 'All created customer groups will be deleted from',
        description_second: 'This folder as well Do you want to delete it?',
      },

      button: {
        cancel: 'Cancel',
        save: 'Save',
        delete: 'Delete',
      },
    },
  },
  all_data: {
    tabs: 'All Data',
    text_empty_table: 'Please select a table to display the data',
  },
  all_segment: {
    tabs: 'All Segment',
    topic_edit_modal: 'Edit Folder',
    label_edit_modal: 'Folder Name',
    placeholder_edit_modal: 'specify..',

    button: {
      action: 'Manage',
      create_segment: 'Create Segment',
      edit: 'Edit',
      cancel: 'Cancel',
    },

    table: {
      segment_name: 'Segment Name',
      folder: 'Folder',
      segment_size: 'Segment size',
      campaign: 'Campaign',
      update_by: 'Update By',
      fast_update: 'Last Update',
      segment_type: 'Segment Type',
    },

    action: {
      edit: 'Edit',
      duplicate: 'Duplicate',
      delete: 'Delete',
    },

    popup: {
      delete_success: 'The folder was successfully deleted.',
    },
  },

  detail_segment: {
    last_update: 'Last Update',
    hours_ago: 'hours ago',
    minutes_ago: 'minutes ago',
    seconds_ago: 'seconds ago',
    table: {
      statusbar_total_record_all: 'All',
      statusbar_total_record_selected: 'segments on this page are selected.',
      statusbar_total_record_button_all: 'Select all ',
      statusbar_total_record_button_inbox: ' segments in Inbox',
      statusbar_total_record_button_clear: 'Clear selection',
    },
    modal: {
      edit_segment_title: 'Edit Segment',
      edit_segment_name: 'Segment Name',
      segment_delete: {
        segment_order_all: 'Delete all items',
        segment_order: 'Lists',
        segment_description: 'Do you want to delete an item?',
      },
      segment_replace: {
        segment_content_first: 'Customer group name ',
        segment_content_last: ' already exists',
        segment_description: 'Please name the group again.',
      },
    },
    button: {
      edit_segment: 'Edit Segment',
      edit_name: 'Edit Name',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      duplicate: 'Duplicate',
      export: 'Export',
    },
  },

  preview_segment: {
    title: 'Preview Data',
    folder: 'Folder',
    table: {
      statusbar_total_record_all: 'All',
      statusbar_total_record_selected: 'segments on this page are selected.',
      statusbar_total_record_button_all: 'Select all ',
      statusbar_total_record_button_inbox: ' segments in Inbox',
      statusbar_total_record_button_clear: 'Clear selection',
    },
    modal: {
      topic_create_segment_name: 'Create a segment name',
      label_segment_name: 'Segment Name',
      reate_segment_placeholder: 'specify...',
    },
    button: {
      edit_rule: 'Edit Condition',
      save: 'Save',
      confirm: 'Confirm',
      cancel: 'Cancel',
      edit_name: 'Edit Name',
      export_report: 'Export',
    },
  },

  preview: {
    title: 'Preview Segment',
    save_type: 'Type Save Segment',
    manage_segment: 'Manage Segment',
  },

  breadcrumb: 'Segment',

  result: {
    segment_list: 'Segment List',
    insight: 'Data Insight',
    view_type: 'Save View',
    filter_segment: 'Filter Segment',
    edit_rule: 'Edit Rule',
    edit_segment: 'Edit Segment',
    modal: {
      rename_input: 'Input',
    },
  },

  components: {
    create_segment_modal: {
      set_conditions: 'Set conditions',
      logic: 'Logic',
      no_matching_options: 'Sorry, no matching options.',

      operator: {
        title: 'Operator',
        and: 'And',
        or: 'Or',
        advance: 'Advance',
      },

      button: {
        add_rule_set: 'Add rule set',
        filter_by_segment: 'Filter by segment',
        cancel: 'Cancel',
        apply: 'Apply',
        create_group: 'Create Group',
        ungroup_all: 'Ungroup All',
      },

      ruleset_card: {
        rule_name: 'Rule name',
        set_conditions: 'Set conditions',
        logic: 'Logic',
        group_by: 'Group by',

        operator: {
          title: 'Operator',
          and: 'And',
          or: 'Or',
        },

        button: {
          add_rule: 'Add rule',
          function_aggregate: 'Function Aggregate',
          add_function: 'Add Function',
        },
      },

      notification: {
        button: {
          cancel: 'Cancel',
          confirm: 'Confirm',
          close: 'Close',
        },
      },

      filter_by_segment: {
        rule_name: 'Rule name',
        include: 'Include',
        exclude: 'Exclude',
        select_view: '- Select View -',
        select_segment: '- Select Segment -',
      },
    },

    view_mode: {
      placeholder: '-- Place Selected --',
      no_item: 'No Item',
      customer: 'Customer',
      product: 'Product',
      transaction: 'Transaction',
      group_by: 'Group By',
      tooltip: 'View mode',
    },

    save_custom_dialog: {
      title: 'Edit customer group',
      view_data: 'View Data',

      table: {
        countdata_before: 'All',
        countdata_after: 'segments on this page are selected.',
        clear_select: ' Clear selection',
        segments_inbox_before: 'Select all ',
        segments_inbox_after: ' segments in Inbox.',
      },

      all_customer: {
        tabs: {
          customer: 'All Customer',
          product: 'All Product',
          transaction: 'All Transaction',
        },

        btn: {
          create_segment: 'Create Segment',
        },
      },

      all_folder: {
        tabs: 'All Folder',

        dropdown: {
          folder: 'Folder',
          segment: 'Segment',
        },
      },

      all_segment: {
        tabs: 'All Segment',

        dropdown: {
          segment: 'Segment',
        },
      },

      all_data: {
        tabs: 'All Data',
      },

      button: {
        cancel: 'Cancel',
        add: 'Add',
      },

      modal_next_tab: {
        title_first: 'If the information display bar has been changed',

        title_second: 'Previously selected items will not be merged.',

        description: 'Do you want to continue?',

        button: {
          cancel: 'Cancel',
          next: 'Next',
        },
      },

      modal_save_modal: {
        title_before: 'Save all ',

        title_after: ' Lists',

        description: 'Want to save yes or no?',

        button: {
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },

      modal_success: {
        title: 'The transaction was successfully recorded.',
      },
    },

    breadcrumb: {
      folder: 'Folder',
    },

    dialog_segment: {
      segment_name: 'Segment name',
      search: 'Search',
      title_folder: 'Please select a folder.',
      view_data: 'view data',

      table: {
        segment_name: 'Segment Name',
        segment_type: 'Segment Type',
        no_item: 'No Items',
      },

      modal: {
        topic_create_folder: 'Create folder',
        create_folder_placeholder: 'Name the folder',
      },

      button: {
        create_folder: 'Folder',
        save: 'Save',
        cancel: 'Cancel',
      },
    },
  },
  segment_conditions: 'Segmenting target groups based on conditions.',
  wait_moment: 'wait a moment...',
  saving_please_wait_moment: 'Saving. Please wait a moment...',
  group_by: 'Group by',
  executing_campaign: 'Executing campaign...',
  save_NewEntry: 'Save New Segmentation',
  placeholder_save_segment: 'Please enter a segment name',
  custom_segment: 'Custom segment',
  new_segment: 'New Segment',
  segment_template: 'Segment Template',
  //NotificationGroupRuleSet
  modal_GroupRuleSet_unableGroup: 'Grouping failed',
  modal_GroupRuleSet_conditions: 'Grouping operations cannot be executed due to conflicting criteria.',
  modal_GroupRuleSet_edit_conditions: 'Please revise the grouping conditions and try again',
  modal_GroupRuleSet_selected_rule: 'The selected rule set is not linked for grouping.',
  modal_GroupRuleSet_select_newConsecutive: 'Please choose a linked rule set and  set  new  grouping criteria',
  modal_GroupRuleSet_clear_group: 'Confirming this action will reset all grouping criteria',
  modal_GroupRuleSet_want_confirm: 'Are you sure you want to proceed?',
  //NotificationModal
  modal_agg_off: 'Disable Aggregate Function?',
  modal_clear_groupby: 'This will permanently delete all grouped {ruleset} segment criteria',
  modal_last_rule_set: 'Because this is the last Rule Set with Function Aggregate turned on',
  modal_confirm: 'This will permanently remove the ruleset configured search conditions',
  modal_agg_data_clear: 'Data set to the Function Aggregate will be removed.',
  modal_rule_set_delete: 'Are you sure you want to proceed?',
  modal_cancel: 'Canceling will discard any unsaved changes to the segmentation criteria.',
  //NotificationOperatorMain
  modal_operator_change_condition: 'Update Segmentation',
  modal_operator_after_change: 'Confirm modification of segmentation criteria.',
  modal_operator_delete_old_condition: 'This will permanently delete the existing segment criteria.',
  modal_operator_confirm: 'Are you sure you want to proceed?',
  // ModalSubmit
  preview_save_with_sync: 'Save target group by condition (with sync)',
  preview_save_without_sync: 'Save target group by condition (without sync)',
  preview_save_manual: 'Save manually grouped target',
  preview_save_changes: 'Save changes',
  preview_in_folder: 'in folder',
  preview_confirm_save: 'Do you want to save?',
  // PreviewErrorModal
  preview_group_name: 'Customer group name',
  preview_group_exists: 'already exists',
  preview_rename_group: 'Please rename the group',
  // SelectFolderModal
  preview_condition_group_with_sync: 'Rule-Based Segmentation (Sync)',
  preview_condition_group_without_sync: 'Rule-Based Segmentation (Batch)',
  preview_manual_group: 'Custom Segmentation (Selective)',

  //------customer profile-------
  setting_dashboard: 'Dashboard Setting',
  dashboard_setting: 'Customer Dashboard Setting​',
  dashboard_overview: 'Dashboard Overview',
  branch: 'Branch',
  point: 'Point',
  quantity_amount_pieces: 'Quantity {{ value }} pieces',
  open_ticket_by: 'Open ticket by',
  customer_scoring: 'Customer Scoring',
  add_charts: 'Add Charts',
  add_tabs: 'Add New Tab',
  add_data: 'Add Data',
  please_click: 'Please Click',
  data_type: 'Data Type',
  display: 'Display',
  selected: 'Selected',
  infographic: 'Infographic',
  normal: 'Normal',
  manage_properties: 'Manage Properties',
  select_properties: 'Please select properties',
  last_visit: 'Last Visit',
  manage_property: 'Manage Property',
  profile: 'Profile',
  overall: 'Overall',

  segment_type_dropdown: {
    select_segment_type: 'Select Segment Type',
    segment_type: 'Segment Type',
    all: 'All',
    batch: 'Batch',
    sync: 'Sync',
    custom: 'Custom',
  },
  segment_select_desc: {
    data: 'data',
    select_count: 'Selecting {count} data',
    total: 'total',
    select_all: 'Selecting all data',
    for_all: ' from a total of ',
    clear_select: ' To clear the selection, click.',
  },
  panel_bulider: {
    filter_data_results: 'Filter data sesults',
    source: 'Source',
    table: 'Table',
    set_condition: 'Set Condition',
    condition: 'Condition',
    and: 'AND',
    or: 'OR',
    list: 'List',
    advance: 'Advance',
    aggregate_function: 'Aggregate Function',
    drag_and_drop_columns: 'Drag and Drop Columns',
    add_condition: 'Add Condition',
    add_filter_segment: 'Add Filter Segment',
    reset: 'Reset',
    cancel: 'Cancel',
    apply: 'Apply',
    select_count: 'Select',
    select_data: 'Select Data',
    clear_select: 'Clear',
    type: 'Type',
    column: 'Column',
    rule_ex: 'Condition​​',
    include: 'Include',
    exclude: 'Exclude',
    folder: 'Folder',
    segment: 'Segment',
    ex: 'Equation',
    groupby: 'Group By',
  },
  close_back: 'Back',
  apply: 'Apply',
  modal_select_view: {
    title: 'Display format',
    des: 'Please select the data type you want to save the display format.',
    only: 'Show only {view} information.',
  },
  advance_view: {
    title: 'Advanced Segmentation',
    des: 'Customize data display formats to view results from different perspectives or show multiple data types in a table (e.g., invoice number, redemption number).',
    header_step_one: 'Configure Main Table visibility',
    header_step_two: 'Configure Additional Table visibility',
    header_step_three: 'Configure column visibility',
    left_one: 'Select Main Table Format',
    right_two: 'Select Table',
    main_select: 'Please Select Main Table',
    main: 'Main Table',
    additional: 'Additional Table',
    comingsoon: 'Coming soon...',
    back: 'Back',
    next: 'Next',
    submit: 'Apply',
  },
  save_segment_sync: 'Save Sync Segmentation',
  save_segment_batch: 'Save Batch Segmentation',
  save_segment_custom: 'Save Custom Segmentation',
  save: 'Save',
  to_folder: 'to folder',
  leave_page: 'Leave this page?',
  leave_page_des: 'Do you want to cancel the segmentation  settings and leave this page?',

  save_segment_custom_title: '{total_record} items saved to \n “{segment_name}” \n in the “{folder_name}” folder',
  save_segment_custom_confirmation: 'Do you want to save this segmentation ?',
};
