export default {
  mka: 'การตลาดอัตโนมัติ',
  automations: 'การตลาดอัตโนมัติ',
  summary: 'ภาพรวมแคมเปญ',
  status: 'สถานะ',
  all_campaign: 'แคมเปญทั้งหมด',
  active_campaigne: 'ใช้งานอยู่',
  pause_campaigne: 'หยุดชั่วคราว',
  draft_campaigne: 'ฉบับร่าง',
  complete_campaigne: 'เสร็จสิ้น',
  inactive_campaigne: 'ไม่ได้ใช้งาน',
  status_all: 'ทั้งหมด',
  status_draft: 'ฉบับร่าง',
  status_preparing: 'กำลังดำเนินการ',
  status_active: 'ใช้งานอยู่',
  status_pause: 'หยุดชั่วคราว',
  status_complete: 'สิ้นสุดแคมเปญ',
  status_archived: 'จัดเก็บเก็บถาวร',
  campaign_name: 'ชื่อแคมเปญ',
  start_date: 'วันที่เริ่ม',
  end_date: 'วันที่สิ้นสุด',
  last_update: 'อัปเดตล่าสุด',
  ends_in: 'สิ้นสุดใน',
  favorite: 'รายการโปรด',
  update_by: 'อัปเดตโดย',
  manage: 'จัดการ',
  detail: 'รายละเอียด',
  clone: 'คัดลอก',
  edit: 'แก้ไข',
  delete: 'ลบ',
  create_campaign: 'สร้างแคมเปญ',
  date: 'วันที่',
  clear: 'ล้าง',
  start_campaign: 'เริ่มแคมเปญ',
  pause_campaign: 'หยุดชั่วคราว',
  resume_campaign: 'เริ่มแคมเปญต่อ',
  saved: 'บันทึกแล้ว',
  entry_source: 'แหล่งข้อมูล',
  flow_control: 'เงื่อนไขการทำงาน',
  action: 'action',
  actions: 'ช่องทางส่งออก',
  content: 'เนื้อหา',
  sender: 'ผู้ส่ง',
  change: 'เปลี่ยน',
  save: 'บันทึก',
  subject: 'เรื่อง',
  segment: 'กลุ่มเป้าหมาย',
  report: 'รายงาน',
  success: 'สำเร็จ',
  fail: 'ล้มเหลว',
  resume: 'ดำเนินการต่อ',
  export: 'ส่งออก',
  preview: 'ตัวอย่าง',
  other: 'Other',
  consent_pdpa: 'ส่งทั้งหมด',
  pdpa_restricted: 'ไม่ยินยอม PDPA',
  entry_source_segment: 'กลุ่มเป้าหมาย',
  flow_control_fork_split: 'จำนวนช่องทาง',
  flow_control_distribution_split: 'การกระจาย',
  flow_control_distribution_split_sub: '(แบบวนรอบ)',
  flow_control_random_split_true: 'การสุ่ม',
  flow_control_random_split_true_sub: '(แบบทั่วไป)',
  flow_control_random_split_normal: 'การสุ่ม',
  flow_control_random_split_normal_sub: '(ตามสัดส่วน)',
  flow_control_wait_by_duration: 'เวลารอ',
  flow_control_wait_until_date: 'รอถึงวันที่',
  select_date: 'เลือกวัน',
  select_time: 'เลือกเวลา',
  flow_control_merge: 'รวมช่องทาง',
  flow_control_limit: 'จำกัดการส่ง',
  flow_engagement: 'มีส่วนร่วม',
  actions_sms: 'SMS',
  actions_line: 'Line',
  actions_edm: 'E-mail',
  modal_use: 'เลือก',
  modal_next: 'ต่อไป',
  modal_back: 'ย้อนกลับ',
  modal_cancel: 'ยกเลิก',
  modal_change: 'เปลี่ยน',
  modal_close: 'ปิด',
  modal_apply: 'ใช้งาน',
  modal_view_data: 'มุมมองข้อมูล',
  modal_folder: 'โฟลเดอร์',
  modal_segment: 'กลุ่มเป้าหมาย',
  modal_priority: 'ลำดับความสำคัญ',
  modal_year: 'ปี',
  modal_month: 'เดือน',
  modal_day: 'วัน',
  modal_hour: 'ชั่วโมง',
  modal_minute: 'นาที',
  modal_second: 'วินาที',
  modal_branchs: 'จำนวน',
  modal_total_customer_limit: 'จำกัดจำนวนลูกค้า',
  modal_limit: 'กำหนด',
  modal_unlimit: 'ไ่ม่กำหนด',
  modal_limit_per_customer: 'จำกัดจำนวนต่อลูกค้าหนึ่งท่าน',
  modal_mode: 'โหมด',
  modal_wait_all: 'รอทั้งหมด',
  modal_wait_count: 'รอตาม',
  modal_flow_mode: 'ลำดับความสำคัญ',
  modal_non_priority: 'ไม่จัดลำดับ',
  modal_entry_priority: 'จัดลำดับตามตั้งค่า',
  modal_add: 'เพิ่ม',
  modal_tooltip_campaign_public: 'ดับเบิลคลิก เพื่อดูรายละเอียดเพิ่มเติม',
  modal_tooltip_campaign_draft: 'ดับเบิลคลิก เพื่อกรอกรายละเอียดเพิ่มเติม',
  modal_engagement: {
    engagement_conditions: 'เงื่อนไขการมีส่วนร่วม',

    select_the_node_to_config: 'กรุณาเลือกโหนดที่ต้องการตั้งค่า',

    content_required: 'กรุณาเพิ่มเนื้อหา',
    tooltip_select_node: 'คลิกเพื่อเลือกโหนด',
    verify_error_select_node: 'โปรดเลือกโหนด',
    invalid_communication_template: 'เทมเพลตการสื่อสารไม่ถูกต้อง',
    verify_error_template_not_support_engagement:
      'เทมเพลตการสื่อสารนี้ไม่สามารถใช้ร่วมกับโหนด "มีส่วนร่วม" ได้\nเนื่องจากไม่มีการตั้งค่าการติดตามไว้ กรุณาเปลี่ยนเทมเพลต',

    change_node: 'เปลี่ยนโหนด',
    confirm_change_node: 'ยืนยันการเปลี่ยนโหนด\nจะต้องตั้งค่าการมีส่วนร่วมอีกครั้ง',
    confirm_change_node_desc: 'ต้องการยืนยัน ใช่หรือไม่?',

    equation: 'สมการ',
    group: 'จัดกลุ่ม',
    cancel_grouping: 'ยกเลิกจัดกลุ่ม',
    add_condition: 'เพิ่มเงื่อนไข',

    copy_success: 'คัดลอกสำเร็จ',

    operation_and: 'และ',
    operation_or: 'หรือ',

    opened: 'Opened',
    clicked: 'Clicked',

    greater_than: 'มากกว่า',
    greater_than_or_equals_to: 'มากกว่าหรือเท่ากับ',
    less_than: 'น้อยกว่า',
    less_than_or_equals_to: 'น้อยกว่าหรือเท่ากับ',
    equals_to: 'เท่ากับ',
    times: 'ครั้ง',

    set_time_period: 'กำหนดช่วงเวลา',
    with_in: 'ภายใน',
    second: 'วินาที',
    minute: 'นาที',
    hour: 'ชั่วโมง',
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    after_sending_the_message: 'หลังส่งออกข้อความ',
  },
  summary_sent: 'ส่งแล้ว',
  summary_condition: 'เงื่อนไข',
  summary_completed: 'สำเร็จ',
  summary_outstanding: 'คงค้าง',
  summary_remaining: 'เครดิตคงเหลือ',
  summary_failed: 'ไม่สำเร็จ',
  summary_wait: 'รอ',
  summary_last_update: 'อัปเดตล่าสุด',
  summary_total_customer: 'ลูกค้าทั้งหมด',
  summary_contacts_today: 'ติดต่อเพิ่มขึ้นวันนี้',
  summary_ready_to_send: 'พร้อมสำหรับการส่ง',
  summary_unconsented: 'ไม่ยินยอม PDPA',
  summary_success: 'สำเร็จ',
  summary_campaign_publish: 'แคมเปญส่งออกล่าสุด',
  summary_total: 'ทั้งหมด',
  summary_persons: 'คน',
  action_info_title: 'ผู้รับ',
  action_info_campaign_name: 'ชื่อแคมเปญ',
  action_info_recipient: 'ผู้รับ',
  action_info_sender_name: 'ผู้ส่ง',
  action_info_created_by: 'สร้างโดย',
  action_info_last_updated: 'อัปเดตล่าสุด',
  action_info_all_recipient: 'ผู้รับทั้งหมด',
  action_info_success: 'สำเร็จ',
  action_info_fail: 'ล้มเหลว',
  action_info_not_enough_credit: 'เครดิตไม่พอ',
  action_info_node_name: 'ชื่อกลุ่มเป้าหมาย',
  action_info_type_desc_source: 'ประเภทกลุ่มเป้าหมาย',
  action_info_type_desc_flow: 'ประเภทเงื่อนไข',
  verify_error: 'เกิดข้อผิดพลาด',
  verify_error_node: 'ไม่พบ node ในแคมเปญนี้ กรุณาเลือก node ก่อนเริ่มแคมเปญ',
  verify_error_flow: 'ไม่พบการเชื่อมต่อ node ในแคมเปญนี้ กรุณาเชื่อมต่อ node ก่อนเริ่มแคมเปญ',
  add_credit: 'เติมเครดิต',
  journey_paused: 'Journey หยุดชั่วคราว',
  before_start_journey: 'ตรวจสอบข้อมูลก่อนเริ่มแคมเปญ',
  detail_before_start_journey: { channel: 'ช่องทาง ', start: 'ไม่สามารถนำส่งข้อความไปยังผู้รับได้', end: 'เนื่องจากเครดิตไม่เพียงพอต่อการใช้งาน' },
  detail_confirm_before_start_journey: {
    start: 'แต่ยังส่งข้อความผ่านช่องทาง',
    middle: 'ได้',
    end: 'หากต้องการเริ่มแคมเปญ กรุณากด ',
    confirm: '“ยืนยัน”',
  },
  credit_remaining_header: 'เครดิตคงเหลือ',
  channel: 'ช่องทางการส่งออก',
  credit_remaining: 'เครดิตคงเหลือ',
  channel_no_provider: 'ไม่พบการเชื่อมต่อ',
  channel_no_provider_contact: 'ติดต่อเรา',
  segment_clone_modal_warning: 'ไม่สามารถทำรายการได้ เนื่องจาก Version การจัดกลุ่มเป้าหมาย ไม่ตรงกับ Version ปัจจุบัน',
  segment_does_not_match: 'กลุ่มเป้าหมาย ไม่ตรงกับ Version ปัจจุบัน',

  modal_detail_create_mka_journey: {
    a: 'กรณีต้องการส่งข้อความผ่านการตลาดอัตโนมัติ กรุณาใช้เทมเพลต',
    b: 'ของช่องทางการสื่อสารที่ต้องการส่ง กด',
    c: 'หรือหากมีเทมเพลตแล้ว กด',
  },
  modal_create_template: 'สร้างเทมเพลต',
  modal_continue: 'ดำเนินการต่อ',

  modal_select_communication_channel: 'เลือกช่องทางการสื่อสาร',
  modal_select_communication_channel_detail: 'กรุณาเลือกช่องทางการสื่อสารเพื่อสร้างเทมเพลต',

  modal_confirm_data_submission: 'เริ่มแคมเปญ',
  modal_confirm_run_detail: 'ต้องการยืนยันการเริ่มทำงานแคมเปญหรือไม่',

  modal_confirm_pause: 'หยุดแคมเปญ',
  modal_confirm_pause_campaign: 'ต้องการยืนยันการหยุดแคมเปญชั่วคราวหรือไม่',

  modal_confirm_resume: 'เริ่มแคมเปญต่อ',
  modal_confirm_resume_campaign: 'ต้องการยืนยันการเริ่มแคมเปญต่อหรือไม่',

  modal_incorrect_information: 'เกิดข้อผิดพลาด',
  modal_incorrect_information_detail: 'ข้อมูลไม่สมบูรณ์ กรุณาตรวจสอบและกรอกข้อมูลให้ครบถ้วน',
  modal_incorrect_information_detail_100: 'อัตราส่วนรวมกันไม่เท่ากับ 100%',

  clear_data: 'ล้างข้อมูล',
  delete_all_mka: 'ต้องการล้างข้อมูล MKA Flow ทั้งหมดหรือไม่',

  successful: 'ทำรายการสำเร็จ',

  data_info_modal: {
    customerid: 'Customer ID',
    customer_firstname: 'ชื่อ',
    customer_lastname: 'นามสกุล',
    opened: 'Opened',
    clicked: 'Clicked',
    mobileno: 'เบอร์โทรศัพท์',
    email: 'อีเมล',
    line_uid: 'Line UID',
    status: 'สถานะ',
    send_time: 'เวลาส่ง',

    link: 'ลิงก์',
    total_click: 'คลิกทั้งหมด',
    rule: 'กฏ',
    action: 'แอ็กชัน',
    period: 'ระยะเวลา',
    expect_times: 'จำนวนครั้ง',
    actual_times: 'จำนวนครั้ง(เกิดขึ้นจริง)',
    result: 'ผลลัพธ์',
  },
};
