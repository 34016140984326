import { CustomRouteRecord } from '@/types/router/router';

const Login = () => import('@/views/modules/authentication/pages/LoginPage.vue');
const VerifyAccount = () => import('@/views/modules/authentication/pages/VerifyAccountPage.vue');
const ChangePassword = () => import('@/views/modules/authentication/pages/ChangePasswordPage.vue');
// forget and reset
import ForgetPassword from '@/views/modules/authentication/components/ForgetPassword.vue';
import CheckEmailForget from '@/views/modules/authentication/components/CheckEmailForget.vue';
import ExpireResetPassword from '@/views/modules/authentication/components/ExpireResetPassword.vue';
import LandingPageFromEmail from '@/views/modules/authentication/components/LandingPageFromEmail.vue';

const authenticationRoute: Array<CustomRouteRecord> = [
  {
    path: '/login',
    name: 'auth-login',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/select-brand',
    name: 'select-brand',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/reset',
    name: 'first-reset-password',
    component: Login,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/forget',
    name: 'forget-password',
    component: ForgetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/landing-page-email',
    name: 'landing-page-email',
    component: LandingPageFromEmail,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/check-email-forget',
    name: 'check-email-forget',
    component: CheckEmailForget,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/expire-reset-password',
    name: 'expire-reset-password',
    component: ExpireResetPassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    component: VerifyAccount,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
];

export default authenticationRoute;
