// work
import { watch, ref, Ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import router from '@/router/index';
import { useSegmentVersion } from '@/store/segmentV2Store';
import { segmentVersionKey } from '@/constants/modules/segment-v2/segment-adaptor';

interface BreadcrumbOption {
  text: string;
  active: boolean;
  to: string;
  query?: Record<string, any>;
}

export default function useBreadCrumb() {
  const breadcrumb: Ref<BreadcrumbOption[]> = ref([]);
  const segmentVersionStore = useSegmentVersion();
  const initBreadcrumb = () => {
    const allRoute = router.options.routes;
    const currentRoute = router.currentRoute.value;

    if (currentRoute.matched.length > 0) {
      // Convert the path pattern to a proper RegExp object
      const pathPattern = currentRoute.matched[0].path;
      const pathRegex = new RegExp(`^${pathPattern.replace(/:[^\s/]+/g, '[^/]+')}$`);

      const currentRouteInfo = allRoute.find((route) => {
        return pathRegex.test(route.path);
      });

      // Check meta breadcrumb first
      if (currentRoute.meta?.breadcrumb) {
        breadcrumb.value = currentRoute.meta.breadcrumb as BreadcrumbOption[];
        return;
      }

      // Fall back to route config breadcrumb
      if (currentRouteInfo?.breadcrumb) {
        breadcrumb.value = currentRouteInfo?.breadcrumb;
        return ;
      }
    }

    breadcrumb.value = [];
  };

  const onClickBreadcrumb = (breadcrumb: Record<string, any>) => {
    if (breadcrumb.to) router.push({ path: breadcrumb.to, query: breadcrumb.query || {} }).then(() => {});
    switch (breadcrumb.versionKey) {
      case segmentVersionKey:
        if (segmentVersionStore.getSegmentVersion && breadcrumb.versionTo[segmentVersionStore.getSegmentVersion])
          router.push({ path: breadcrumb.versionTo[segmentVersionStore.getSegmentVersion], query: breadcrumb.query || {} }).then(() => {});
        break;
    }
  };

  // Watch route changes
  watch(
    () => router.currentRoute.value,
    (newRoute: RouteLocationNormalizedLoaded) => {
      initBreadcrumb();
    },
    { deep: true, immediate: true },
  );

  return {
    breadcrumb,
    onClickBreadcrumb,
  };
}
