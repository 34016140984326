<script setup lang="ts">
import { useBrandStore } from '@/store/brandStore';
import { defineProps, ref, reactive, onMounted, onBeforeMount, getCurrentInstance, watch, Ref } from 'vue';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import usePermissionModal from '@/views/modules/permission/components/permission-modal/hook/usePermissionModal';
import { usePermission } from '../../hooks/usePermission';
import { useI18n } from 'vue-i18n';
import api from '@/services/api';

// Constans
import {
  PERMISSION_MENU_STATUS as menuStatus,
  PERMISSION_MENU_KEY as menuKey,
  PERMISSION_ROLE_KEY as roleKeyAction,
} from '@/constants/modules/permission/permission';

import { Permission } from '@/types/modules/permission/permission';

// api
import PermissionApi from '@/models/permission/permission';

// Icon
import DashboardIcon from '@/assets/icons/modules/permission/DashboardIcon.vue';
import SegmentIcon from '@/assets/icons/modules/permission/CreateAudienceIcon.vue';
import DataConectorIcon from '@/assets/icons/modules/permission/DataConectorIcon.vue';
import CommunicationIcon from '@/assets/icons/modules/permission/CommunicationIcon.vue';
import MkaIcon from '@/assets/icons/modules/permission/MkaIcon.vue';
import SettingIcon from '@/assets/icons/modules/permission/ManageRightsIcon.vue';
import ChangePasswordIcon from '@/assets/icons/modules/permission/ChangePasswordIcon.vue';
import CrossIcon from '@/assets/icons/modules/permission/CrossIcon.vue';
import RoleConfigIcon from '@/assets/icons/modules/permission/RoleConfigIcon.vue';
import ArrowDownIcon from '@/assets/icons/base/navbarIcon/ArrowDownIcon.vue';
import LoadingAnimateIcon from '@/assets/icons/modules/customer-insight/LoadingAnimateIcon.vue';

interface Props {
  currMenu?: string;
  isOpen: boolean;
  role?: any[];
  roleKey?: string;
}

const props = defineProps<Props>();

const loading = reactive({
  save: false,
  configPanel: false,
});

// const emit = defineEmits(['update:currMenu']);
const { fetchRoleDetailModel } = PermissionApi();
const { onCloseModal } = usePermissionModal();

const { t } = useI18n();

const currMenu = ref('');
const roleDetailListFromFilter = ref<any[]>([]);
const roleHeaderListFromFilter: Ref<Permission.RoleDetail.Request.Response | null> = ref(null);
const roleKey: Ref<Permission.RoleDetail.Request.Payload> = ref({ Role_key: '' });
const permissionStatus = ref(menuStatus.Dashboard);
const permissionKey = ref(menuKey.Dashboard);
const roleAction = ref(roleKeyAction.Admin);
const brandRef = ref<string | null>('');

watch(
  () => permissionKey.value,
  () => {
    permissionKey.value = permissionKey.value;
    fetchRoleDetail();
  },
);

watch(
  () => roleAction.value,
  () => {
    roleAction.value = roleAction.value;
    fetchRoleDetail();
  },
);

const fetchRoleDetail = async (): Promise<void> => {
  loading.configPanel = true;
  roleKey.value.Role_key = props.currMenu == null ? props.roleKey ?? '' : roleAction.value ?? '';

  fetchRoleDetailModel.payload.Role_key = roleKey.value.Role_key;
  fetchRoleDetailModel.payload.Brand_Ref = brandRef.value;

  await api
    .apiRequest(fetchRoleDetailModel)
    .then((res) => {
      const permissionStatusValue = permissionStatus.value;
      const permissionKeyValue = permissionKey.value;

      const roleHeaderlist = res.data.key_list.filter((item: any) => {
        return item.permission_key === permissionKeyValue;
      });

      if (roleHeaderlist.length > 0) {
        roleHeaderListFromFilter.value = roleHeaderlist;
        if (roleHeaderListFromFilter.value !== null && roleHeaderListFromFilter.value !== undefined) {
          roleHeaderListFromFilter.value.action_th = roleHeaderlist[0].action_th;
          roleHeaderListFromFilter.value.desc_th = roleHeaderlist[0].desc_th;
          roleHeaderListFromFilter.value.action_en = roleHeaderlist[0].action_en;
          roleHeaderListFromFilter.value.desc_en = roleHeaderlist[0].desc_en;
        }
      }

      const roleDetailList = res.data.key_list.filter((item: any) => {
        return item.module_code === permissionStatusValue;
      });
      roleDetailListFromFilter.value = roleDetailList;
    })
    .finally(() => {
      loading.configPanel = false;
    });
};

onBeforeMount(() => {
  brandRef.value = useBrandStore().getBrandRef;
});

onMounted(async () => {
  await fetchRoleDetail();
  currMenu.value = currMenu.value || 'dashboard';
});
</script>

<template>
  <base-modal size="xl" modal-id="permission-role-modal" class="role-modal" :is-modal-open="isOpen" @hide="onCloseModal" hide-footer is-scrollable>
    <template #modal-header>
      <div class="container-fluid p-0">
        <teleport to="body">
          <div v-if="loading.configPanel" class="loading-overlay">
            <LoadingAnimateIcon />
          </div>
        </teleport>
        <div :class="[props.currMenu == 'roleDetailAll' ? 'd-flex flex-column' : 'd-flex justify-content-between']">
          <div v-if="props.currMenu == 'roleDetailAll'">
            <div class="back" @click="onCloseModal">
              <arrow-down-icon fill="#000" style="margin-right: 10px" />
              {{ t('global.back') }}
            </div>
          </div>
          <div class="text-header">
            <label class="permission-role-text">{{ t('permission.modal.role_detail.role') }}</label>
          </div>
          <div v-if="props.currMenu != 'roleDetailAll'" class="role-header">
            <button class="btn-role">
              <RoleConfigIcon />
              {{ props.role }}
            </button>
            <div class="crossBack">
              <CrossIcon style="cursor: pointer" @click="onCloseModal" />
            </div>
          </div>
          <div v-else class="role-action">
            <button :class="[roleAction == roleKeyAction.Admin && 'btn-role-action']" @click="() => (roleAction = roleKeyAction.Admin)">
              {{ t('permission.modal.role_detail.admin') }}
            </button>
            <button :class="[roleAction == roleKeyAction.Editor && 'btn-role-action']" @click="() => (roleAction = roleKeyAction.Editor)">
              {{ t('permission.modal.role_detail.editor') }}
            </button>
            <button :class="[roleAction == roleKeyAction.GeneralUser && 'btn-role-action']" @click="() => (roleAction = roleKeyAction.GeneralUser)">
              {{ t('permission.modal.role_detail.general_user') }}
            </button>
            <button :class="[roleAction == roleKeyAction.Viewer && 'btn-role-action']" @click="() => (roleAction = roleKeyAction.Viewer)">
              {{ t('permission.modal.role_detail.only_see') }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #modal-body>
      <div class="container-fluid p-0">
        <div class="d-flex">
          <div class="panel-left border-right">
            <div class="btn-panel-left-wrp">
              <button
                :class="[permissionKey == menuKey.Dashboard && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.Dashboard), (permissionKey = menuKey.Dashboard))"
              >
                <DashboardIcon />
                {{ t('permission.modal.role_detail.dashboard') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.Segment && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.Segment), (permissionKey = menuKey.Segment))"
              >
                <SegmentIcon />
                {{ t('permission.modal.role_detail.create_audience') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.DataConnector && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.DataConnector), (permissionKey = menuKey.DataConnector))"
              >
                <DataConectorIcon />
                {{ t('permission.modal.role_detail.data_conector') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.Communication && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.Communication), (permissionKey = menuKey.Communication))"
              >
                <CommunicationIcon />
                {{ t('permission.modal.role_detail.communication') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.Mka && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.Mka), (permissionKey = menuKey.Mka))"
              >
                <MkaIcon />
                {{ t('permission.modal.role_detail.mka') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.Setting && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.Setting), (permissionKey = menuKey.Setting))"
              >
                <SettingIcon />
                {{ t('permission.modal.role_detail.manage_role') }}
              </button>
              <br />
              <button
                :class="[permissionKey == menuKey.ResetPassword && 'btn-menu-action']"
                type="submit"
                @click="() => ((permissionStatus = menuStatus.ResetPassword), (permissionKey = menuKey.ResetPassword))"
              >
                <ChangePasswordIcon />
                {{ t('permission.modal.role_detail.change_password') }}
              </button>
              <br />
            </div>
          </div>
          <div class="panel-right">
            <div class="container">
              <div class="row header-panel">
                <div class="col-12">
                  <label class="label-header">
                    {{ $i18n.locale == 'th' ? roleHeaderListFromFilter?.action_th : roleHeaderListFromFilter?.action_en }}
                  </label>
                </div>
              </div>
              <div class="row header-detail-panel">
                <label> {{ t('permission.modal.role_detail.detail') }} </label>
              </div>
              <div class="row content-panel">
                <div v-for="(item, index) in roleDetailListFromFilter" :key="index" class="content-detail-panel">
                  <div style="display: flex">
                    <div class="rectangle" :class="{ green: item.status, red: !item.status }"></div>
                    <label class="label-header-content"> {{ $i18n.locale == 'th' ? item.action_th : item.action_en }}</label>
                  </div>
                  <div class="row label-content-detail">
                    <div>
                      {{ $i18n.locale == 'th' ? item.desc_th : item.desc_en }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-modal>
</template>

<style lang="scss" scoped>
@import '@assets/styles/modules/permission/permission-modal/permission-role-modal.scss';

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  background-color: #ffffff20;
  backdrop-filter: blur(5px);
  z-index: 10000;
}
</style>
