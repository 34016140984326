<template>
  <div class="noti-credit-success">
    <base-modal size="xxxl" class="modal-noti-credit-success" hide-footer hideHeader :is-modal-open="isModalOpen" @hide="onHideModal()">
      <template #modal-body>
        <div class="noti-credit-success-container">
          <div class="noti-credit-success-detail">
            <div class="noti-credit-success-body-top">
              <div class="noti-credit-success-picture">
                <img width="165" height="90" src="/base/noti-credit/notiSuccess.png" />
              </div>
              <div class="noti-credit-success-title">{{ t('notification.noti_credit_success.title') }}</div>
            </div>
            <div class="noti-credit-success-body-bottom">
              <div>
                <!-- <div class="spinner-border" role="status" v-if="loading">
                <span class="visually-hidden">{{t('global.loading')}}</span>
              </div> -->
                <div v-if="loading" class="noti-credit-success-bottom-title">
                  {{ t('notification.noti_credit_success.content_one') }}
                  <b
                    >{{ t('notification.noti_credit_success.num') }}
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">{{ t('global.loading') }}</span>
                    </div>
                    {{ t('notification.noti_credit_success.list') }}
                  </b>
                </div>
                <div v-else class="noti-credit-success-bottom-title">
                  {{ t('notification.noti_credit_success.content_one') }}
                  <b
                    >{{ t('notification.noti_credit_success.num') }}

                    {{ filter_countcampaign }}
                    {{ t('notification.noti_credit_success.list') }}
                  </b>
                </div>
                <div class="noti-credit-success-bottom-title">
                  {{ t('notification.noti_credit_success.content_two') }}<span> “{{ t('notification.noti_credit_success.manage') }}”</span>
                </div>
              </div>
              <div v-if="loading" class="noti-credit-success-body">
                <list-card :is-loading="loading" />
              </div>
              <div v-else>
                <div v-if="filter_listCardSuccessData.length != 0" class="noti-credit-success-body">
                  <div :style="'padding: 0rem 2rem'" v-for="(item, index) in filter_listCardSuccessData" v-bind:key="index">
                    <list-card :is-provider="true" :type-card="3">
                      <template #content-card>
                        <div class="content-card-noti-container">
                          <div class="channel-card-noti">
                            <div v-if="item.channel == 1" class="icon-channel"><SMSIcon /></div>
                            <div v-else-if="item.channel == 2" class="icon-channel"><EmailIcon /></div>
                            <div v-else-if="item.channel == 3" class="icon-channel"><LineOaIcon /></div>
                            <div class="text-channel">{{ item.channel == 1 ? 'SMS' : item.channel == 2 ? 'E-mail' : 'Line' }}</div>
                          </div>
                          <div class="content-card-noti">
                            <div class="text-show-content">{{ t('notification.noti_credit_success.add_credit') }}</div>
                            <div class="number-show-content">{{ resolveNumber(item.credit_topup) }}</div>
                          </div>
                          <div class="content-card-noti">
                            <div class="text-show-content">{{ t('notification.noti_credit_success.total_credit') }}</div>
                            <div class="number-show-content">{{ resolveNumber(item.credit_remaining) }}</div>
                          </div>
                        </div>
                      </template>
                      <template #text-footer
                        ><div :style="'cursor: pointer'" @click="pushRouter()">{{ t('notification.noti_credit_success.manage') }}</div></template
                      >
                    </list-card>
                  </div>
                </div>
                <div v-else>
                  <div class="no-item-card"><EmptyTableIcon /></div>
                </div>
              </div>
            </div>
            <div class="noti-credit-success-body-footer">
              <div @click="onCloseModal()" class="text-skip">
                <span :style="'cursor: pointer'">{{ t('notification.noti_credit_success.skip_button') }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive, Ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import api from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import notificationModel from '@/models/notification/notification';
import SMSIcon from '@/assets/icons/modules/communication/all-channel-tab/SMSIcon.vue';
import LineOaIcon from '@/assets/icons/modules/communication/all-channel-tab/LineOaIcon.vue';
import EmailIcon from '@/assets/icons/modules/communication/all-channel-tab/EmailIcon.vue';
import listCard from '@/views/components/list-credit-content/component/listCard.vue';
import useNumber from '@/utils/hooks/useNumber';
import BaseModal from '@/views/components/modal/BaseModal.vue';
import EmptyTableIcon from '@/assets/icons/base/table/EmptyTableIcon.vue';
import router from '@/router';
import { useAuthStore } from '@/store/authStore';
interface Props {
  isModalOpen: boolean;
}
const { t } = useI18n();
const { openSuccessModal, openErrorModal } = useValidationModal();
const { resolveNumber } = useNumber();
const authStore = useAuthStore();
const { getAlertNotificationCreditTopup } = notificationModel();
const props = defineProps<Props>();
const emits = defineEmits(['hide', 'close-modal', 'open-modal']);
const loading: Ref<boolean> = ref(true);

const listCardSuccessData: Ref<any[]> = ref([]);
const filter_countcampaign: Ref<number> = ref(0);

const filter_listCardSuccessData: Ref<any[]> = ref([]);
function onClickDetailList(channelType: number) {
  // tab
  // if (channelType == 3) {
  //   router.push({ path: '/notification-credit', query: { tab: 'NotiStorage' } });
  // } else {
  //   router.push({ path: '/notification-credit', query: { tab: 'NotiCredit' } });
  // }
}
function pushRouter() {
  router.push({
    path: '/communication/resume-campaign',
    query: { back: '/analytics/dashboard', activetab: '/analytics/dashboard' },
  });
  emits('close-modal');
}
function fetchData() {
  loading.value = true;
  listCardSuccessData.value = [];
  filter_listCardSuccessData.value = [];
  return api
    .apiRequest(getAlertNotificationCreditTopup)
    .then((response) => {
      if (response.data.credit_list.length == 0) {
        onCloseModal();
      } else {
        onOpenModal();
        listCardSuccessData.value = response.data.credit_list;
        filter_listCardSuccessData.value = listCardSuccessData.value;
        filter_countcampaign.value = response.data.total_pause_campaign;
      }
    })
    .catch((error) => {
      // openErrorModal('GetListTable : ' + String(error.data.error.locale['th-th'].title), String(error.data.error.locale['th-th'].message));
    })
    .finally(() => {
      loading.value = false;
    });
}
function onCloseModal() {
  emits('close-modal');
}
function onOpenModal() {
  emits('open-modal');
}
function onHideModal() {
  emits('hide');
}
// function onModalOpen() {
//   fetchData();
// }

onMounted(() => {
  if (authStore.isCreditChecked == true) {
    fetchData();
  }
});
</script>
<style lang="scss" scoped>
.noti-credit-success {
  :deep(.modal-inner) {
    max-width: 80rem !important;
    border-radius: 20px !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    width: 100% !important;
    padding: 0 !important;
  }

  :deep(.modal-body) {
    padding: 0 !important;
    margin-right: -1rem;
    margin-bottom: -1rem;
    margin-left: -1rem;
  }
  .modal-noti-credit-success {
    .noti-credit-success-container {
      position: relative;
      .noti-credit-success-detail {
        padding: 0rem 0rem 3rem 0rem;

        .noti-credit-success-body-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1.5rem;
          background-color: #67ad5b1a;
          .noti-credit-success-picture {
            margin-top: 1rem;
          }
          .noti-credit-success-title {
            text-align: center;
            font: normal normal normal 28px/50px Prompt;
            color: #67ad5b;
            margin-top: 0.1rem;
          }
        }
        .noti-credit-success-body {
          display: flex;
          justify-content: center;
          margin-top: 2.7rem;
          width: 100%;
          flex-wrap: wrap;
          .card-noti-container {
            width: 18rem;
            height: 20rem;
            :deep(.text-header-card-n) {
              font: normal normal normal 16px/13px Prompt;
            }
            :deep(.card-noti-footer) {
              font: normal normal normal 16px/29px Prompt;
            }
          }
          .content-card-noti-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 2.1rem;

            .channel-card-noti {
              display: flex;
              align-items: center;

              .icon-channel {
                width: 10%;
                height: auto;
              }
              .text-channel {
                font: normal normal 500 22px/24px Prompt;
                width: 90%;
                margin-left: 0.5rem;
              }
            }
            .content-card-noti {
              display: flex;
              align-items: center;
              margin-top: 1.5rem;
              height: auto;

              .text-show-content {
                display: flex;
                font: normal normal normal 16px/16px Prompt;
                width: 60%;
              }
              .number-show-content {
                display: flex;
                justify-content: flex-end;
                font: normal normal 500 22px/14px Prompt;
                width: 40%;
              }
              .number-show-content-zero {
                color: #e05240;
                display: flex;
                justify-content: flex-end;
                font: normal normal normal 22px/14px Prompt;
                width: 50%;
              }
            }
          }
        }
        .noti-credit-success-body-bottom {
          height: auto;
          margin-bottom: 1rem;

          .noti-credit-success-bottom-title {
            margin-top: 1rem;
            text-align: center;
            font: normal normal normal 22px/19px Prompt;
            color: #000000;
            margin-bottom: 10px;
            span {
              color: #007fff;
            }
          }
          .spinner-border {
            width: 15px;
            height: 15px;
            border: 2px solid #007fff;
            border-right-color: transparent;
          }
          .no-item-card {
            display: flex;
            margin-top: 2rem;
            width: 19.438rem;
            height: 24.125rem;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #e5f0fa;
            border-radius: 20px;
            align-items: center;
            justify-content: center;
            color: #a8a8a8;
          }
        }

        .noti-storage-bottom-title {
          /* UI Properties */
          text-align: left;
          font: normal normal normal 22px/19px Prompt;
          color: #000000;
          margin-bottom: 10px;
          margin-top: 60px;
        }

        .noti-credit-success-body-footer {
          display: flex;
          justify-content: center;
          min-height: 70px;
          width: 100%;
          margin-top: 60px;
          .text-skip {
            font: normal normal normal 22px/42px Prompt;
            color: #606a6c;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
