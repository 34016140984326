import router from '@/router';
import { useAuthStore } from '@/store/authStore';
import { useBrandStore } from '@/store/brandStore';
import { useModuleStore } from '@/store/moduleStore';
import { useBuilderConfigStore, useSourceDetailStore } from '@/store/segmentV2Store';
import loginModel from '@/models/authentication/login';
import apiService from '@/services/api';

const { fetchBrandListModel, fetchSubmitBrandModel } = loginModel();

class BrandService {
  // Dynamically fetch the auth store to ensure Pinia is initialized
  private get authStore() {
    return useAuthStore();
  }

  private get brandStore() {
    return useBrandStore();
  }

  private get moduleStore() {
    return useModuleStore();
  }

  // Segment v2
  private get segmentBuilderConfigStore() {
    return useBuilderConfigStore();
  }

  private get segmentSourceDetailStore() {
    return useSourceDetailStore();
  }

  isValidBrandRef(input: string): boolean {
    const regex = /^b.{11}$/i; // ^b -> starts with b (case insensitive), .{11} -> 11 more characters
    return regex.test(input);
  }

  getBrandRefParams = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const brandRefQuery = urlParams.get('brandRef');
    return brandRefQuery && this.isValidBrandRef(brandRefQuery) ? brandRefQuery : null;
  };

  async fetchBrandListAndSet(accessToken: string): Promise<boolean> {
    const brandListResponse = await this.fetchBrandList(accessToken);
    if (brandListResponse) {
      this.brandStore.brandList = brandListResponse;
      return true;
    }
    return false;
  }

  async fetchBrandList(accessToken: string): Promise<Authentication.RespBrandList[]> {
    fetchBrandListModel.payload.access_token = accessToken;
    let brandListResponse: Authentication.RespBrandList[] = [];
    await apiService
      .apiRequest(fetchBrandListModel)
      .then((response) => {
        brandListResponse = response.data as Authentication.RespBrandList[];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
    return brandListResponse;
  }

  setBrandData(brandList: Authentication.RespBrandList[]) {
    this.brandStore.setBrandListNew(brandList);
    this.setCurrentBrand(brandList);
  }

  setCurrentBrand(brandList: Authentication.RespBrandList[]) {
    // By params is priority
    const searchParams = new URLSearchParams(window.location.search);
    const brandRef: string = searchParams.get('brandRef')!;
    const selectedBrand = brandList.find((o) => {
      return o.brand_ref === brandRef;
    });
    const brandSelected = selectedBrand || brandList[0];
    this.brandStore.setCurrentBrand({
      accessToken: this.authStore.access_token || '',
      refreshToken: this.authStore.refresh_token || '',
      brandRef: brandSelected.brand_ref,
      brandData: { brand_ref: brandSelected.brand_ref, brand_name: brandSelected.brand_name, logo_url: brandSelected.logo_url },
    });
  }

  submitBrandAndSet(submitBrandResp: Authentication.RespSubmitBrand): boolean {
    if (submitBrandResp) {
      this.authStore.setTokens(submitBrandResp.access_token, submitBrandResp.refresh_token);

      const foundBrandData = this.brandStore.brandList.find((brand) => brand.brand_ref == submitBrandResp.brand_ref);
      if (!foundBrandData) return false;

      // new brand const
      const newBrand: Authentication.CurrentBrand = {
        brandRef: submitBrandResp.brand_ref,
        brandData: foundBrandData,
        accessToken: submitBrandResp.access_token,
        refreshToken: submitBrandResp.refresh_token,
      };

      // Set new brand data
      this.brandStore.setCurrentBrand(newBrand);
      sessionStorage.removeItem('segment_source_detail');

      // Set new user info
      const userInfo = {
        first_name: submitBrandResp.first_name,
        last_name: submitBrandResp.last_name,
        picture_url: submitBrandResp.picture_url,
        email: submitBrandResp.email,
        role_th: submitBrandResp.role_th,
        role_en: submitBrandResp.role_en,
        mobileno: submitBrandResp.mobileno || null,
      };

      // Set new userInfo by brand
      this.authStore.setUserInfo(userInfo);

      // Remove module version
      this.moduleStore.$reset();

      // Segment
      this.segmentSourceDetailStore.$reset();
      this.segmentBuilderConfigStore.$reset();
      // Manual reset method
      this.segmentBuilderConfigStore.reset();
      this.segmentSourceDetailStore.reset();

      // Set permission
      // await permissionService.fetchAndSetPermissions();
      // await moduleService.fetchAndSetModuleVersions();
      // await router.replace({ ...router.currentRoute.value, query: { ...router.currentRoute.value.query, brandRef: null } });
      return true;
    }
    return false;
  }

  async fetchSubmitBrandAndSet(brandRef: string): Promise<boolean> {
    if (!brandRef || brandRef == '') return false;
    const access_token = this.authStore.access_token as string;

    const submitBrandResp = await this.fetchSubmitBrand(access_token, brandRef);
    if (submitBrandResp) {
      this.authStore.setTokens(submitBrandResp.access_token, submitBrandResp.refresh_token);

      const foundBrandData = this.brandStore.brandList.find((brand) => brand.brand_ref == submitBrandResp.brand_ref);
      if (!foundBrandData) return false;

      // new brand const
      const newBrand: Authentication.CurrentBrand = {
        brandRef: brandRef,
        brandData: foundBrandData,
        accessToken: submitBrandResp.access_token,
        refreshToken: submitBrandResp.refresh_token,
      };

      // Set new brand data
      this.brandStore.setCurrentBrand(newBrand);
      sessionStorage.removeItem('segment_source_detail');

      // Set new user info
      const userInfo = {
        first_name: submitBrandResp.first_name,
        last_name: submitBrandResp.last_name,
        picture_url: submitBrandResp.picture_url,
        email: submitBrandResp.email,
        role_th: submitBrandResp.role_th,
        role_en: submitBrandResp.role_en,
        mobileno: submitBrandResp.mobileno || null,
      };

      // Set new userInfo by brand
      this.authStore.setUserInfo(userInfo);

      // Remove module version
      this.moduleStore.$reset();

      // Segment
      this.segmentSourceDetailStore.$reset();
      this.segmentBuilderConfigStore.$reset();
      // Manual reset method
      this.segmentBuilderConfigStore.reset();
      this.segmentSourceDetailStore.reset();

      // Set permission
      // await permissionService.fetchAndSetPermissions();
      // await moduleService.fetchAndSetModuleVersions();
      // await router.replace({ ...router.currentRoute.value, query: { ...router.currentRoute.value.query, brandRef: null } });
      return true;
    }
    return false;
  }

  getLocalBrandData(): string | null {
    return localStorage.getItem('app:brandInfo') ?? null;
  }

  async fetchSubmitBrand(accessToken: string, brandRef: string): Promise<Authentication.RespSubmitBrand | null> {
    let submitBrandResp: Authentication.RespSubmitBrand | null = null;
    fetchSubmitBrandModel.payload.access_token = accessToken;
    fetchSubmitBrandModel.payload.brand_ref = brandRef;
    await apiService
      .apiRequest(fetchSubmitBrandModel)
      .then((response) => {
        submitBrandResp = response.data as Authentication.RespSubmitBrand;
      })
      .catch((e) => {
        Promise.reject(e); //delete error
        throw e;
      });
    return submitBrandResp;
  }
}

const brandService = new BrandService();
export default brandService;
