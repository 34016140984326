import { moduleName } from '@/constants/global/module';
import { CustomRouteRecord } from '@/types/router/router';

const SegmentListV2 = () => import('@/views/modules/segment-v2/segment-list/SegmentListV2.vue');
const SegmentDetailV2 = () => import('@/views/modules/segment-v2/segment-detail/SegmentDetailV2.vue');
const FolderInside2 = () => import('@/views/modules/segment-v2/segment-list/components/FolderInside.vue');

const SegmentPreviewV2 = () => import('@/views/modules/segment-v2/segment-result/SegmentPreviewV2.vue');
const SegmentPreviewSelectCustomV2 = () => import('@/views/modules/segment-v2/segment-result/SegmentPreviewSelectCustomV2.vue');
const SegmentResultV2 = () => import('@/views/modules/segment-v2/segment-result/SegmentResultV2.vue');

const meta = {
  moduleName: moduleName.Segment,
  moduleVersion: '2.0.0',
};

const dataConnectRoutes: Array<CustomRouteRecord> = [
  {
    path: '/segmentv2/detail/:segmentRef',
    name: 'segmentDetailV2',
    component: SegmentDetailV2,
    meta: {
      ...meta,
      requiresConfirmation: true,
      bypassConfirmations: ['segmentPreviewV2'],
    },
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
      {
        text: 'detail',
        active: true,
      },
    ],
  },
  {
    path: '/segmentv2',
    name: 'segmentV2',
    component: SegmentListV2,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/folder-insidev2',
    name: 'folderInsidev2',
    component: FolderInside2,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'segment',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/segmentv2/preview/:segmentId',
    name: 'segmentPreviewV2',
    component: SegmentPreviewV2,
    meta: {
      ...meta,
      requiresConfirmation: true,
      bypassConfirmations: ['segmentDetailV2', 'segmentResultV2'],
    },
    breadcrumb: [
      {
        text: 'segment.breadcrumb',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/segmentv2/preview-select-custom/:segmentRef/:cloneId/:previewRef',
    name: 'segmentPreviewSelectCustomV2',
    component: SegmentPreviewSelectCustomV2,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'segment.breadcrumb',
        to: '/segmentv2',
      },
    ],
  },
  {
    path: '/segmentv2/result/:segmentId',
    name: 'segmentResultV2',
    component: SegmentResultV2,
    meta: {
      ...meta,
    },
    breadcrumb: [
      {
        text: 'segment.breadcrumb',
        to: '/segmentv2',
      },
    ],
  },
];

export default dataConnectRoutes;
